<template>
  <div>
    <van-button 
          style="background: transparent"
          color="#fff" plain  hairline type="primary">
      {{ inputTitle }}
      <input
        class="fileInput"
        type="file"
        @change="historyFile"
        accept="video/*"
      />
      <!-- <input
        class="fileInput"
        type="file"
        @change="historyFile"
        accept="file/*"
      /> -->
    </van-button>
    <videolzComp />
  </div>
</template>

<script>
import videolzComp from './videolz.vue'
export default {
  components:{videolzComp},
  data() {
    return {
      previewUrl: null,
      fileIsImage: false,
    };
  },
  props: ["inputTitle"],
  mounted() {
    // console.log(MediaRecorder)
  },
  methods: {
    stimeUraniuml(url) {
      this.$emit("stimeUraniuml", url);
    },
    historyFile(e) {
      const file = e.target.files[0];
      this.handleVideoSelect(e);
      return;
      if (file) {
        // 判断文件类型
        if (file.type.startsWith("image")) {
          this.fileIsImage = true;
        } else if (file.type.startsWith("video")) {
          this.fileIsImage = false;
        } else {
          alert("不支持的文件类型");
          return;
        }

        // 创建预览URL
        this.previewUrl = URL.createObjectURL(file);
      } else {
        this.previewUrl = null;
        this.fileIsImage = false;
      }
      this.stimeUraniuml(this.previewUrl);
    },
    handleVideoSelect(event) {
      const file = event.target.files[0];
      if (!file) {
        alert("请选择一个视频文件");
        return;
      }

      // 使用FileReader读取文件
      const reader = new FileReader();
      reader.onload = (e) => {
        // 获取到的URL是blob类型的URL，可以直接用于播放
        const videoUrl = e.target.result;
        // 设置DPlayer的视频源
        // this.dp.switchVideo({
        //   url: videoUrl,
        // });
        this.stimeUraniuml(videoUrl);
      };
      reader.readAsDataURL(file); // 读取文件为DataURL
    },
  },
  beforeDestroy() {
    // 清理预览URL，避免内存泄漏
    if (this.previewUrl) {
      URL.revokeObjectURL(this.previewUrl);
    }
  },
};
</script>

<style lang="scss" scoped>
.fileInput {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
}
</style>