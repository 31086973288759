<template>  
  <div class="scan-container">
       <button @tap="scanQRCode">扫描二维码</button>
       <div v-if="scannedResult" class="scanned-result">
        {{ scannedResult }}
      </div>
      <div>
        <homebottom></homebottom>
      </div>
  </div> 
  
</template>  
    
  <script>  
  import homebottom from '../components/homebottom.vue';
  import wx from 'weixin-js-sdk'
  export default {  
    components:{
      homebottom,
    },
    data() {  
      return {  
        scannedResult: ''  
      };  
    },  
    methods: {  
      scanQRCode() {  
        //获取当前位置
          // wx.getLocation({
          //   type: 'wgs84',
          //   success:function (res) {
          //     // console.log(res);
          //     const latitude = res.latitude
          //     const longitude = res.longitude
          //     wx.chooseLocation({
          //       latitude:latitude,
          //       longitude:longitude,
          //       success:function(res){
          //         console.log(res);
          //       },
          //       fail: function(){},
          //       complete: function(){}

          //     })
          //   },
          //   fail: function(){},
          //   complete: function(){}
          //   })
        const config = {
          debug: false,
          appId: res.appId,
          timestamp: res.timestamp,
          nonceStr: res.nonceStr,
          signature: res.signature,
          jsApiList: ['scanQRCode'] // 你需要使用的JS接口列表
        }
        wx.config(config);
        wx.ready(() => {
          //配置成功之后，可调用相应接口
        })
        wx.error((err) => {
          console.log('微信配置错误', err);
        })

        const that = this;  
        wx.scanCode({  
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode'],
          success:function(res) {  
            console.log(res);
            // if (res.resultType === 'qrCode') {  
            //   that.scannedResult = res.result;  
            // } else if (res.resultType === 'barCode') {  
            //   // 处理条形码逻辑  
            // }  
            // // 其他处理逻辑...  
          },  
          fail:function(error) {  
            console.error('扫描二维码失败：', error);  
          }  
        });  
      }  
    }  
  };  
  </script>  
    
  <style scoped>  
  .scan-container {  
    display: flex;  
    flex-direction: column;  
    align-items: center;  
    justify-content: center;  
    height: 100vh;  
  }  
    
  button {  
    margin-top: 20px;  
    padding: 10px 20px;  
  }  
    
  .scanned-result {  
    margin-top: 20px;  
    text-align: center;  
  }  
  </style>






  <!-- <template>
    <div>
      <input v-model.number="center.lng">
      <input v-model.number="center.lat">
      <input v-model.number="zoom">
      <baidu-map 
          class="map"
          :scroll-wheel-zoom="true"
          :center="center"
          :zoom="zoom"
          @moving="syncCenterAndZoom"
          @moveend="syncCenterAndZoom"
          @zoomend="syncCenterAndZoom">
        <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
      </baidu-map>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
        center: {
          lng: 116.404,
          lat: 39.915
        },
        zoom: 15
      }
    },
    methods: {
       syncCenterAndZoom (e) {
        const {lng, lat} = e.target.getCenter()
        this.center.lng = lng
        this.center.lat = lat
        this.zoom = e.target.getZoom()
      }
    }
  }
  </script>
<style>
.map{
  width: 100%;
  height: 600px;
}
</style> -->
