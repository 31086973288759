
<template id="app1">
  <!-- 底部？ -->
  <div>
    <div>
      <div>
        <van-tabbar v-model="active1" @change="onChange">
          <van-tabbar-item icon="home-o" to="/XRlease/home"
            >首页</van-tabbar-item
          >
          <van-tabbar-item icon="friends-o" to="/XRlease/mysi"
            >我的</van-tabbar-item
          >
        </van-tabbar>
      </div>
    </div>
  </div>
</template>
<script>
// import { Notify } from 'vant';
export default {
  data() {
    return {
      active1: this.index,
    };
  },
  props:['index'],
  mounted() {
    this.handleChange;
  },
  methods: {
    onChange(index) {
      console.log(index);
    //   this.active1 = index;
      // Notify({ type: 'primary', message: index });
    },
    home() {
      this.$router.push("/XRlease/home");
    },
    mysi() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.van-tabbar--fixed {
  position: fixed;
  bottom: 0;
  left: 0;
}
.van-tabbar {
  z-index: var(--van-tabbar-z-index);
  display: flex;
  box-sizing: content-box;
  width: 100%;
  height: 50px;
  background: #ffffff;
}
.van-tabbar-item--active {
  color: #1989fa;
  background-color: #ffffff;
}
.van-tabbar-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--van-tabbar-item-text-color);
  /* font-size: var(--van-tabbar-item-font-size); */
  line-height: var(--van-tabbar-item-line-height);
  cursor: pointer;
}
</style>