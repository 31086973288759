<template>
  <div>
    <div class="topButtomCont">
      <van-button @click="showModel(1)" block>添加活动</van-button>
    </div>
    <div class="activeListData">
      <div
        class="divEachData"
        @click="activeClickFun(item)"
        v-for="(item, index) in activeList"
        :key="index"
      >
        <div class="divEachDataOperation">
          活动名称：<span>{{ item.name }}</span>
        </div>
        <div class="divEachDataTitle">
          所属学校：<span>{{ item.school }}</span>
        </div>
        <div class="btnCont">
          <van-button type="primary" @click="showModel(2, item)"
            >编辑活动</van-button
          >
          <van-button type="info" @click="addTimeMoney(item)">添加价格时间</van-button>
          <van-button type="warning">前往活动</van-button>
        </div>
      </div>
    </div>
    <van-overlay :show="show">
      <div class="wrapper" @click="show = false">
        <div class="block" @click.stop>
          <div class="topTiele">{{ modeType == 1 ? "添加" : "编辑" }}活动</div>
          <van-form @submit="onSubmit">
            <van-field
              v-model="activename.name"
              name="活动名"
              label="活动名"
              placeholder="活动名"
              :rules="[{ required: true, message: '请填写活动名' }]"
            />
            <van-field
              label="时间"
            >
            <template #input>
            <el-date-picker
                size="mini"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
              </template>
            </van-field>
            <div style="margin: 16px">
              <van-button round block type="info" native-type="submit"
                >提交</van-button
              >
            </div>
          </van-form>
        </div>
      </div>
    </van-overlay>
    <!-- <van-popup v-model="showTime" position="bottom" :style="{ height: '30%' }" >
      <van-datetime-picker
                v-model="currentDate"
                type="datetime"
                title="选择完整时间"
                :min-date="minDate"
                :max-date="maxDate"
              />
    </van-popup> -->
  </div>
</template>

<script>
import {
  SETYlWxPlatform,
  getYlWxPlatform,
  upYlWxPlatform,
} from "../../api/homeApi/index";
export default {
  data() {
    return {
      
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      showTime:false,
      modeType: 1,
      show: true,
      activename: {
        name: "",
        id: 0,
        school_id: 0,
      },
      queryData: {},
      activeList: [],
    };
  },
  mounted() {
    this.queryData.loginid = this.$route.query.loginid;
    this.queryData.school_id = this.$route.query.school_id;
    this.getYlWxPlatformFun();
  },
  methods: {
    showModel(i, item) {
      this.show = true;
      this.activename = item || "";
      this.modeType = i;
    },
    activeClickFun(item) {
      // this.$router.push({
      //   name: "activePage",
      //   query: { loginid: this.getRouter, school_id: this.form.class },
      // });
    },
    addTimeMoney(item){
       this.$router.push({
        name: "signUphome",
        query: { loginid: this.queryData.loginid, school_id: this.queryData.school_id,active_id: item.id },
      });
    },
    onSubmit() {
      if (this.modeType == 1) {
        SETYlWxPlatform({
          name: this.activename.name,
          parent: this.queryData.school_id,
        }).then((res) => {
          console.log(res);
          this.$toast("添加成功");
          this.show = false;
          this.getYlWxPlatformFun();
        });
      } else {
        upYlWxPlatform({
          id: this.activename.id,
          name: this.activename.name,
          parent: this.queryData.school_id,
        }).then((res) => {
          console.log(res);
          this.$toast("编辑成功");
          this.show = false;
          this.getYlWxPlatformFun();
        });
      }
    },
    getYlWxPlatformFun() {
      getYlWxPlatform({
        page: 1,
        page_size: 100,
        parent: this.queryData.school_id,
      }).then((res) => {
        console.log(res);
        this.activeList = res.resmsg;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::deep(.van-cell__value) {
  display: block;
}
.activeListData {
  padding: 0.2rem;
  width: 100%;
  box-sizing: border-box;
  .divEachData {
    font-size: 0.28rem;
    border-bottom: 1px solid #ccc;
    padding: 0.2rem;
    &:last-child {
      border: none;
    }
    .btnCont {
      margin-top: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .divEachDataTitle {
      margin-top: 0.1rem;
    }
    .divEachDataOperation {
      margin-top: 0.1rem;
    }
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    min-width: 4.2rem;
    // width: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
    padding: 0.2rem;
    box-sizing: border-box;
    .topTiele {
      text-align: center;
      font-size: 0.44rem;
    }
  }
}

.topButtomCont {
  padding: 0.2rem;
}
</style>