<template>
  <div>
    <van-datetime-picker
      v-model="currentDateRange"
      type="time"
      range
      :min-hour="8"
      :max-hour="20"
      :format="format"
      @confirm="onConfirm"
    />
    <div>
      {{ this.valuetime }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentDateRange: [],
      format: 'HH:mm',
      valuetime:''
    };
  },
  methods: {
    onConfirm(value) {
      this.valuetime = value;
      // console.log('Selected Time Range: ', value);
    }
  }
};
</script>