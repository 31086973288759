<template>
  <div>
    <div class="header">
      <van-tabs v-model:active="active" :sticky="true">
        <van-tab title="少儿编程">
          <div class="main" style="border-top: 1px solid #ccc">
            <div class="main-logo">
              <router-link to="/frist" class="rounded-image">
                <h3>超链接</h3>
                <van-image
                  width="160px"
                  height="160px"
                  :src="require('@/assets/XRlease/132.jpg')"
                />
              </router-link>
            </div>
            <div class="main-title" @click="rouTer">
              <div class="main-title-box">
                <router-link to="/frist">
                  <div class="main-title-size">
                    <div style="height: 120px">
                      <span
                        >适用红米手表4保护壳红米Watch4手表保护套PC壳膜一体</span
                      >
                    </div>

                    <div
                      class="main-title-h5"
                      style="margin-top: 5px; margin-bottom: 0px; float: left"
                    >
                      <h5>关注立减5元</h5>
                    </div>
                    <div
                      class="main-title-h5"
                      style="margin-top: 5px; margin-bottom: 0px; float: right"
                    >
                      <h5>577￥</h5>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="韵母拼读"></van-tab>
        <van-tab title="单词拼写"></van-tab>
      </van-tabs>
    </div>
    <div>
      <homebottom></homebottom>
    </div>
  </div>
</template>
<script>
import homebottom from "./components/homebottom.vue";

export default {
  components: { homebottom },
  data() {
    return {
      active: 0,
    };
  },
  methods: {
    rouTer() {
      this.$router.push("/XRlease/frist");
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  display: flex;
  height: 180px;
  align-items: center; /* 垂直居中 */
}
.main-logo h3 {
  display: none;
}
.main-title {
  height: 180px;
  width: 100%;
  margin-left: 5px;
}
.rounded-image img {
  /* 设置图片的圆角大小 */
  border-radius: 10px; /* 或者使用百分比、50%等实现半圆形效果 */
  margin-left: 2px;
}
.main-title-box {
  margin-top: 8px;
  height: 160px;
}
.main-title-size span {
  font-size: 12px;
  color: black;
}
.main-title-h5 h5 {
  color: red;
  font-size: 12px;
}
.fl {
  float: left;
}
</style>