<template>
  <div>
    <van-popup v-model="showPopup">
      <div class="divpage">
        <svg
          t="1717643174026"
          class="icon SvgImg"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="5900"
          width="100"
          height="100"
        >
          <path
            d="M224 832a32 32 0 0 1-22.63-54.63L722.75 256H608a32 32 0 0 1 0-64h192a31.83 31.83 0 0 1 22.35 9.1l0.43 0.43A31.83 31.83 0 0 1 832 224v192a32 32 0 0 1-64 0V301.25L246.63 822.63A31.9 31.9 0 0 1 224 832z"
            fill="#ffffff"
            p-id="5901"
          ></path>
        </svg>
        <div class="textdl">
          <div>请点击右上角</div>
          <div>通过浏览器预览</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getwxOpenid, getSwapToken } from "../api/index";
function getQueryParam(paramName) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
}
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      showPopup: false,
    };
  },
  mounted() {
    // console.log(this.$route.query, "query query query query query ");
    const code = getQueryParam("code");
    // const modUrl = getQueryParam("modUrl");
    // console.log(code, "code code code code code ");
    if (code) {
      //拿去到了code 请求或openid
      // 通过wx返回的code，获取openid
      if (process.env.NODE_ENV == "development") {
        //开发环境
      } else if (process.env.NODE_ENV == "production") {
        // this.getwxOpenidFun(code,modUrl);
        //生产环境
      } else {
        //本地环境
      }

      // if (this.is_weixn()) {
      //   this.showPopup = true;
      // } else {
        this.getwxOpenidFun(code);
      // }
    }
  },
  methods: {
    ...mapActions("user", ["setGeturl"]),
    getwxOpenidFun(code, modUrl) {
      try {
        getwxOpenid({ code })
          .then((res) => {
            console.log("获取到Openid----------", res); //获取到Openid
            return getSwapToken({ openid: res });
          })
          .then((ress) => {
            console.log("获取到swapToken----------", ress);
            localStorage.setItem("ProjectAuthorizationToken", ress.resmsg);
            localStorage.setItem("ProjectAuthorizationUserId", ress.userid);
            this.$router.push({
              path: "/ProjectBridgePage/ProjectHome",
            });
          })
      } catch (error) {
        // this.$router.push({
        //   path: "/aiVideo/home",
        // });
        this.$dialog
          .alert({
            title: "错误",
            message: "请在微信内部重新打开",
            showConfirmButton:false
          })
          .then(() => {
          });
        console.log("获取到Openid 错误", error);
      }
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },
};
// QiniuUpToken().then((res) => {
//   this.qiniuuptoken = res.uptoken;
//   console.log("获取到uptoken----------", res);
//   // uptoken: "EjQy0QGvHw7DWKwL5ZD9DrhcZWWkXv7P9eYZ8VKz:YA-VubYVGFFXIP_nxC5GW37RZlg=:eyJzY29wZSI6IjN2cHB0IiwiZGVhZGxpbmUiOjE3MTkyMjAzODcsImZvcmNlU2F2ZUtleSI6dHJ1ZSwic2F2ZUtleSI6InBwdF9tZWRpYS8kKGV0YWcpJiQoZm5hbWUpIiwicmV0dXJuVXJsIjpudWxsLCJyZXR1cm5Cb2R5Ijoie1wia2V5XCI6IFwiJChrZXkpXCIsIFwiaGFzaFwiOiBcIiQoZXRhZylcIiwgXCJmbmFtZVwiOiBcIiQoZm5hbWUpXCJ9In0="
// });
</script>

         
<style lang="scss" scoped>
.divpage {
  width: 100vw;
  height: 100vh;
  // background: #fff;
  position: relative;
  .textdl {
    position: absolute;
    right: 0.5rem;
    top: 2.2rem;
    font-size: 0.32rem;
    color: #fff;
  }

  .SvgImg {
    position: absolute;
    right: 0;
    top: 0.2rem;
  }
}
:deep(.van-popup) {
  background-color: transparent !important;
}
</style>