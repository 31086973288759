<template>
  <div id="ap" class="van-all">
    <div class="van-tab__panel">
      <div class="bg">
        <div v-for="(item, index) in getres" :key="index" class="van-grid">
          <div
            class="List"
            v-if="item.status === 2 && item.end_time"
            style="margin-top: 2px"
          >
            <van-cell>
              <span>租赁方式</span>
              <span  style="margin-left: 50%"
                >{{ item.description.name || "设备租赁" }}
              </span>
            </van-cell>
            <van-cell>
              <span>开始时间</span>
              <span style="margin-left: 30%">{{
                convertTimestampToBeijingTime(item.start_time)
              }}</span>
            </van-cell>
            <van-cell>
              <span>结束时间</span>
              <span style="margin-left: 30%">{{
                convertTimestampToBeijingTime(Number(item.end_time))
              }}</span>
            </van-cell>
            <van-cell>
              <span>使用时长</span>
              <span style="margin-left: 30%"
                >{{
                  Math.floor(
                    (Number(item.end_time) - Number(item.start_time)) / 60
                  )
                }}分钟</span
              >
            </van-cell>
            <van-cell>
              <span>设备押金</span>
              <span style="margin-left: 30%">{{ item.description.deposit / 100 || "--" }}元</span>
              
              <!-- <span v-if='item.type != 3' style="margin-left: 40%">100元</span> 
              <span v-else style="margin-left: 40%">200元</span>  -->
            </van-cell>
            <!-- <van-cell>
                      <span>结算支付</span>
                      <span style="margin-left: 30%;">{{ Math.ceil((Number(item.end_time)-Number(item.start_time))/60-2)*3  }}元</span>
                    </van-cell>
                    <van-cell>
                      <span>退款</span>
                      <span style="margin-left: 40%;">{{ 100-Math.ceil((Number(item.end_time)-Number(item.start_time))/60-2)*3 }}元</span>
                    </van-cell>  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { allOrders, orderStates, refundOrder } from "../api/mysi/index";
// import { formatTimestampToBeijingTime } from './timeStamp'
import { parseUrl } from "@/view/XRlease/myhome/orders";
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      getres: [],
    };
  },
  methods: {
    convertTimestampToBeijingTime(timestamp) {
      const date = new Date(timestamp * 1000); // 将时间戳转换为毫秒
      // 设置时区为北京时间
      date.setUTCHours(date.getUTCHours() + 8);
      // 格式化日期为年月日时分秒的格式
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      const seconds = String(date.getUTCSeconds()).padStart(2, "0");
      const beijingTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return beijingTime;
    },
    //调起订单列表接口
    getAllOrders() {
      // console.log("接口没得到" + this.getOpenid);
      const params = {
        openid: this.getOpenid,
      };
       let  wxL = this.$store.getters.wxPayOrderList
      allOrders(params)
        .then((res) => {
          
          let getres = res.data;
          console.log("得到了res 列表的值-1", res.data);
          //冒泡排序
          for (let i = 0; i < getres.length - 1; i++) {
            for (let j = 0; j < getres.length - 1 - i; j++) {
              if (
                Number(getres[j].start_time) < Number(getres[j + 1].start_time)
              ) {
                let temp = getres[j];
                getres[j] = getres[j + 1];
                getres[j + 1] = temp;
              }
            }
          }
          // let getresNew = getres.filter(
          //   (item) => item.pay_status == 1 && item.status != 2
          // );
          // this.getres = getres.map((jsonStr) => {
          //   // 将JSON字符串转换为对象
          //   try {
          //     jsonStr.description = JSON.parse(jsonStr.description);
          //   } catch (error) {}
          //   return jsonStr;
          // });
          getres.forEach((item1) => {
            const match = wxL.find((item2) => item2.id == item1.type);
            if (match) {
              item1.description = match; // 修改描述
            }
          });
          this.getres = getres;
          // console.log("得到了res的值"+JSON.stringify(this.getres));
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    pageInit(){
      this.getAllOrders();
    }
  },
  mounted() {
    //得到openid
    this.url = window.location.href;
    this.getUrl = parseUrl(this.url);
    // console.log(this.getUrl)
    // this.getOpenid = this.getUrl.parameter.openid?.substring(0, 28);
    this.getOpenid =this.$store.getters.urlSendData.openid
    console.log("订单也得" + this.getOpenid);
    this.getAllOrders(this.getOpenid);
  },
};
</script>
  <style>
.van-all {
  /* background-color: aqua; */
  height: 100%;
}
.van-tab__panel {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
}
.bg {
  padding-top: 2vh;
  width: 100%;
  min-height: 100vh;

  background-color: #ededed;
  flex-wrap: wrap;
  padding-bottom: 50px;
  /*flex-direction: column-reverse; */
}
.van-grid {
  display: flex;
  flex-wrap: wrap;
}

.van-image__img {
  display: block;
  width: 100%;
  height: 100%;
}
.click_add_sale {
  font-size: 22px;
  text-align: center;
  color: #fff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background-color: #19c764;
}
.flag_zu {
  text-align: center;
  color: #ffcb3b;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background-color: #0009;
}

.van-cell__value {
  display: flex;
  justify-content: space-between;
}
</style>