
// url地址解析
// import { getLocation } from "@/api/api/axios";
// var url = "http://h5.3vppt.com/#/home?mobile=&head_img_url=https%3A%2F%2Fthirdwx.qlogo.cn%2Fmmopen%2Fvi_32%2FPiajxSqBRaEK6ialDT33nichLWIQFQtJKf6QUYfF77gPz1oPfmC7MAEQmXnFKUA4NibAOmzJSZibRbuEaYcwloKUFFfuWEj2MANM8V8zWN3WJXy1byO8XIWIWicA%2F132&token=c3c0620d03de6f341bd2af4388de2980&member_id=2&nickname=C&openid=oTp3w6iM2NU3kGpi73PhXvJF2fJs"
// export function changeUrl(url){
//         var obj={}
//         //使用split来将有？好分解出来
//       console.log(url.split('?'));
//       obj.parameter = {};
//       obj.url = url.split('?')[0];
//       var paramStr = url.split('?')[1];
//       var paramArr = paramStr.split('&');
//       //使用数组来遍历的得到的data数据
//       console.log(paramArr);
//       for( var i =0; i<paramArr.length;i++){
//         var name = paramArr[i].split('=')[0];
//         var value = paramArr[i].split('=')[1];
//         obj.parameter[name] = value;
//       }
//       //打印解析的URL地址，通过decodeURIComponent（）来解析URL地址
//       // console.log(decodeURIComponent(obj.parameter.head_img_url));
//       return obj;
// }
// changeUrl(url);

export const parseUrl = (url) => {
  const obj = {}
  const [baseUrl, queryString] = url.split('?')
  obj.url = baseUrl
  obj.parameter = {}
  if (queryString) {
    const params = queryString.split('&')
    for (const param of params) {
      const [name, value] = param.split('=')
      obj.parameter[name] = decodeURIComponent(value)
    }
  }
  // console.log(obj)
  return obj
}