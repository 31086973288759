<template>
  <div>
    <!-- //头部 -->
    <div class="div-title">
      <div class="device-title">
        <span class="on_line" :dateMes="fo">[在线]</span>
        {{ namez }}
      </div>
      <div class="info">
        <div class="user-nickname">
          <span :dataName="name">{{ nickname }}</span>
        </div>
        <div class="user-img">
          <div
            class="van-image van-image--round"
            style="width: 30px; height: 30px"
          >
            <div class="van-image__loading">
              <van-image id="myImage" :src="head_img_url" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- tab标签 -->
    <div class="van-tabs van-tabs--line">
      <div>
        <div>
          <homemain :dateMes="fo" @showCityName="updateCity"></homemain>
        </div>
        <homebottom  :index="0"></homebottom>
        <div></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import homemain from "./components/homemain.vue";
import homebottom from "./components/homebottom.vue";
import sale from "./components/sale.vue";
import { parseUrl } from "./orders.js";
import { getuserinfo, getAllGoods, getAllCategory } from "../api/homeApi";
// import { getLocation } from "@/api/api/axios";
const s = 0;
// 保存到本地浏览器sessionStorage
sessionStorage.setItem("zt", "在线");
const x1 = sessionStorage.getItem("zt");

import { Toast } from "vant";
export default {
  data() {
    return {
      active: 0,
      namez: "XR使用租赁",
      jj: "",

      data1: [],
      fo: {
        zt: "",
        URL: "",
        getOpenid: "", // 获得openid
      },
      name: {
        num1: "",
      },
      getUrl: {},
      url: "",
      nickname: "",
      head_img_url: "",
    };
  },
  // mounted() {
  // },
  watch: {
    // 监听
    // 监听 activeTab 的变化，当标签切换时，会打印新的索引值
    active(newVal) {
      console.log("当前选中的标签索引已更改为:", newVal);
    },
  },
  components: {
    homemain,
    homebottom,
    sale,
  },
  methods: {
    ...mapActions("XRlease", ["setUrlData"]),
    updateCity(data) {
      // console.log("授权成功调用，获取的", window.location.href);
      // debugger
      // const getUserLocatio ="";
      getuserinfo()
        .then((res) => {})
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    getAllGoodsData() {
      const params = {
        page: 1,
        page_size: 10,
      };
      getAllGoods(params)
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    // 获取全部设备
    getAllCategoryList() {
      const params = {
        page: 1,
        page_size: 5,
      };
      getAllCategory(params)
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    getQueryParams() {
      // 获取URL中"#"后面的部分，包括"#"
      const hash = window.location.hash;

      // 去掉"#"
      const hashString = hash.split("?")[1];
      // .slice(1)

      // 如果hashString为空，则返回一个空对象
      if (!hashString) return {};

      // 将hash字符串分割为参数数组，参数之间由"&"分隔
      const hashParamsArray = hashString.split("&");

      // 创建一个空对象来存储参数
      const params = {};

      // 遍历每个参数，并使用"="分割键和值
      hashParamsArray.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = value || true; // 如果没有值，默认为true（或你可以根据需要设置为null或undefined）
      });

      // 返回包含所有hash参数的对象
      return params;
    },
  },
  mounted() {
    this.url = window.location.href;
    this.getUrl = parseUrl(this.url);
    if (this.xx != 0) {
      if (s != 0) {
        this.fo.zt = x1;
        this.jj = decodeURIComponent(this.getUrl.parameter.head_img_url);
        this.name.num1 = decodeURIComponent(this.getUrl.parameter.nickname);
      }
    }
    const allParams = this.getQueryParams();
    if (allParams.openid) {
      this.fo.getOpenid = allParams.openid;
      this.nickname =  decodeURIComponent(allParams.nickname);
      this.head_img_url = decodeURIComponent(allParams.head_img_url);
      this.$store.dispatch("xrlease/setUrlData", allParams);
    } else {
      let urlSendData = this.$store.getters.urlSendData;
      console.log("vuex存储数据", urlSendData);
      if (urlSendData.openid) {
        // vuex里有，调用它
        this.fo.getOpenid = urlSendData.openid;
        this.nickname = decodeURIComponent(urlSendData.nickname);
        this.head_img_url = decodeURIComponent(urlSendData.head_img_url);
      } else {
        Toast("系统错误，请重新进入！");
      }
    }
  },
};
</script>
<style>
.div-title {
  height: 50px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
}
.device-title {
  font-size: 0.32rem;
}
.on_line {
  color: #1fd86d;
  margin-left: 1px;
}
.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
.info .user-nickname {
  font-size: 0.32rem;
  max-width: 2rem;

  /* 防止文本溢出容器 */
  overflow: hidden;

  /* 强制文本在一行内显示 */
  white-space: nowrap;

  /* 当文本溢出时，用省略号(...)显示 */
  text-overflow: ellipsis;
}
.user-img {
  margin-left: 10px;
  margin-right: 10px;
}
.van-image {
  position: relative;
  display: inline-block;
}
.image__loading {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--van-image-placeholder-text-color);
  font-size: var(--van-image-placeholder-font-size);
  background: var(--van-image-placeholder-background);
}

.van-tabs {
  position: relative;
}
</style>
