<template>
  <div class="pages">
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="videoLisy">
        <div class="eachBox" v-for="(item, index) in myDataList" :key="index">
          <DPlayerComponent :pic="item.filename" :videoUrl="item.header" />
          <div class="upTime">
            <span>上传时间：{{ item.creattime }}</span>
            <van-button
              @click="playGradeFun(item)"
              color="#fff"
              size="small"
              style="background-color: transparent"
              round
              plain
              type="primary"
              >我要上墙</van-button
            >
          </div>
        </div>
      </div>
      <van-empty v-if="myDataList.length==0" description="暂无AI合成视频，下拉刷新" />
    </van-pull-refresh>
  </div>
</template>

<script>
import DPlayerComponent from "./DPlayerComponent.vue";
import { getVideoList, playGrade } from "../../api/index";
export default {
  data() {
    return {
      myDataList: [],
      isLoading: false,
    };
  },
  components: { DPlayerComponent },
  mounted() {
      // this.getVideoListFun();
    },
  created() {},
  methods: {
    onRefresh() {
      this.getVideoListFun();
    },  
    getVideoListFun() {
      getVideoList({ sorth: "名画也疯狂" }).then((res) => {
        this.myDataList = res.resmsg;
        this.isLoading = false;
        console.log(res.resmsg);
      });
    },
    playGradeFun(item) {
      playGrade({
        vid: item.id,
        screen: 1,
      }).then((res) => {
        if(res.rescode==200){
          
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
}
.videoLisy {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 0.2rem;
  .eachBox {
    font-size: 16px;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    color: #fff;
    margin-bottom: 10px;
    border-radius: 0.2rem;
    font-size: 0.28rem;
    .upTime {
      padding: 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
