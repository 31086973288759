<template>
  <div>
    <div ref="lottieContainer" style="width:1.2rem; height: 1.2rem;"></div>
  </div>
</template>

<script>
import lottie from 'lottie-web';
// import jsons from './animationData.json';

export default {
  name: 'LottieExample',
  mounted() {
    console.log(require('@/assets/animationData.json'))
    // 假设你有一个名为'animationData.json'的动画文件放在public或assets目录下
    this.loadAnimation();
  },
  methods: {
    loadAnimation() {
      // 从公共路径加载动画数据
      lottie.loadAnimation({
        container: this.$refs.lottieContainer, // 绑定到Vue模板中的DOM元素
        renderer: 'svg', // 或者 'canvas', 'html'
        loop: true, // 是否循环播放
        autoplay: true, // 是否自动播放
        path :('http://h5.3vppt.com/videoFile/animationData.json'), // 动画文件的路径，相对public目录
        // path:jsons
      });
    },
  },
  beforeDestroy() {
    // 清理动画实例，避免内存泄漏
    if (this.animation) {
      this.animation.destroy();
      this.animation = null;
    }
  },
};
</script>