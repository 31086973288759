import { request } from '../axios'

  //获取退款接口
  export const allOrders = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/allOrders',
    method: 'post',
    params
  })
  //获取状态接口
  export const orderStates = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/orderStates',
    method: 'post',
    params
  })
  //获取状态接口
  export const refundOrder = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/refundOrder',
    method: 'post',
    params
  })
  //获取退款金额
  export const returnConsumptionAmount = (data) => 
  request({
    url: 'http://ppt.3vppt.com/wx/v1/returnConsumptionAmount',
    method: 'post',
    data
  })
//获取签名
export const getSignPackage = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/getSignPackage',
    method: 'post',
    params
  })
//获取支付金额
export const yksReturnConsumptionAmount = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/yksReturnConsumptionAmount',
    method: 'post',
    params
  })

//获取支付金额
export const yksRcm = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/yksRcm',
    method: 'post',
    params
  })