import { request } from './axios'


// 新获取openid的接口
export const getwxOpenid = (params) =>
    request({
        url: 'http://ppt.3vppt.com/wx/v1/getOpenId',
        method: 'get',
        params
    })
// 通过openid获取token

let leaseUrl = process.env.VUE_APP_API_URL

export const getSwapToken = (params) =>
    request({
        url: leaseUrl+'/api/swapToken/',
        method: 'post',
        params
    })
export const LicensePost = (params) =>
    request({
        url: leaseUrl +'/api/License/',
        method: 'post',
        params
    })
export const LicenseGetList = (params) =>
    request({
        url: leaseUrl +'/api/License/',
        method: 'get',
        params
    })
// 获取微信code
export const GetWxCode = (params) =>
    request({
        url: leaseUrl + '/api/wxCode/',
        method: 'post',
        params
    })
// 获取微信code
export const GetWaitToken = (params) =>
    request({
        url: leaseUrl + '/api/waitToken/',
        method: 'post',
        params
    })

    