<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    this.$nextTick(() => {
      const axios = require('axios');
      axios.get('http://ppt.3vppt.com/wx/v1/wechatMemberLogin')  
        .then(function (response) {  
          // 请求成功时处理响应数据  
          console.log(response.data);  
          // window.location.href = response.data;
        })  
        .catch(function (error) {  
          // 请求失败时处理错误  
          console.error(error);  
        });
    });
  },
  methods: {
    gotowx() {
      setTimeout(() => {
        window.location.href = "http://ppt.3vppt.com/wx/v1/getAuth";
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
