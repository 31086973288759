<template>
    <div>
        <div class="title">
            <span>支付界面</span>
        </div>
        <div class="img">
        <vue-qr
            ref="qrCode"
            :text="text"
            :logoSrc="logoSrc"
            :color-dark="randomColor"
            :callback="qrCodeCallback">
        </vue-qr>
        </div>
        <div class="buttont">
        <van-button type="primary" @click="exitt" >取消支付</van-button>

        </div>
    </div>

  </template>
<script>
import VueQr from 'vue-qr'

export default {

  components: {
    VueQr
  },
  data () {
    return {
      text: 'http://localhost:8080/#/pay',
      logoSrc: '',
      randomColor: 'black',
      qrCodeUrl: '' // 存放生成的二维码url
    }
  },
  methods: {
    qrCodeCallback (url) {
      this.qrCodeUrl = url
    },
    exitt () {
      this.$router.push('/XRlease/home')
    }

  }
}
</script>
<style>
.title{
    font-size: 30px;
    text-align: center;

}
.img{
    display: grid;
  place-items: center; /* 同时实现水平和垂直居中 */
  height: 100vh; /* 或其他你需要的值，以确保容器有足够的高度 */
  max-width: 100%; /* 确保图片不会超出容器宽度 */
  height: auto;
}
.buttont{
    margin-left: 50%;
}
</style>
