<template>
  <div>
    <!-- <input
      type="file"
      ref="videoInput"
      @change="captureFirstFrame"
      accept="video/*"
    /> -->
    <canvas ref="previewCanvas" style="display: none"></canvas>
    <img ref="previewImage" alt="Video First Frame" v-if="firstFrameSrc" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstFrameSrc: null,
    };
  },
  methods: {
    async captureFirstFrame(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const videoElement = document.createElement("video");
      const canvas = this.$refs.previewCanvas;
      const ctx = canvas.getContext("2d");

      videoElement.src = URL.createObjectURL(file);
      videoElement.onloadedmetadata = () => {
        // 设置视频当前播放时间为0，准备截取第一帧
        videoElement.currentTime = 2;
        // 确保视频已经加载足够的数据来显示第一帧
        console.log("00000000000", videoElement);
        // if (videoElement.readyState >= videoElement.HAVE_CURRENT_DATA) {
        //   canvas.width = videoElement.videoWidth;
        //   canvas.height = videoElement.videoHeight;
        //   ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        //   // 将canvas内容转为dataURL作为图片显示
        //   this.firstFrameSrc = canvas.toDataURL("image/jpeg");
        // } else {
          videoElement.addEventListener(
            "canplay",
            () => {
              setTimeout(() => {
                canvas.width = videoElement.videoWidth;
                canvas.height = videoElement.videoHeight;
                ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                this.firstFrameSrc = canvas.toDataURL("image/jpeg");
                videoElement.removeEventListener(
                  "canplay",
                  this.captureFirstFrame
                );
              }, 100);
            },
            { once: true }
          );
        // }
      };
    },
  },
};
</script>