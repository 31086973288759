<template>
  <!-- <div class="van-grid" style="padding-left: 10px;">

                    <div  v-for="(item, index) in getdata" :key="index" id="pay" class="van-grid-item" style="flex-basis: 33.3333%; padding-right: 10px; padding-bottom: 10px; position: relative;">
                        <div class="van-grid-item__content">
                            <div  class="van-image" style="width: 60px; height: 60px; margin: auto; align-items: center; align-content: center;">
                                <img class="van-image__img" src="http://qiniu.jingxiaoyu.top/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20240315150300.png17104862014781710486201479?e=3857969848&token=_5DZCrVmqmCbAYcJoUcLX3XtjHnsFRQikunpM3ms:eSB43CP5uEGDlJENqdhhHk_ssaE=">
                            </div>
                            <span id="getid" style="font-size: 14px; margin-top: 9px; text-align: center;">{{ item.id }}</span>
                            <span style="font-size: 11px; color: cadetblue; margin-top: 5px; text-align: center;">箱门:{{ item.id }}</span>
                            <div style="display: flex; flex-direction: row; margin-top: 6px; justify-content: space-between; align-items: center;">
                                <span style="display: flex; flex-direction: row; margin-top: 6px; justify-content: space-between; align-items: center;">
                                    ￥{{ getdatadeposit.deposit }}
                                    <span style="font-size: 10px;">元押金</span>
                                </span>

                                <van-button icon="plus" type="primary" round size="mini" @click="Add(item.id)" id="wxappid"></van-button>
                            </div>
                        </div>
                        <span class="flag_zu" style="font-size: 14px; position: absolute; top: 5px; left: 5px; float: right;">租</span>
                    </div>

                </div> -->
  <div id="ap" class="van-all">
    <div class="van-tab__panel">
      <div class="bg" style="">
        <!-- <div class="van-grid">
          <div class="List" style="margin-top: 2px">
            <van-cell>
              <span>计费方式一</span>
              <span style="margin-left: 40%"></span>
            </van-cell>
            <van-cell>
              <span>套餐价格</span>
              <span style="margin-left: 30%">20元</span>
            </van-cell>
            <van-cell>
              <span>套餐时长</span>
              <span style="margin-left: 30%">10分钟</span>
            </van-cell>
            <van-cell>
              <span>超出时长</span>
              <span style="margin-left: 30%">2元/分钟</span>
            </van-cell>
            <van-cell>
              <span>设备押金</span>
              <span style="margin-left: 30%">100元</span>
            </van-cell>
            <van-cell>
              <span>备注</span>
              <span style="margin-left: 20%"
                >套餐固定时长为10分钟，使用时长超出10分钟的部分每分钟计费2元，体验金额会从押金内扣除。</span
              >
            </van-cell>
            <van-cell>
              <van-button
                class="btn"
                type="danger"
                @click="Add(4)"
                style="width: 100%"
                >选择计费方式一</van-button
              >
            </van-cell>
          </div>
        </div> -->
        <!--  <div class="van-grid">
          <div class="List" style="margin-top: 2px">
            <van-cell>
              <span>计费方式二</span>
              <span style="margin-left: 40%"></span>
            </van-cell>
            <van-cell>
              <span>免费时长</span>
              <span style="margin-left: 30%">5分钟</span>
            </van-cell>
            <van-cell>
              <span>起出时长</span>
              <span style="margin-left: 30%">4元/分钟</span>
            </van-cell>
            <van-cell>
              <span>设备押金</span>
              <span style="margin-left: 30%">100元</span>
            </van-cell>
            <van-cell>
              <span>备注</span>
              <span style="margin-left: 20%"
                >选择该方式会有5分钟免费使用时间，超出5分钟的部分每分钟计费4元，体验金额汇总押金内扣除。</span
              >
            </van-cell>
            <van-cell>
              <van-button
                class="btn"
                type="danger"
                @click="Add(2)"
                style="width: 100%"
                >选择计费方式二</van-button
              >
            </van-cell>
          </div>
        </div> -->
        <div class="van-grid">
          <div
            class="List"
            v-for="(item, index) in wxPayOrderList"
            :key="index"
            style="margin-top: 2px"
          >
            <van-cell>
              <span>{{ item.name }}</span>
              <span style="margin-left: 40%"></span>
            </van-cell>
            <van-cell>
              <span>套餐价格</span>
              <span style="margin-left: 30%">{{ item.thali / 100 }}元</span>
            </van-cell>
            <van-cell>
              <span>套餐时长</span>
              <span style="margin-left: 30%">{{ item.meal }}分钟</span>
            </van-cell>
            <van-cell>
              <span>起出时长</span>
              <span style="margin-left: 30%"
                >{{ item.excer / 100 }}元/分钟</span
              >
            </van-cell>
            <van-cell>
              <span>设备押金</span>
              <span style="margin-left: 30%">{{ item.deposit / 100 }}元</span>
            </van-cell>
            <van-cell>
              <span>备注</span>
              <span style="margin-left: 20%"
                >套餐固定时长为{{ item.meal }}分钟，使用时长超出{{
                  item.meal
                }}分钟的部分每分钟计费{{
                  item.excer / 100
                }}元，体验金额会从押金内扣除。</span
              >
            </van-cell>
            <van-cell>
              <van-button
                class="btn"
                type="danger"
                @click="newAdd(item)"
                style="width: 100%"
                >{{ item.name }}</van-button
              >
                <!-- @click="newAdd(item)" 
                
                @click="Add(item)"
                -->
            </van-cell>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="80%"
      :center="true"
    >
      <div class="elradio" style="margin-top: 20px">
        <el-radio v-model="radio1" label="1" border size="medium"
          >套餐时长+起过时长的计费</el-radio
        >
        <el-radio v-model="radio1" label="2" border size="medium"
          >免费时长+使用时长的计费</el-radio
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialog()">确 定</el-button>
      </span>
    </el-dialog>
  </div>

  <!-- <div class="bnt">
        <van-button type="primary" size="large" style="width: 100%; margin-top: 10px;" @click="pay">立即支付</van-button>
    </div> -->
</template>
<script>
import index from "./index.css";
import {
  pay,
  getAllGoods,
  orderStates,
  getSignPackage,
  getwxPayList,
} from "../../api/homeApi";

import { generateRandomID } from "@/view/XRlease/myhome/components/outtradeno.js";
import wx from "weixin-js-sdk";
import { mapState, mapActions } from "vuex";
// 导入微信支付js
// import {onBridgeReady } from '@/views/myhome/components/pay'

// 定义接收res的常量
export const GETRES = {};
export default {
  props: {
    dateMes: {
      type: Object,
    },
  },
  data() {
    return {
      num: "",
      amount: "100",
      getdata: [],
      getdatadeposit: "",
      // 位
      bit: 32,
      // 定义变量接收generateRandomID
      getrandomid: "",
      // 得到箱子的id
      getnumid: "",
      dialogVisible: false,
      radio1: "1",
      message1:
        "需先缴纳押金100元</br>扣除固定时长</br>每1分钟2元</br>封顶99元",
      message2: "需先缴纳押金100元</br>按时段收费</br>每1分钟3元</br>封顶99元",
      signPackage: "",
      url: "",
      resultStr: "",
      wxData: "",
      wxPayOrderList: [],
    };
  },
  components: {
    index,
  },
  mounted() {
    // this.getAllGoodsData()
    // this.dialogVisible = true
    // this.Add(0)
    // this.getOrderStates()
    // this.getOrderStates()
    //
    // 定义变量调用生成随机订单号的方法
    // 得到盒子的id
    // let that =this
    // that.$dialog
    // .alert({
    //   message: "支付成功！", //改变弹出框的内容
    //   showCancelButton: false, //展示取水按钮
    // })
    // .then(() => {
    //   that.$router.push({
    //     path: "/XRlease/mysi",
    //   });
    // });
    this.getwxPayListFun();
  },
  methods: {
    getwxPayListFun() {
      getwxPayList().then((res) => {
        console.log(res);
        this.wxPayOrderList = res.resmsg;
        this.$store.dispatch("xrlease/setWxPL", res.resmsg);
        console.log(this.$store.getters.wxPayOrderList,'8888888888');
      });
    },
    //调起订单支付状态接口
    newGetOrderStates() {
      const params = {
        out_trade_no: this.getrandomid, //订单
        pay_status: 1,
      };
      return new Promise((resolve, reject) => {
        orderStates(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.error("查询订单状态 -- Error:", error);
            reject();
          });
      });
    },
    wxPayThree(getItemId) {
      let resultStr = "1,1",
        that = this;
      that.getrandomid = generateRandomID(this.bit);
      console.log("订单号" + that.getrandomid);
      console.log("扫码结果" + resultStr);
      const params = {
        payData: {
          out_trade_no: that.getrandomid, // 订单号
          description: "设备租赁金",
          // money: 1, // 单位分
          money: getItemId == 3 ? 20000 : 10000, // 单位分
          openid: this.dateMes.getOpenid,
          goods_id: resultStr.split(",")[0] ?? 0,
          type: getItemId,
          scenic_id: resultStr.split(",")[1] ?? 0,
        },
      };
      console.log(params);
      console.log("点击了" + getItemId);
      pay(params)
        .then((res) => {
          // this.GETRES = res[1]
          // console.log("开始打印咯"+JSON.stringify(this.GETRES));
          console.log("pay的支付回调参数", res);
          this.getres = res;
          onBridgeReady(this.getres[1]);

          // 调起微信支付
          function onBridgeReady(data) {
            const GETRES = data;
            // console.log("lo发空间的啦"+ JSON.stringify(GETRES));
            window.WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              {
                appId: process.env.VUE_APP_INFO_APPID, // 公众号ID，由商户传入
                timeStamp: GETRES.timeStamp, // 时间戳，自1970年以来的秒数
                nonceStr: GETRES.nonceStr, // 随机串
                package: GETRES.prepayId,
                signType: "RSA", // 微信签名方式
                paySign: GETRES.sign,
              },
              function (res) {
                console.log("支付状态----------1", res);
                // 支付成功
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  console.log("支付成功--");
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  that.$dialog
                    .alert({
                      // title: document.getElementById("getid").innerText, //加上标题
                      message: "支付成功！", //改变弹出框的内容
                      showCancelButton: false, //展示取水按钮
                    })
                    .then(() => {
                      that.newGetOrderStates();
                      setTimeout(() => {
                        that.$router.push({
                          path: "/XRlease/mysi",
                        });
                      }, 500);
                    });
                }
                // 支付过程中用户取消
                if (res.err_msg == "get_brand_wcpay_request:cancel") {
                  console.log("支付取消--");
                  that.$dialog
                    .alert({
                      // title: document.getElementById("getid").innerText, //加上标题
                      message: "支付取消！", //改变弹出框的内容
                      showCancelButton: false, //展示取水按钮
                    })
                    .then(() => {});
                }
                // 支付失败
                if (res.err_msg == "get_brand_wcpay_request:fail") {
                  console.log("支付失败--");
                  that.$dialog
                    .alert({
                      // title: document.getElementById("getid").innerText, //加上标题
                      message: "支付失败！", //改变弹出框的内容
                      showCancelButton: false, //展示取水按钮
                    })
                    .then(() => {});
                }
                /**
                 * 其它
                 * 1、请检查预支付会话标识prepay_id是否已失效
                 * 2、请求的appid与下单接口的appid是否一致
                 * */
                if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
                }
              }
            );
          }
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    wxPay(getitemid, resultStr) {
      let that = this;
      const getItemId = getitemid;
      // 扫描结束之后进入支付
      const getres = [];
      console.log(this.dateMes);
      if (this.dateMes.zt === "") {
        if (getItemId == 1) {
          // this.$dialog.alert({
          //     // title: document.getElementById("getid").innerText, //加上标题
          //     message: this.message1, //改变弹出框的内容
          //     showCancelButton: true //展示取水按钮
          // })
          // .then(() => { //点击确认按钮后的调用
          // this.$dialog.alert({
          //     // title: document.getElementById("getid").innerText, //加上标题
          //     message: "第一次押金是100元",
          // })
          // .then(() =>{
          this.getrandomid = generateRandomID(this.bit);
          console.log("订单号" + this.getrandomid);
          console.log("扫码结果" + resultStr);
          const params = {
            payData: {
              out_trade_no: this.getrandomid, // 订单号
              description: "设备租赁金",
              // money: 1, // 单位分
              money: 10000, // 单位分
              openid: this.dateMes.getOpenid,
              goods_id: resultStr.split(",")[0] ?? 0,
              type: getItemId,
              scenic_id: resultStr.split(",")[1] ?? 0,
            },
          };
          console.log(params);
          console.log("点击了" + getItemId);
          pay(params)
            .then((res) => {
              // this.GETRES = res[1]
              // console.log("开始打印咯"+JSON.stringify(this.GETRES));
              console.log("pay的支付回调参数", res);
              this.getres = res;
              onBridgeReady(this.getres[1]);

              // 调起微信支付
              function onBridgeReady(data) {
                const GETRES = data;
                // console.log("lo发空间的啦"+ JSON.stringify(GETRES));
                window.WeixinJSBridge.invoke(
                  "getBrandWCPayRequest",
                  {
                    appId: process.env.VUE_APP_INFO_APPID, // 公众号ID，由商户传入
                    timeStamp: GETRES.timeStamp, // 时间戳，自1970年以来的秒数
                    nonceStr: GETRES.nonceStr, // 随机串
                    package: GETRES.prepayId,
                    signType: "RSA", // 微信签名方式
                    paySign: GETRES.sign,
                  },
                  function (res) {
                    console.log("支付状态----------1", res);
                    // 支付成功
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                      console.log("支付成功--");
                      // 使用以上方式判断前端返回,微信团队郑重提示：
                      //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                      that.$dialog
                        .alert({
                          // title: document.getElementById("getid").innerText, //加上标题
                          message: "支付成功！", //改变弹出框的内容
                          showCancelButton: false, //展示取水按钮
                        })
                        .then(() => {
                          that.newGetOrderStates();
                          setTimeout(() => {
                            that.$router.push({
                              path: "/XRlease/mysi",
                            });
                          }, 500);
                        });
                    }
                    // 支付过程中用户取消
                    if (res.err_msg == "get_brand_wcpay_request:cancel") {
                      console.log("支付取消--");
                      that.$dialog
                        .alert({
                          // title: document.getElementById("getid").innerText, //加上标题
                          message: "支付取消！", //改变弹出框的内容
                          showCancelButton: false, //展示取水按钮
                        })
                        .then(() => {});
                    }
                    // 支付失败
                    if (res.err_msg == "get_brand_wcpay_request:fail") {
                      console.log("支付失败--");
                      that.$dialog
                        .alert({
                          // title: document.getElementById("getid").innerText, //加上标题
                          message: "支付失败！", //改变弹出框的内容
                          showCancelButton: false, //展示取水按钮
                        })
                        .then(() => {});
                    }
                    /**
                     * 其它
                     * 1、请检查预支付会话标识prepay_id是否已失效
                     * 2、请求的appid与下单接口的appid是否一致
                     * */
                    if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
                    }
                  }
                );
              }
            })
            .catch((error) => {
              // 处理请求失败的情况
              console.error("Error:", error);
              // 在这里可以显示错误信息给用户，或者进行其他处理
            });
          // })

          // console.log("点击了确认按钮噢" + a)
          // })
          // .catch(() => { //点击取消按钮后的调用
          //     var a=0;
          //     // console.log("点击了取消按钮噢" + a)
          // })
        } else {
          // this.$dialog.alert({
          //     // title: document.getElementById("getid").innerText, //加上标题
          //     message: this.message2, //改变弹出框的内容
          //     showCancelButton: true //展示取水按钮
          // })
          // .then(() => { //点击确认按钮后的调用
          // this.$dialog.alert({
          //     // title: document.getElementById("getid").innerText, //加上标题
          //     message: "第一次押金是100元",
          // })
          // .then(() =>{
          this.getrandomid = generateRandomID(this.bit);
          console.log("订单号" + this.getrandomid);
          console.log("扫码结果" + resultStr);
          const params = {
            payData: {
              out_trade_no: this.getrandomid, // 订单号
              description: "设备租赁金",
              money: 10000, // 单位分
              // money: 1, // 单位分
              openid: this.dateMes.getOpenid,
              goods_id: resultStr.split(",")[0] ?? 0,
              type: getItemId,
              scenic_id: resultStr.split(",")[1] ?? 0,
            },
          };
          console.log(params);
          console.log("点击了" + getItemId);
          pay(params)
            .then((res) => {
              // this.GETRES = res[1]
              // console.log("开始打印咯"+JSON.stringify(this.GETRES));

              this.getres = res;
              onBridgeReady(this.getres[1]);

              // 调起微信支付
              function onBridgeReady(data) {
                const GETRES = data;
                // console.log("lo发空间的啦"+ JSON.stringify(GETRES));
                window.WeixinJSBridge.invoke(
                  "getBrandWCPayRequest",
                  {
                    appId: process.env.VUE_APP_INFO_APPID, // 公众号ID，由商户传入
                    timeStamp: GETRES.timeStamp, // 时间戳，自1970年以来的秒数
                    nonceStr: GETRES.nonceStr, // 随机串
                    package: GETRES.prepayId,
                    signType: "RSA", // 微信签名方式：
                    paySign: GETRES.sign,
                  },
                  function (res) {
                    console.log("支付状态----------2", res);
                    // 支付成功
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                      console.log("支付成功--");
                      // 使用以上方式判断前端返回,微信团队郑重提示：
                      //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                      that.$dialog
                        .alert({
                          // title: document.getElementById("getid").innerText, //加上标题
                          message: "支付成功！", //改变弹出框的内容
                          showCancelButton: false, //展示取水按钮
                        })
                        .then(() => {
                          that.newGetOrderStates();
                          setTimeout(() => {
                            that.$router.push({
                              path: "/XRlease/mysi",
                            });
                          }, 500);
                        });
                    }
                    // 支付过程中用户取消
                    if (res.err_msg == "get_brand_wcpay_request:cancel") {
                      console.log("支付取消--");
                      that.$dialog
                        .alert({
                          // title: document.getElementById("getid").innerText, //加上标题
                          message: "支付取消！", //改变弹出框的内容
                          showCancelButton: false, //展示取水按钮
                        })
                        .then(() => {});
                    }
                    // 支付失败
                    if (res.err_msg == "get_brand_wcpay_request:fail") {
                      console.log("支付失败--");
                      that.$dialog
                        .alert({
                          // title: document.getElementById("getid").innerText, //加上标题
                          message: "支付失败！", //改变弹出框的内容
                          showCancelButton: false, //展示取水按钮
                        })
                        .then(() => {});
                    }
                    /**
                     * 其它
                     * 1、请检查预支付会话标识prepay_id是否已失效
                     * 2、请求的appid与下单接口的appid是否一致
                     * */
                    if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
                    }
                  }
                );
              }
            })
            .catch((error) => {
              // 处理请求失败的情况
              console.error("Error:", error);
              // 在这里可以显示错误信息给用户，或者进行其他处理
            });
          // })

          // console.log("点击了确认按钮噢" + a)
          // })
          // .catch(() => { //点击取消按钮后的调用
          //     var a=0;
          //     // console.log("点击了取消按钮噢" + a)
          // })
        }
      }
    },
    async newAdd(item){
      await this.timesignLink(); //判断系统
      this.newWxPayThree(item);
      // this.yazscanQRCode();
    },
    
    yazscanQRCode() {
      // function(res) {
      //     console.log(res);
      // };
      this.url = window.location.href.split("#")[0];
      const params = {
        url: this.url,
      };
      getSignPackage(params)
        .then((res) => {
          this.signPackage = res;
          console.log(this.signPackage);

          wx.config({
            debug: false, // 开发阶段可以设为true查看调试信息
            appId: res.appId,
            timestamp: res.timestamp,
            nonceStr: res.nonceStr,
            signature: res.signature,
            jsApiList: ["scanQRCode"], // 请求使用的JS接口列表，这里只列出scanQRCode接口
          });

          // 微信配置成功后的回调
          wx.ready(() => {
            console.log("微信JS-SDK配置成功");

            // 这里可以开始调用微信的API
            wx.scanQRCode({
              // onlyFromCamera: true,
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: function (res) {
                this.resultStr = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                console.log("扫描结果:", this.resultStr);
              },

              fail: function (err) {
                console.error("扫描失败", err);
              },
            });
          });

          // 配置失败的回调
          wx.error((res) => {
            console.error("微信JS-SDK配置错误", res);
          });
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    newWxPayThree(item) {
      let that = this;
      that.getrandomid = generateRandomID(this.bit); //生成订单号
      console.log("订单号" + that.getrandomid);
      let objs = item.name//JSON.stringify(item);

      const params = {
        payData: {
          out_trade_no: that.getrandomid, // 订单号
          description: objs,
          // money: 1, // 单位分
          money: item.deposit, //押金 单位分
          openid: this.dateMes.getOpenid,
          goods_id:1,
          type: item.id,
          scenic_id: 1,
        },
      };
      console.log(params,process.env.VUE_APP_INFO_APPID,'-------------');
      pay(params)
        .then((res) => {
          // this.GETRES = res[1]
          // console.log("开始打印咯"+JSON.stringify(this.GETRES));
          console.log("pay的支付回调参数", res);
          this.getres = res;
          onBridgeReady(this.getres[1]);

          // 调起微信支付
          function onBridgeReady(data) {
            const GETRES = data;
            // console.log("lo发空间的啦"+ JSON.stringify(GETRES));
            window.WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              {
                appId: process.env.VUE_APP_INFO_APPID, // 公众号ID，由商户传入
                timeStamp: GETRES.timeStamp, // 时间戳，自1970年以来的秒数
                nonceStr: GETRES.nonceStr, // 随机串
                package: GETRES.prepayId,
                signType: "RSA", // 微信签名方式
                paySign: GETRES.sign,
              },
              function (res) {
                console.log("支付状态----------1", res);
                // 支付成功
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  console.log("支付成功--");
                  // 使用以上方式判断前端返回,微信团队郑重提示：
                  //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                  that.$dialog
                    .alert({
                      // title: document.getElementById("getid").innerText, //加上标题
                      message: "支付成功！", //改变弹出框的内容
                      showCancelButton: false, //展示取水按钮
                    })
                    .then(() => {
                      that.newGetOrderStates();
                      setTimeout(() => {
                        that.$router.push({
                          path: "/XRlease/mysi",
                        });
                      }, 500);
                    });
                }
                // 支付过程中用户取消
                if (res.err_msg == "get_brand_wcpay_request:cancel") {
                  console.log("支付取消--");
                  that.$dialog
                    .alert({
                      // title: document.getElementById("getid").innerText, //加上标题
                      message: "支付取消！", //改变弹出框的内容
                      showCancelButton: false, //展示取水按钮
                    })
                    .then(() => {});
                }
                // 支付失败
                if (res.err_msg == "get_brand_wcpay_request:fail") {
                  console.log("支付失败--");
                  that.$dialog
                    .alert({
                      // title: document.getElementById("getid").innerText, //加上标题
                      message: "支付失败！", //改变弹出框的内容
                      showCancelButton: false, //展示取水按钮
                    })
                    .then(() => {});
                }
                /**
                 * 其它
                 * 1、请检查预支付会话标识prepay_id是否已失效
                 * 2、请求的appid与下单接口的appid是否一致
                 * */
                if (res.err_msg == "调用支付JSAPI缺少参数：total_fee") {
                }
              }
            );
          }
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    async Add(getitemid) {
      // function(res) {
      //     console.log(res);
      // };
      await this.timesignLink();
      this.wxData = this.dateMes;
      this.url = window.location.href.split("#")[0];
      const params = {
        url: this.url,
      };
      console.log(params);
      if (getitemid == 3 || getitemid == 4 || getitemid == 5) {
        this.wxPayThree(getitemid);
        return;
      }
      getSignPackage(params)
        .then((res) => {
          this.signPackage = res;
          console.log(this.signPackage);

          wx.config({
            debug: false, // 开发阶段可以设为true查看调试信息
            appId: res.appId,
            timestamp: res.timestamp,
            nonceStr: res.nonceStr,
            signature: res.signature,
            jsApiList: ["scanQRCode"], // 请求使用的JS接口列表，这里只列出scanQRCode接口
          });

          // 微信配置成功后的回调
          wx.ready(() => {
            console.log("微信JS-SDK配置成功");
            this.scanQRCode(getitemid);
          });

          // 配置失败的回调
          wx.error((res) => {
            console.error("微信JS-SDK配置错误", res);
          });
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    timesignLink() {
      // 判断是否时安卓系统获取路径
      let signLink = /(Android)/i.test(navigator.userAgent)
        ? location.href
        : window.entryUrl;
      // 刷新后路径变化使用当前路径
      console.log(
        signLink,
        "0000",
        encodeURIComponent(signLink),
        /(Android)/i.test(navigator.userAgent)
      );
      if (encodeURIComponent(signLink) == "undefined") signLink = location.href;
    },
    scanQRCode(getitemid) {
      // const resultStr = ''
      const that = this;
      // 这里可以开始调用微信的API
      wx.scanQRCode({
        // onlyFromCamera: true,
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          this.resultStr = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          // console.log('扫描结果:', this.resultStr);
          // this.resultStr = result
          // alert(this.resultStr);
          // 处理扫描结果
          // that.wxPay(getitemid,this.resultStr)
          // this.getAllGoodsData()

          console.log("扫描结果:", this.resultStr);
          // debugger
          that.$dialog
            .alert({
              message:
                "设备扫描完成，请确认是否租借设备" +
                this.resultStr.split(",")[0],
              showCancelButton: true, // 展示取消按钮
            })
            .then(() => {
              that.wxPay(getitemid, this.resultStr);
            });
        },

        fail: function (err) {
          console.error("扫描失败", err);
        },
      });
      // this.$dialog.alert({
      //             // title: document.getElementById("getid").innerText, //加上标题
      //             message: "设备扫描完成，请确认是否租借设备"+this.resultStr,

      //             // showCancelButton: true //展示取水按钮
      //         })
      //         .then(() => {
      //             // this.wxPay(getitemid,this.resultStr)
      //             console.log(this.resultStr);
      //             debugger//运行看
      //         })
    },
    getAllGoodsData() {
      const params = {
        page: 1,
        page_size: 10,
      };
      getAllGoods(params)
        .then((res) => {
          console.log(JSON.stringify(res));

          // this.getdata = res.data.list
          // this.getdatadeposit = this.getdata[1]
          // console.log("拿到值了"+JSON.stringify(this.getdata));
          // debugger
          // this.num = this.getdata.list[0].id
          // console.log("laldf"+ JSON.stringify(this.getdata.list[0].id))
          // this.Add(0)
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },

    dialog() {
      this.dialogVisible = true;
      this.Add(0);
    },

    // }
  },
};
</script>
<style lang="less" scoped>
.van-all {
  /* background-color: aqua; */
  height: 100%;
}
.van-tab__panel {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
}
.bg {
  padding-top: 2vh;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  /*background-color: #ededed;
    background-image: url("@/img/back.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover; */
}
.van-grid {
  display: flex;
  flex-wrap: wrap;
}
.van-grid-item {
  position: relative;
  box-sizing: border-box;
}
.van-grid-item__content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding: var(--van-grid-item-content-padding);
  background: var(--van-grid-item-content-background);
}
.van-image__img {
  display: block;
  width: 100%;
  height: 100%;
}
.click_add_sale {
  font-size: 22px;
  text-align: center;
  color: #fff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background-color: #19c764;
}
.flag_zu {
  text-align: center;
  color: #ffcb3b;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background-color: #0009;
}
.elradio:deep .el-radio--medium.is-bordered {
  height: 200px;
}
.elradio:deep .el-radio__label {
  word-break: normal;
  width: auto;
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden;
}
.elradio:deep .el-radio {
  margin: 0px 0px 0px 0px;
}
.elradio:deep .el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 0px;
}
</style>
