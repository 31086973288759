<template>
  <div>
    <van-tabs sticky animated swipeable @change="changeTabsNew" v-model="active">
      <van-tab style="min-height: 100vh;" title="AI合成">
        <RecordingVideo @changeTabs="changeTabs(1)" />
      </van-tab>
      <van-tab title="我的名画"> <myList ref="myList" /> </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import RecordingVideo from "./comp/RecordingVideo.vue";
import myList from "./comp/myList.vue";
export default {
  components: { RecordingVideo, myList },
  data() {
    return {
      active: 0,
      previewUrl: null, // 存储文件的预览URL
      fileIsImage: false, // 标记文件是否为图片
    };
  },
  mounted() {},
  methods: {
    changeTabsNew(i) {
      console.log(i);
      this.$nextTick(()=>{
        if (i == 1) this.$refs.myList.getVideoListFun();
      })
    },
    changeTabs(index) {
      this.active = index;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>