import { request } from './axios'

let leaseUrl = process.env.VUE_APP_API_URL
// 获取微信签名
export const getSignPackage = (params) =>
    request({
        url: 'https://ppt.3vppt.com/wx/v1/getSignPackage',
        method: 'post',
        params
    })
//上传文件
export const uploadFile = (data, postUrl) =>
    request({
        url: postUrl +'/api/sound/',
        method: 'post',
        params: data
    })
    // 获取服务上传url
export const postGradeComp = (data) =>
    request({
        url: leaseUrl +`/file/postGrade/${data}/`,
        method: 'get',
    })
//获取七牛云token
export const QiniuUpToken = (params, data) =>
    request({
        url: leaseUrl+'/file/QiniuUpToken/',
        method: 'post',
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })

// 新获取openid的接口
export const getwxOpenid = (params) =>
    request({
        url: 'http://ppt.3vppt.com/wx/v1/getOpenId',
        method: 'get',
        params
    })
// 通过openid获取token
export const getSwapToken = (params) =>
    request({
        url: leaseUrl+'/api/swapToken/',
        method: 'post',
        params
    })
// 上传服务器获取地址 
export const QiniuUpload = (params) =>
    request({
        url: leaseUrl+'/file/QiniuUpload/',
        method: 'post',
        params
    })
// 模板列表
export const postGradeList = (params) =>
    request({
        url: leaseUrl+'/file/postModels/',
        method: 'post',
    })
// 上传地址创建动画
export const postGradeOpenAdmin = (params) =>
    request({
        url: leaseUrl+'/file/postGrade/',
        method: 'post',
        params
    })
// 上传地址创建动画
export const getGradeOpenAdmin = (params) =>
    request({
        url: leaseUrl+'/file/postGrade/' + params + '/',
        method: 'get',
    })

// 上传地址创建动画
export const gettasksId = (params) =>
    request({
        url: leaseUrl+'/api/tasks/',
        method: 'post',
        params
    })

// 获取名画列表
export const getVideoList = (params) =>
    request({
        url: leaseUrl+'/file/FilesList/',
        method: 'get',
        params
    })
// 视频上墙
export const playGrade = (params) =>
    request({
        url: leaseUrl+'/file/playGrade/',
        method: 'post',
        params
    })
// 获取上墙列表
export const listGrade = (params) =>
    request({
        url: leaseUrl+'/file/listGrade/',
        method: 'post',
        params
    })