import Vue from 'vue'
import VueRouter from 'vue-router'
// XR租凭
// 导入组件
import XRleaseHome from '@/view/XRlease/myhome/home.vue'
import XRleaseMysi from '@/view/XRlease/myis/mysi.vue'
import XRleasePay from '@/view/XRlease/payment/pay.vue'
import XRleaseTime from '@/view/XRlease/myhome/components/time.vue'
import XRleaseShopping from '@/view/XRlease/myhome/shopping.vue'
import XRleaseFrist from '@/view/XRlease/myhome/shoppingch/frist.vue'
import XRleaseFome from '@/view/XRlease/myhome/shoppingch/fome.vue'
import XRleaseScan from '@/view/XRlease/myhome/shoppingch/scan.vue'
import XRleasebridge from '@/view/XRlease/bridge.vue'
import bridgeTwo from '@/view/XRlease/bridgeTwo.vue'


//报名系统
import signUpLogin from '@/view/signUp/views/login/login.vue'
import signUpAddshool from '@/view/signUp/views/addshool/addshool.vue'
import signUpHome from '@/view/signUp/views/home/home.vue'
import signUpApplication from '@/view/signUp/views/application/application.vue'
import signUpFromdialog from '@/view/signUp/views/application/components/fromdialog.vue'
import signUpAddform from '@/view/signUp/views/home/components/addform.vue'
import signUpUpform from '@/view/signUp/views/home/components/upform.vue'
import XRbutterfly from '@/view/signUp/views/activityShare/XRbutterfly.vue'
import addSignUpUser from '@/view/signUp/views/addSignUpUser/addSignUpUser.vue'
import activePage from '@/view/signUp/views/activityShare/activePage.vue'

//下载系统
import videoDownloader from '@/view/videoDownloader/index.vue'
import TemplateDownload from '@/view/videoDownloader/TemplateDownload.vue'
import videoDownloaderBridge from '@/view/videoDownloader/bridge.vue'
import voicedla from '@/view/videoDownloader/voicedla.vue'


//ai合成视频项目
import aiVideoHome from '@/view/aiVideo/home/index.vue'
import aiVideoRecording from '@/view/aiVideo/Recording/index.vue'
import PCWindow from '@/view/aiVideo/PCWindow/index.vue'
import getCode from '@/view/aiVideo/getCode/index.vue'
import aiVideobridge from '@/view/aiVideo/bridge.vue'


//ai合成视频项目
import ProjectBridge from '@/view/ProjectAuthorization/bridge/bridge.vue'
import ProjectGetCode from '@/view/ProjectAuthorization/bridge/index.vue'
import ProjectHome from '@/view/ProjectAuthorization/home/index.vue'
import ProjectHome1 from '@/view/ProjectAuthorization/home/index1.vue'
import ProjectpcWindos from '@/view/ProjectAuthorization/pcWindosPage/index.vue'

Vue.use(VueRouter)
const XRleaseroutes = [

  {
    path: '/XRlease/videoDownloaderBridge',
    name: '',
    component: videoDownloaderBridge,
    meta: {
      name: '授权'
    }
  },
  {
    path: '/XRlease/voicedla',
    name: '',
    component: voicedla,
    meta: {
      name: '授权'
    }
  },
  {
    path: '/videoDownloader',
    name: '视频下载',
    component: videoDownloader,
    meta: {
      name: '视频下载'
    }
  },
  {
    path: '/TemplateDownload',
    name: '模板下载',
    component: TemplateDownload,
    meta: {
      name: '模板下载'
    }
  },

  {
    path: '/XRlease/bridge',
    name: '',
    component: XRleasebridge,
    meta: {
      name: '授权'
    }
  },

  {
    path: '/XRlease/bridgeTwo',
    name: '',
    component: bridgeTwo,
    meta: {
      name: '授权'
    }
  },
  {
    path: '/XRlease/home',
    name: '首页',
    component: XRleaseHome,
    meta: {
      name: '首页'
    }
  },
  {
    path: '/XRlease/mysi',
    name: '我的',
    component: XRleaseMysi,
    meta: {
      name: '我的'
    }
  },
  {
    path: '/XRlease/pay',
    name: '支付界面',
    component: XRleasePay,
    meta: {
      name: '支付界面'
    }
  },
  {
    path: '/XRlease/time',
    name: '计时',
    component: XRleaseTime,
    meta: {
      name: '计时'
    }
  },
  {
    path: '/XRlease/shopping',
    name: '购物车',
    component: XRleaseShopping,
    meta: {
      name: '购物车'
    }
  },
  {
    path: '/XRlease/frist',
    name: '第一个',
    component: XRleaseFrist,
    meta: {
      name: '第一个'
    }
  },
  {
    path: '/XRlease/fome',
    name: '表单',
    component: XRleaseFome,
    meta: {
      name: '表单'
    }
  },
  {
    path: '/XRlease/scan',
    name: '扫描',
    component: XRleaseScan,
    meta: {
      name: '扫描'
    }
  }
]
const signUproutes = [

  {
    path: '/signUp/login',
    name: 'signUplogin',
    component: signUpLogin,
    meta: {
      name: '登录'
    }
  },
  {
    path: '/signUp/addshool',
    name: 'signUpaddshool',
    component: signUpAddshool,
    meta: {
      name: '选择学校'
    }
  },
  {
    path: '/signUp/home',
    name: 'signUphome',
    component: signUpHome,
    meta: {
      name: '首页'
    }
  },
  {
    path: '/signUp/application',
    name: 'signUpapplication',
    component: signUpApplication,
    meta: {
      name: '报名页面'
    }
  },
  {
    path: '/signUp/fromdialog',
    name: 'signUpfromdialog',
    component: signUpFromdialog,
    meta: {
      name: ''
    }
  },
  {
    path: '/signUp/addform',
    name: 'signUpaddform',
    component: signUpAddform,
    meta: {
      name: '选择场次'
    }
  },
  {
    path: '/signUp/upform',
    name: 'signUpupform',
    component: signUpUpform,
    meta: {
      name: ''
    }
  },
  {
    path: '/signUp/XRbutterfly',
    name: '蝴蝶展览',
    component: XRbutterfly,
    meta: {
      name: '蝴蝶展览'
    }
  },
  {
    path: '/signUp/activePage',
    name: 'activePage',
    component: activePage,
    meta: {
      name: '活动名称列表'
    }
  },
  {
    path: '/signUp/addSignUpUser',
    name: 'addSignUpUser',
    component: addSignUpUser,
    meta: {
      name: '报名页面'
    }
  },
]
//默认重定向的XR租凭home页

//ai合成视频项目
const aiVideo =[
  {
    path: '/aiVideo/home',
    name: '',
    component: aiVideoHome,
    meta: {
      name: '选择模板'
    }
  },
  {
    path: '/aiVideo/Recording',
    name: '',
    component: aiVideoRecording,
    meta: {
      name: 'Ai合成视频'
    }
  },
  {
    path: '/aiVideo/PCWindow',
    name: '',
    component: PCWindow,
    meta: {
      name: '视频播放'
    }
  },
  {
    path: '/aiVideo/getCode',
    name: '',
    component: getCode,
    meta: {
      name: '获取wxCode'
    }
  },
  {
    path: '/aiVideo/aiVideobridge',
    name: '',
    component: aiVideobridge,
    meta: { 
      name: '登录'
    }
  },
];

const ProjectBridgePage = [
  {
    path: '/ProjectBridgePage/ProjectBridge',
    name: '',
    component: ProjectBridge,
    meta: {
      name: '登录'
    }
  },
  {
    path: '/ProjectBridgePage/ProjectGetCode',
    name: '',
    component: ProjectGetCode,
    meta: {
      name: '授权'
    }
  },
  {
    path: '/ProjectBridgePage/ProjectHome',
    name: '',
    component: ProjectHome,
    meta: {
      name: '项目授权'
    }
  },
  {
    path: '/ProjectBridgePage/ProjectHome1',
    name: '',
    component: ProjectHome1,
    meta: {
      name: '项目授权'
    }
  },
  {
    path: '/ProjectBridgePage/ProjectpcWindos',
    name: '',
    component: ProjectpcWindos,
    meta: {
      name: 'PC登录'
    }
  },
  
]

const redirect = {
  path: '/', // 匹配任何路径
  redirect: '/XRlease/home' // 重定向到主页
}
const routes = [redirect, ...XRleaseroutes, ...signUproutes, ...aiVideo, ...ProjectBridgePage]

const router = new VueRouter({
  routes,
  mode: 'hash'
})
export default router
