/*
* @Author: 大剑师兰特（xiaozhuanlan），还是大剑师兰特（CSDN）
* @此源代码版权归大剑师兰特所有，可供学习或商业项目中借鉴，未经授权，不得重复地发表到博客、论坛，问答，git等公共空间或网站中。
* @Email: 2909222303@qq.com
* @weixin: gis-dajianshi
* @First published in CSDN
* @First published time: 2023-11-14
*/
<template>
  <div
    class="container"
    :style="item.animationState == 'running' ? 'width: 3rem' : 'width: 0'"
  >
    <div class="dajianshi">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { animationState: "paused" };
  },
  props:['item'],
  mounted() {},
  methods: {
    toggleAnimation() {
      // 切换动画状态
      this.animationState =
        this.animationState === "running" ? "paused" : "running";
      // 如果需要，也可以在这里直接操作DOM元素的动画状态，但Vue推荐使用数据绑定
    },
  },
  computed: {},
  watch: {},
  components: {},
};
</script>

<style scoped>
.container {
  overflow: hidden;
  transition: all 0.5s;
}

.top {
  margin: 0 auto 0px;
  /* padding: 10px 0; */
  background: thistle;
  /* color: #fff; */
}

.dajianshi {
  /* margin:1rem auto 0; */
  width: 3rem;
  height: 1rem;
  display: flex;
}

.dajianshi span {
  width: 0.1rem;
  border-radius: 0.32rem;
  margin-right: 0.15rem;
}

.dajianshi span:nth-child(1) {
  animation: bar1 1.5s 0.2s infinite linear;
}

.dajianshi span:nth-child(2) {
  animation: bar2 1.5s 0.4s infinite linear;
}

.dajianshi span:nth-child(3) {
  animation: bar3 1.5s 0.6s infinite linear;
}

.dajianshi span:nth-child(4) {
  animation: bar4 1.5s 0.8s infinite linear;
}

.dajianshi span:nth-child(5) {
  animation: bar5 1.5s 1s infinite linear;
}

.dajianshi span:nth-child(6) {
  animation: bar6 1.5s 1.2s infinite linear;
}

.dajianshi span:nth-child(7) {
  animation: bar7 1.5s 1.4s infinite linear;
}

.dajianshi span:nth-child(8) {
  animation: bar8 1.5s 1.6s infinite linear;
}

.dajianshi span:nth-child(9) {
  animation: bar9 1.5s 1.8s infinite linear;
}

@keyframes bar1 {
  0% {
    background: #f677b0;
    margin-top: 25%;
    height: 10%;
  }

  50% {
    background: #f677b0;
    height: 100%;
    margin-top: 0%;
  }

  100% {
    background: #f677b0;
    height: 10%;
    margin-top: 25%;
  }
}

@keyframes bar2 {
  0% {
    background: #df7ff2;
    margin-top: 25%;
    height: 10%;
  }

  50% {
    background: #df7ff2;
    height: 100%;
    margin-top: 0%;
  }

  100% {
    background: #df7ff2;
    height: 10%;
    margin-top: 25%;
  }
}

@keyframes bar3 {
  0% {
    background: #8c7ff2;
    margin-top: 25%;
    height: 10%;
  }

  50% {
    background: #8c7ff2;
    height: 100%;
    margin-top: 0%;
  }

  100% {
    background: #8c7ff2;
    height: 10%;
    margin-top: 25%;
  }
}

@keyframes bar4 {
  0% {
    background: #7fd0f2;
    margin-top: 25%;
    height: 10%;
  }

  50% {
    background: #7fd0f2;
    height: 100%;
    margin-top: 0%;
  }

  100% {
    background: #7fd0f2;
    height: 10%;
    margin-top: 25%;
  }
}

@keyframes bar5 {
  0% {
    background: #7ff2d3;
    margin-top: 25%;
    height: 10%;
  }

  50% {
    background: #7ff2d3;
    height: 100%;
    margin-top: 0%;
  }

  100% {
    background: #7ff2d3;
    height: 10%;
    margin-top: 25%;
  }
}
</style>

