
const XRleaseModule = {  
    namespaced: true, // 默认为 false，设置为 true 表示模块内的状态、mutations、actions 和 getters 都是局部的  
    state: {  
      name: 'Guest',  
      urlSendData:{},
      wxPayOrderList:[]
    },  
    mutations: {  
    //   SET_NAME(state, name) {  
    //     state.name = name;  
      //   },  
      SET_URLDATA(state, data) {
        state.urlSendData = data;
      },
        SET_WXPL(state, data) {
          state.wxPayOrderList = data;
      }
    },  
  actions: {
    setUrlData({ commit }, urlD) {
      commit("SET_URLDATA", urlD);
    },
    setWxPL({ commit }, urlD) {
      commit("SET_WXPL", urlD);
    },
    //   login({ commit }, userInfo) {  
    //     // 模拟异步登录  
    //     setTimeout(() => {  
    //       commit('SET_NAME', userInfo.name);  
    //       commit('SET_TOKEN', userInfo.token);  
    //     }, 1000);  
    //   }  
    },  
    getters: {  
      // userOpenid: state => state.urlSendData
    }
  };  
  export default XRleaseModule;