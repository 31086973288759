<template>
  <div>
    <dplayer
      style="height: 100vh; width: 100vw"
      :options="playerOptions"
      ref="dplayer"
      @ended="videoended"
    ></dplayer>
  </div>
</template>

<script>
import DPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";

export default {
  components: {
    DPlayer,
  },
  props: {
    videoList: {
      type: [],
      required: true,
    },
  },
  data() {
    return {
      playerOptions: {
        lang: "zh-cn",
        preload: "auto",
        video: {
          // url: 'http://h5.3vppt.com/videoFile/butterflyVideo1.mp4',
          // type: 'auto',
          //   poster: this.poster || '', // 如果没有提供海报，则不显示
        },
        autoplay: true,
        // loop:true,
        preload: "auto",
        controls: true,
        // ...this.options,
      },
      dplayer: null,
      nextUrl: "",
    };
  },
  // http://h5.3vppt.com/videoFile/butterflyVideo2.mp4
  mounted() {
    // 在组件挂载后，确保DPlayer实例可用
    this.$nextTick(() => {
      console.log();
      if (this.$refs.dplayer) {
        console.log("DPlayer已初始化");
        this.dplayer = this.$refs.dplayer.dp;
        this.switchVideo(this.videoList[0][1]);
      }
    });
  },
  methods: {
    switchVideo1(url) {
      this.dplayer.switchVideo({
        url, //:'http://h5.3vppt.com/videoFile/butterflyVideo2.mp4',
      });
      setTimeout(() => {
        this.dplayer.play();
      }, 200);
    },
    switchVideo(url) {
      this.dplayer.switchVideo({
        url, //:'http://h5.3vppt.com/videoFile/butterflyVideo2.mp4',
      });
      setTimeout(() => {
        this.dplayer.play();
      }, 200);
    },
    videoended() {
      console.log("播放结束");
      setTimeout(() => {
        //删除
        this.$emit("delectList");
      }, 200);
      setTimeout(() => {
        this.switchVideo(this.videoList[0][1]);
      }, 400);
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.dplayer-play-icon) {
  display: block !important;
}
:deep(.dplayer-play-icon) {
  // display: block !important;
}
:deep(.dplayer-icons-left) {
  display: flex;
  // align-items: center;
}
</style>