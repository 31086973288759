<template>
  <div class="pages">
    <div class="contBoxQr">
      请扫码登录
      <qrcode v-if="WxCodeData.resmsg" :qrUrl="WxCodeData.resmsg"></qrcode>
    </div>
  </div>
</template>

<script>
import { GetWxCode, GetWaitToken } from "../api/index";
import qrcode from "./qrcode.vue";
let times = null,
  is = 0;
export default {
  components: { qrcode },
  data() {
    return {
      WxCodeData: {
        code: 0,
        resmsg: "",
      },
    };
  },
  mounted() {
    this.GetWxCodeFun();
  },
  methods: {
    startTime() {
      times = setInterval(() => {
        if (is > 24) {
          is = 0;
          clearInterval(times);
          return;
        }
        is++;
        console.log("开始计时");
        GetWaitToken({ state: this.WxCodeData.code }).then((res) => {
          console.log(res);
          if (res?.rescode == 200) {
            clearInterval(times);
            localStorage.setItem("ProjectAuthorizationToken", res?.resmsg);
            this.$router.push({
              path: "/ProjectBridgePage/ProjectHome1",
            });
          }
          // loshToken(res.resmsg);
        });
      }, 5000);
    },
    GetWxCodeFun() {
      GetWxCode()
        .then((res) => {
          console.log(res);
          this.WxCodeData = res;
          this.startTime();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.pages {
  width: 100vw;
  height: 100vh;
  background: url("@/assets/page/login-bg.jpg") no-repeat center right;
  display: flex;
  align-items: center;
  justify-content: center;
  .contBoxQr {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 26px;
    border: 1px solid #fff;
  }
}
</style>