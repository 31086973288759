<template>
  <div>
    {{ time }}
  </div>
</template>

<script>
let times = null;
export default {
  props: ["times"],
  data() {
    return {
      time: "",
    };
  },
  mounted() {
    let that = this;
    times = setInterval(() => {
      that.time = that.getTimeDifference(that.times);
    }, 1000);
  },
  methods: {
    getTimeDifference(timestamp) {
      let now = Date.now();
      let difference = now - timestamp * 1000; // 获取毫秒差值
      let seconds = Math.floor(difference / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);

      // 重新计算余下的秒和分钟
      seconds %= 60;
      minutes %= 60;

      // 格式化输出，不足两位的前面补0
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    },
  },

  beforeDestroy() {
    clearInterval(times);
    // console.log("组件即将被销毁");
    // 取消定时器、解除监听器等
  },
  destroyed() {
    // console.log("组件已被销毁");
    // 清理外部API订阅、释放资源等
  },
};
</script>

<style lang="scss" scoped>
</style>