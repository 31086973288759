<template>
  <div style="width: 100vw; height: 100vh;display: flex; justify-content: center; align-items: center">
    <audio ref="audioPlayer" controls>
      <source :src="audioUrl" type="audio/mpeg" />
    </audio>
    <!-- <button @click="playAudio">播放</button>
    <button @click="pauseAudio">停止</button> -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "voicedla",
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
      audioUrl: "",
    };
  },
  mounted() {
    let dUrl = this.$route.query.dUrl;
    let decodedUri = decodeURIComponent(dUrl);
    console.log(decodedUri,'0000000000000');
    this.audioUrl = decodedUri;
    this.$refs.audioPlayer.play();
    //  decodedUri ='https://oss.3vppt.com/ppt_media/FlVtzcXUUBDVgdrE-X7QWzVEu00z&output.wav'
    this.downloadVideo(decodedUri);
  },
  methods: {
    playAudio() {
      this.$refs.audioPlayer.play();
    },
    pauseAudio() {
      this.$refs.audioPlayer.pause();
    },
    getFileType(url) {
      // 使用正则表达式匹配最后一个点后的部分
      const matches = url.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
      if (matches) {
        return matches[1];
      }
      return null;
    },
    async downloadVideo(urls) {
      let that = this,
        types = this.getFileType(urls);
      try {
        const response = await axios.get(
          urls,
          //   "http://h5.3vppt.com/videoFile/butterflyVideo1.mp4",
          {
            responseType: "blob",
            onDownloadProgress: function (progress) {
              //   self.downLoadInfo = true;
              //   that.self.loaded = that.setfinishSize(progress.loaded);
              //   that.self.total = that.setfinishSize(progress.total);
            },
          }
        );

        this.isShow = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "video." + types);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.isShow = false;
        console.error("视频下载失败", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>