import { getSignPackage } from './homeApi/index'
import wx from "weixin-js-sdk";
export async function wechatSharefund(columnInfo) {

    const params = {
        url: location.href.split('#')[0],
    };
    const data = await getSignPackage(params) // 返回的微信信息

    console.log(data)

    // if (data.code === 0) {

        // 注册

        wx.config({

            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。

            appId: data.appId, // 必填，公众号的唯一标识

            timestamp: data.timestamp, // 必填，生成签名的时间戳

            nonceStr: data.nonceStr, // 必填，生成签名的随机串

            signature: data.signature, // 必填，签名

            jsApiList: [

                'updateAppMessageShareData',

                'updateTimelineShareData',

                'startRecord',

                'stopRecord',

                'playVoice',

                'onVoiceRecordEnd',

                'uploadVoice',

                'onVoicePlayEnd',

                'downloadVoice'

            ] // 必填，需要使用的JS接口列表

        })

        // 是否成功

        // wx.ready(function (res) {

            // console.log([res, 90])

            // wx.updateAppMessageShareData({

            //     title: '我是自定义首页！！！！！好友' + store.getters.doctorId,

            //     desc: '自定义描述', // 分享描述

            //     link: 'https://doctor.taiori.com/doctor/hospitalIndex?userParam=' + store.getters.doctorId,

            //     imgUrl: '', // 分享图标

            //     success: function () {

            //         // 设置成功

            //     }

            // })

            // wx.updateTimelineShareData({

            //     title: "我是自定义首页！！圈" + store.getters.doctorId,

            //     link: 'https://doctor.taiori.com/doctor/hospitalIndex?userParam=' + store.getters.doctorId,

            //     imgUrl: '',

            //     success: function () {

            //     }

            // });



        // });

        // 是否支持指定JS接口

        // wx.checkJsApi({

        //     jsApiList: ['startRecord'], // 需要检测的JS接口列表，所有JS接口列表见附录2,

        //     success: function (res) {

        //         console.log([res, '114'])

        //         store.commit('jsApiList', res)

        //         // 以键值对的形式返回，可用的api值true，不可用为false

        //         // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}

        //     }

        // })

        // wx.error(function (res) {

        //     console.log(res)

        // })

    // }
    console.log('获取微信配置成功 wechatSharefund-------')
}