<template>
  <div>
    <van-nav-bar title="模板下载" placeholder :fixed="true" />
    <div class="videoDl">
      <div class="videobox" id="videoTemp"></div>
      <van-button class="db" type="primary" block @click="dbFun">
        下载模板
      </van-button>
      <div class="Text1" v-if="isShow">
        视频获取中，请不要关闭{{ self.total }}/{{ self.loaded }}
      </div>
    </div>
    <van-popup v-model="showPopup">
      <div class="divpage" @click="showPopup = false">
      <svg
        t="1717643174026"
        class="icon SvgImg"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="5900"
        width="100"
        height="100"
      >
        <path
          d="M224 832a32 32 0 0 1-22.63-54.63L722.75 256H608a32 32 0 0 1 0-64h192a31.83 31.83 0 0 1 22.35 9.1l0.43 0.43A31.83 31.83 0 0 1 832 224v192a32 32 0 0 1-64 0V301.25L246.63 822.63A31.9 31.9 0 0 1 224 832z"
          fill="#ffffff"
          p-id="5901"
        ></path>
      </svg>
      <div class="textdl">
        <div>请点击右上角</div>
        <div>通过浏览器下载</div>
      </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import DPlayer from "dplayer";
import axios from "axios";

export default {
  data() {
    return {
      isShow: false,
      showPopup: false,
      self: {
        total: 0,
        loaded: 0,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      const dp1 = new DPlayer({
        container: document.getElementById("videoTemp"),
        video: {
          url: "http://h5.3vppt.com/videoFile/videoTemp.mp4",
          pic: require("../../assets/signUp/video/videoBg1.jpg"),
        },
      });
    });
    
  },
  methods: {
    dbFun() {
      let that = this;
      if (this.is_weixn()) {
        this.showPopup = true;
      } else {
        this.isShow = true;
        this.downloadVideo("http://h5.3vppt.com/videoFile/butterflyVideo1.mp4");
      }
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },

    setfinishSize(size) {
      let finishSize = "";
      if (size > 1048576) {
        finishSize = parseFloat(size / 1048576).toFixed(1) + "M";
      } else if (size > 1024) {
        finishSize = parseInt(size / 1024) + "K";
      } else {
        finishSize = size + "B";
      }
      return finishSize;
    },
    async downloadVideo(urls) {
      let that = this;
      try {
        const response = await axios.get(
          urls,
          //   "http://h5.3vppt.com/videoFile/butterflyVideo1.mp4",
          {
            responseType: "blob",
            onDownloadProgress: function (progress) {
              //   self.downLoadInfo = true;
              that.self.loaded = that.setfinishSize(progress.loaded);
              that.self.total = that.setfinishSize(progress.total);
            },
          }
        );

        this.isShow = false;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "video.mp4");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.isShow = false;
        console.error("视频下载失败", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.van-popup){
  background: transparent !important;
}

  .divpage{
    width: 100vw;
    height: 100vh;
    // background: #fff;
    position: relative;
    .textdl{
      position: absolute;
      right:  0.5rem;
      top: 2.2rem;
      font-size: .32rem;
      color: #fff;
    }

    .SvgImg{
      position: absolute;
      right:  0;
      top: 0.2rem;
    }
  }
.videoDl {
  width: 100%;
  padding: 0.2rem;
  box-sizing: border-box;
  .Text1 {
    font-size: 0.24rem;
    color: #9c9999;
    margin-top: 0.2rem;
  }
  .db {
    margin-top: 0.2rem;
  }
  .videobox {
    width: 100%;
    // height: 4rem;
  }
}
</style>