   <template>
  <div style="" class="pageBox">
      <!-- :logo-src="logoPath" -->
      <h4>长按图片保存二维码</h4>
    <vue-qr
      :text="qrUrl"
      :size="500"
      :width="300"
      :height="300"
      :logo-scale="2"
      logo-background="#ffffff"
      qr-background="#ffffff"
    ></vue-qr>
  </div>
</template>

   <script>
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
  },
  props:['qrUrl'],
  data() {
    return {
      qrData: "https://example.com", // 二维码内容
    //   logoPath: require("@/assets/logo.png"), // logo 图片路径
    };
  },
};
</script>
<style  lang="less" scoped> 
.pageBox{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
    img{
        width:3.4rem;height:3.4rem;margin:0 0
    }
</style>