<template>
  <div>
    <!--轮播图-->
    <div>
      <van-swipe :autoplay="3000" :loop="true">
        <van-swipe-item>
          <el-image
            style="width: 100%; height: 300px"
            :src="require('@/assets/XRlease/132.jpg')"
            :preview-src-list="srcList"
          >
          </el-image>
        </van-swipe-item>
        <van-swipe-item>
          <el-image
            style="width: 100%; height: 300px"
            :src="require('@/assets/XRlease/132.jpg')"
            :preview-src-list="srcList"
          >
          </el-image>
        </van-swipe-item>
        <van-swipe-item>
          <el-image
            style="width: 100%; height: 300px"
            :src="require('@/assets/XRlease/132.jpg')"
            :preview-src-list="srcList"
          >
          </el-image>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!--文字部分-->
    <div class="van-cell_calue">
      <van-cell>
        <van-button disabled color="#e9333f" size="mini" plain
          >红包以抵5元</van-button
        >
        <span style="color: red">577￥</span>
      </van-cell>
      <van-cell>
        <div>
          <div style="font-weight: bold">
            欧莱雅男士洗面奶水能润泽双效洁面乳补水保湿清爽深层清洁正品
          </div>
          <div>
            <van-button disabled color="#9a6d3b" size="mini" plain
              >24小时内100+人已买 101人评价"价格经济实惠"</van-button
            >
          </div>
        </div>
      </van-cell>
      <van-cell>一对一辅导，让家长放心</van-cell>
      <van-cell>名师课堂</van-cell>
      <van-cell>有爱心的老师让你的孩子快一步</van-cell>
      <van-cell>有爱心的老师让你的孩子快一步</van-cell>
    </div>
    <!--底部导航-->
    <div>
      <van-goods-action :sticky="true">
        <van-goods-action-button type="danger" text="立即购买" @click="eject">
          <!--表单-->
        </van-goods-action-button>
      </van-goods-action>
    </div>
  </div>
</template>  
  
<script>
import { Swipe, SwipeItem } from "vant";

export default {
  data() {
    return {
      cartCount: 0,
      srcList: [require("@/assets/XRlease/132.jpg")],
      form: {
        name: "",
        phone: "",
        address: "",
        remark: "",
      },
      num: 0,
    };
  },
  methods: {
    //添加商品进购物车
    Addproduct() {
      this.cartCount = this.cartCount + 1;
      console.log(this.cartCount);
    },
    //弹出表单
    eject() {
      this.$router.push("/XRlease/fome");
    },
  },
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
};
</script>  
  
<style>
.el-image-viewer__img {
  /* 你的样式 */
  width: 100%;
  height: 400px;
}
/* .van-cell_value{
  display:flex; 
  justify-content: space-between;
} */
</style>