<template>
  <div class="pagesB">
    <div class="divTop">
      <div class="ss">
        <el-input
          style="margin-right: 20px"
          v-model="search"
          placeholder="请输入内容"
        ></el-input>
        <el-button type="info" @click="onRefresh">搜索</el-button>
      </div>
      <el-button type="primary" @click="showAdd">新加</el-button>
    </div>
    <el-table :data="listData" style="width: 100%">
      <el-table-column prop="name" label="项目名称"> </el-table-column>
      <el-table-column prop="serialid" label="序列号"> </el-table-column>
      <el-table-column prop="cipher" label="密钥"> 
        <template slot-scope="scope">
          {{!scope.row.showIf ? "*********" : scope.row.cipher}}
        </template>
      </el-table-column>
      <el-table-column prop="ciphertext" label="密文"> </el-table-column>
      <el-table-column prop="over" label="有效期"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="showXX(scope)"
            size="small"
            >{{scope.row.showIf ? "关闭" : '查看'}}</el-button
          >
          <el-button
            type="text"
            @click="copyText(scope.row.ciphertext)"
            size="small"
            >复制</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="新增" :visible.sync="show" width="30%">
      <!-- :before-close="handleClose" -->
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item prop="name" label="项目名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item prop="serialid" label="序列号">
          <el-input v-model="form.serialid"></el-input>
        </el-form-item>
        <el-form-item prop="cipher" label="密钥">
          <el-input v-model="form.cipher"></el-input>
        </el-form-item>
        <el-form-item label="选择时间">
          <el-date-picker
            v-model="currentDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">立即创建</el-button>
          <el-button @click="show = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div style="display: flex;width:100%;justify-content: end;">
      
    <el-pagination
      layout="prev, pager, next"
      :total="allListData.count"
      @current-change="currentChange"
    >
    </el-pagination>
    </div>
  </div>
</template>

<script>
import { LicensePost, LicenseGetList } from "../api/index";
export default {
  data() {
    return {
      showTime: false,
      show: false,
      showPopup: false,
      isLoading: false,
      form: {
        serialid: "",
        name: "",
        cipher: "",
        over: "",
      },
      listData: [],
      minDate: new Date(),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      search: "",
      rules: {
        name: [{ required: true, message: "请输入项目名称", trigger: "blur" }],
        serialid: [
          { required: true, message: "请输入序列号", trigger: "blur" },
        ],
        cipher: [{ required: true, message: "请输入密钥", trigger: "blur" }],
      },
      allListData: { count: 0 },
      page_size: 10,
      page: 1,
    };
  },
  mounted() {
    this.LicenseGetListFun();
  },
  methods: {
    showXX(row){
      console.log()
      row.row.showIf = !row.row.showIf
      this.$set(this.listData,row.$index,row.row)
    },
    currentChange(index) {
      this.page = index;
      this.LicenseGetListFun();
    },
    async copyText(text) {
      this.$copyText(text).then(
        () => {
          this.$toast.success("文本已复制到剪贴板");
        },
        () => {
          this.$toast.fail("无法复制文本: ", err);
        }
      );
    },
    onRefresh() {
      this.page_size= 10
      this.page= 1
      this.LicenseGetListFun();
      this.search = "";
    },
    onSearch() {
      console.log(this.search);
      this.LicenseGetListFun();
    },
    LicenseGetListFun() {
      this.isLoading = true;
      LicenseGetList({
        search: this.search,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        let newData = res.resmsg;
        newData.forEach((element) => {
          element.showIf = false;
        });
        this.listData = newData;
        this.allListData = res;
        this.isLoading = false;
      });
    },
    showAdd() {
      this.page_size= 10
      this.page= 1
      this.form = {
        serialid: "",
        name: "",
        cipher: "",
        over: "",
      };
      this.show = true;
    },
    confirmFUn(e) {
      console.log("times", this.formatDate(e));
      this.showTime = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    onSubmit() {
      this.form.over = this.formatDate(this.currentDate);
      console.log(this.form);

      this.$refs["form"].validate((valid) => {
        if (valid) {
          LicensePost(this.form)
            .then((res) => {
              this.onRefresh();
              let text = res.rescode == 200 ? "添加成功" : "系统错误";
              this.$toast.success(text);
              this.show = false;
            })
            .catch((err) => {
              let text =
                err.rescode == 403 ? "您没有执行该操作的权限" : "系统错误";
              this.$toast.fail(text);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    gotoPage() {},
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagesB {
  padding: 20px;
  font-size: 16px;
  .divTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ss {
      display: flex;
      align-items: center;
    }
  }
}
</style>