<template>
  <div>
    <van-tabs v-model="active" :sticky="true" @change="tabChange">
      <van-tab title="进行中"  key="tab1"><ongoing :key="tab1Key" @chanineChange="chanineChange" ref="ongoing"></ongoing></van-tab>
      <van-tab title="已完成"  key="tab2"><done :key="tab2Key" ref="done"></done></van-tab>
    </van-tabs>
    <div><homebottomVue :index="1"></homebottomVue></div>
  </div>
</template> 
<script>
import mybottom from "@/view/XRlease/myis/mybottom.vue";
import myimg from "@/view/XRlease/myis/img.vue";
import ongoing from "@/view/XRlease/myis/ongoing.vue";
// import homebottom from '@/view/XRlease/myhome/components/homebottom.vue'
import homebottomVue from "../myhome/components/homebottom.vue";
import done from "@/view/XRlease/myis/done.vue";
export default {
  components: {
    mybottom,
    // homebottom,
    myimg,
    homebottomVue,
    ongoing,
    done,
  },
  data() {
    return {
      active:  0,
      tab1Key: 1,  
      tab2Key: 1,  
    };
  },
  methods: {
    chanineChange(){
      this.active = 1
    },
    tabChange(newVal){
      if (newVal == 0) {  
        this.tab1Key += 1; // 切换时增加 key 值  
        // this.$refs.ongoing.pageInit()
      } else if (newVal == 1) {  
        this.tab2Key += 1; // 切换时增加 key 值  
        // this.$refs.done.pageInit()
      }  
    }
    // exitt(){
    //     this.$router.push('/XRlease/home')
    //     // this.$router.push('/XRlease/home')
    // }
    //调起订单列表接口
  },
};
</script>