<template>
  <div class="login">
    <div>
      <h3 class="title"></h3>
    </div>
    <div class="centered-form">
      <div>
        <van-form>
          <div
            class="shool"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 30vh;
              margin-top: 40px;
            "
          >
            <el-form
              ref="form"
              :model="form"
              label-width="80px"
              style="justify-content: space-between"
            >
              <el-form-item label="学校集团">
                <el-select
                  v-model="form.grade"
                  placeholder="请选择学校集团"
                  @change="onChangeSelect"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学校">
                <el-select v-model="form.class" placeholder="请选择学校">
                  <!-- <el-option label="龙湖小学" value="龙湖小学"></el-option> -->
                  <el-option
                    v-for="item in schoolList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <van-button type="primary" block @click="addshoolGroup">确定选择</van-button>
          </div>
          <!--添加学生集团-->
          <div style="margin-top: 5px; width: 100%" class="upshool">
            <div style="float: left" class="addonlogins">
              <van-button
                type="warning"
                block
                @click="Newlyaddedschoolgroup"
                style="width: 301.4px"
                >新增学校集团</van-button
              >
              <el-dialog
                title="新增学校集团"
                :visible.sync="Newlyaddedschoolgroupform"
                @close="closeAllDate()"
              >
                <el-form ref="form" :model="formAdd" label-width="80px">
                  <el-form-item label="学校集团">
                    <el-input v-model="formAdd.name"></el-input>
                  </el-form-item>

                  <el-form-item>
                    <div style="float: right">
                      <el-button type="primary" @click="NewlyaddedschoolgroupSubmit"
                        >保存</el-button
                      >

                      <el-button @click="Newlyaddedschoolgroupcancel">取消</el-button>
                    </div>
                  </el-form-item>
                </el-form>
              </el-dialog>
            </div>
          </div>

          <!--新增学校-->
          <div style="margin-top: 60px; width: 100%" class="upshools">
            <div style="float: left" class="addonlogins">
              <van-button
                type="warning"
                block
                @click="Newlyaddedschools"
                style="width: 301.4px"
                >新增学校</van-button
              >
              <el-dialog
                title="新增学校"
                @close="closeAllDate()"
                :visible.sync="Newlyaddedschoolsform"
              >
                <el-form ref="form" :model="formAdd" label-width="80px">
                  <el-form-item label="学校集团">
                    <el-select v-model="formAdd.grade" placeholder="请选择学校集团">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="学校">
                    <el-input v-model="formAdd.name"></el-input>
                  </el-form-item>

                  <el-form-item>
                    <div style="float: right">
                      <el-button type="primary" @click="NewlyaddedschoolsSubmit"
                        >保存</el-button
                      >

                      <el-button @click="Newlyaddedschoolscancel">取消</el-button>
                    </div>
                  </el-form-item>
                </el-form>
              </el-dialog>
            </div>
          </div>
          <!--新增学校-->

          <!--删除学校或者集团-->
          <div class="delete">
            <el-button
              type="danger"
              @click="showDelete"
              style="width: 100%; margin-top: 5px"
              >删除</el-button
            >
            <el-dialog
              @close="closeAllDate()"
              title="删除学校集团或学校"
              :visible.sync="deleteSchool"
            >
              <el-form
                ref="form"
                :model="formde"
                label-width="80px"
                style="justify-content: space-between"
              >
                <el-form-item label="学校集团">
                  <el-select
                    v-model="formde.grade"
                    placeholder="请选择学校集团"
                    @change="onChangeSelect"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="学校">
                  <el-select v-model="formde.class" placeholder="请选择学校">
                    <el-option
                      v-for="item in schoolList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="danger" @click="deleteonSubmits">保存</el-button>
                </el-form-item>
              </el-form>
            </el-dialog>
          </div>

          <!--修改学校和集团-->
          <div class="delete">
            <el-button
              type="danger"
              @click="showUpdate"
              style="width: 100%; margin-top: 5px"
              >修改</el-button
            >
            <el-dialog
              @close="closeAllDate()"
              title="修改学校集团或学校"
              :visible.sync="UpdateSchool"
            >
              <el-form
                ref="form"
                :model="formUp"
                label-width="80px"
                style="justify-content: space-between"
              >
                <el-form-item label="选择集团">
                  <el-select
                    v-model="formUp.grade"
                    placeholder="请选择学校集团"
                    @change="onChangeSelect"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="修改集团">
                  <el-input v-model="formUp.updateGrade"></el-input>
                </el-form-item>
                <el-form-item label="选择学校">
                  <el-select v-model="formUp.class" placeholder="请选择学校">
                    <!-- <el-option label="龙湖小学" value="龙湖小学"></el-option> -->
                    <el-option
                      v-for="item in schoolList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="修改学校">
                  <el-input v-model="formUp.updateClass"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="danger" @click="updateonSubmits">保存</el-button>
                </el-form-item>
              </el-form>
            </el-dialog>
          </div>
        </van-form>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getschoolGroupList,
  getschoolGroupDel,
  getschoolGroupExit,
  getschoolGroupAdd,
  SetYlWxSchool,
} from "../../api/homeApi/index";
// import { options } from "less";
export default {
  data() {
    return {
      shools: "",
      shool: "",
      form: {
        grade: "",
        class: "",
      },
      formAdd: {
        name: "",
        pid: 0,
        user_id: 0,
        id: 0,
        grade: "",
      },
      formde: {
        grade: "",
        class: "",
        id: 0,
      },
      formUp: {
        grade: "",
        class: "",
        updateGrade: "",
        updateClass: "",
      },
      Newlyaddedschoolsform: false,
      Newlyaddedschoolgroupform: false,
      UpdateSchool: false,
      deleteSchool: false,
      options: [], // 集团
      schoolList: [], // 学校
      sessionOp: "",
      sessionCl: "",
      getRouter: 0,
      getGroup_id: 0,
    };
  },
  methods: {
    Newlyaddedschoolgroup() {
      console.log(sessionStorage.getItem("id"));
      // console.log(this.formAdd.pid);
      this.Newlyaddedschoolgroupform = true;
    },
    //保存学校集团和学校
    NewlyaddedschoolgroupSubmit() {
      const params = {
        name: this.formAdd.name,
        pid: 0,
        user_id: sessionStorage.getItem("id"),
      };
      SetYlWxSchool(params).then((res) => {
        console.log(res);
        sessionStorage.setItem("pid", params.pid);
        this.getschoolGroupLists();
        this.formAdd.name = "";
        this.form.class = "";
        this.formde.class = "";
        this.form.grade = "";
        this.Newlyaddedschoolgroupform = false;
      });
      // getschoolGroupAdd(params).then((res) => {
      //   console.log(res);
      //   sessionStorage.setItem("pid", params.pid);
      //   this.getschoolGroupLists();
      //   this.formAdd.name = "";
      //   this.form.class = "";
      //   this.formde.class = "";
      //   this.form.grade = "";
      //   this.Newlyaddedschoolgroupform = false;
      // });
      // console.log(this.formAdd.name);
      // this.Newlyaddedschoolgroupform = false;
    },
    //取消学校集团和学校
    Newlyaddedschoolgroupcancel() {
      this.Newlyaddedschoolgroupform = false;
    },

    //新增学校
    Newlyaddedschools() {
      this.Newlyaddedschoolsform = true;
    },
    //新增保存按钮
    NewlyaddedschoolsSubmit() {
      const params = {
        name: this.formAdd.name,
        pid: this.formAdd.grade,
        user_id: sessionStorage.getItem("id"),
      };
      SetYlWxSchool(params).then((res) => {
        this.form.class = "";
        this.formde.class = "";
        this.form.grade = "";
        this.formAdd.grade = "";
        this.formAdd.name = "";
        this.Newlyaddedschoolsform = false;
      });
      // getschoolGroupAdd(params).then((res) => {
      //   this.form.class = "";
      //   this.formde.class = "";
      //   this.form.grade = "";
      //   this.formAdd.grade = "";
      //   this.formAdd.name = "";
      //   this.Newlyaddedschoolsform = false;
      // });
    },
    //新增取消按钮
    Newlyaddedschoolscancel() {
      this.Newlyaddedschoolsform = false;
    },

    addshoolGroup() {
      //添加学校集团和学校接口
      if (this.form.class && this.form.grade) {
        sessionStorage.setItem("school_class", this.form.class);

        for (const item in this.options) {
          if (this.options[item].id == this.form.grade) {
            sessionStorage.setItem("grade", this.options[item].name);
            console.log(this.options[item].name);
          }
        }
        for (const item in this.schoolList) {
          if (this.schoolList[item].id == this.form.class) {
            sessionStorage.setItem("class", this.schoolList[item].name);
            console.log(this.schoolList[item].name);
          }
        }
        //找到集团
        for (const item in this.options) {
          if (this.options[item].id == this.form.grade) {
            this.sessionOp = this.options[item].name;
            console.log(this.sessionOp);
          }
        }
        for (const item in this.schoolList) {
          if (this.schoolList[item].id == this.form.class) {
            this.sessionCl = this.schoolList[item].name;
            console.log(this.sessionCl);
          }
        }
        // this.$router.push("/home");
        // this.$dialog.alert({
        //   message: "选择成功", //改变弹出框的内容
        // }).then(() =>{
        this.$router.push({
          name: "signUphome",
          query: { loginid: this.getRouter, school_id: this.form.class },
        });
        // this.$router.push({
        //   name: "activePage",
        //   query: { loginid: this.getRouter, school_id: this.form.class },
        // });
        
        // })
      } else {
        this.$dialog.alert({
          message: "学校集团或者学校没有选择", //改变弹出框的内容
        });
      }
    },
    closeAllDate() {
      this.form.class = "";
      this.formde.class = "";
      this.form.grade = "";
      this.formAdd.grade = "";
      this.formAdd.name = "";
      this.formUp.updateGrade = "";
      this.formUp.updateClass = "";
      this.Newlyaddedschoolgroupform = false;
      this.Newlyaddedschoolsform = false;
      this.deleteSchool = false;
      this.UpdateSchool = false;
    },
    //显示删除学校showDelete的表单
    showDelete() {
      this.deleteSchool = true;
    },
    //学校集团和学校删除按钮
    deleteonSubmits() {
      // if(this.formde.class && this.formde.grade){
      //   const params = {
      //   id: this.formde.class,
      // };
      // getschoolGroupDel(params).then((res) => {});
      // }
      if (this.formde.grade) {
        //判断学校是否为空
        if (this.formde.class) {
          const params = {
            id: this.formde.class,
          };
          getschoolGroupDel(params).then((res) => {
            this.formde.class = "";
            this.formde.grade = "";
            this.deleteSchool = false;
            this.form.class = "";
            this.form.grade = "";
            this.getschoolGroupLists();
          });
        } else {
          const params = {
            id: this.formde.grade,
          };
          getschoolGroupDel(params).then((res) => {
            this.formde.class = "";
            this.formde.grade = "";
            this.form.class = "";
            this.form.grade = "";
            this.deleteSchool = false;
            this.getschoolGroupLists();
          });
        }
      }
    },
    //显示修改的form表单
    showUpdate() {
      this.UpdateSchool = true;
    },
    //修改保存按钮
    updateonSubmits() {
      if (this.formUp.updateClass && this.formUp.class) {
        const params = {
          id: this.formUp.class,
          name: this.formUp.updateClass,
        };
        getschoolGroupExit(params).then((res) => {
          if (res.code === 200) {
            this.$dialog.alert({
              message: "修改成功", //改变弹出框的内容
            });
            this.formde.class = "";
            this.formde.grade = "";
            this.form.class = "";
            this.form.grade = "";
            this.UpdateSchool = false;
            this.getschoolGroupLists();
          } else {
            this.$dialog.alert({
              message: "修改失败", //改变弹出框的内容
            });
          }
        });
      }
      if (this.formUp.updateGrade && this.formUp.grade) {
        const params = {
          id: this.formUp.grade,
          name: this.formUp.updateGrade,
        };
        getschoolGroupExit(params).then((res) => {
          this.formde.class = "";
          this.formde.grade = "";
          this.form.class = "";
          this.form.grade = "";
          this.UpdateSchool = false;
          this.getschoolGroupLists();
        });
      }
    },

    onChangeSelect(e) {
      this.form.class = "";
      this.formde.class = "";
      this.getSchoolList(e);
    },
    //获取集团列表接口
    getschoolGroupLists() {
      const params = {
        user_id: this.$route.query.loginid,
        group_id: 0,
      };
      getschoolGroupList(params).then((res) => {
        this.options = res.data;
        // console.log("拉拉"+JSON.stringify(res.data));
        console.log(this.options);
      });
    },
    getSchoolList(id) {
      const params = {
        user_id: this.$route.query.loginid,
        group_id: id,
      };
      getschoolGroupList(params).then((res) => {
        this.schoolList = res.data;
        // console.log("拉拉"+JSON.stringify(res.data));
        console.log(this.options);
      });
    },
  },
  mounted() {
    this.getRouter = this.$route.query.loginid;
    this.getGroup_id = this.$route.query.group_id;
    this.getschoolGroupLists();
    console.log(this.getRouter);
  },
};
</script>

<style scoped lang="less">
.centered-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  /* 或其他你需要的值，以确保容器足够高以实现垂直居中 */
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #888;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 101px;
}

.addonlogins :deep .el-dialog {
  width: 100%;
}
.shool :deep .el-form-item__label {
  text-align: left;
}
.upshool :deep .el-form-item__label {
  text-align: left;
}
.delete :deep .el-dialog {
  width: 100%;
}
.upshools :deep .el-form-item__label {
  text-align: left;
}
</style>
