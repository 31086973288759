<template>
  <div style="font-size: 0.34rem">
    <van-nav-bar title="视频下载" placeholder :fixed="true" />
    <!-- danger -->
    <div class="kslz" style="">
      <van-button
        type="primary"
        block
        class="btnClass"
        :class="{ towClass: isclickStyle == 2 }"
        @click="starStopVideoFun"
      >
        <template v-if="isclickStyle == 0">
          <i style="font-size: 0.4rem" class="iconfont icon-shipinbofang"> </i>
          点击开始录制
        </template>
        <template v-else-if="isclickStyle == 1">
          <i style="font-size: 0.4rem" class="iconfont icon-zanting"> </i>
          结束录屏
          <!-- 请下拉刷新获取录制的视频 -->
          <span style="font-size: 0.22rem">（{{ showTimesNum }}）</span>
        </template>
        <template v-else-if="isclickStyle == 2">
          <!-- <lottieAdmin /> -->
          <i style="font-size: 0.4rem" class="iconfont antran icon-jiazai1">
          </i>
          正在上传
          <span style="font-size: 0.22rem; margin-left: 0.2rem"
            >(点击或下拉刷新)</span
          >
        </template>
        <div class="backLoad" v-if="isclickStyle == 2">
          <div class="liuguang">
            <div class="back" style="--j: 1"></div>
            <!-- <div class="back" style="--j:2"></div>
            <div class="back" style="--j:3"></div> -->
          </div>
          <!-- <div class="liuguang" style="--j:1"></div>
          <div class="liuguang" style="--j:2"></div>
          <div class="liuguang" style="--j:3"></div> -->
        </div>
        <!-- <span style="font-size: 0.22rem">（{{ showTimesNum }}）</span> -->
      </van-button>
      <van-button
        type="warning"
        class="btnClass btnClass1"
        block
        @click="clonseVideo"
      >
        <i style="font-size: 0.4rem" class="iconfont icon-tuichu"> </i>
        退出录屏
      </van-button>
    </div>
    <van-pull-refresh
      style="min-height: calc(100vh - 90px)"
      v-model="isLoading"
      @refresh="getVideoUpListFun"
    >
      <div class="dlVPage">
        <div class="videoCont" v-if="isclickStyle == 1">
          <div class="colasBock">
            <span style="--i: 1">视</span>
            <span style="--i: 2">频</span>
            <span style="--i: 3">录</span>
            <span style="--i: 4">制</span>
            <span style="--i: 5">中</span>
            <span style="--i: 6">，</span>
            <span style="--i: 7">请</span>
            <span style="--i: 8">稍</span>
            <span style="--i: 9">后</span>
            <span style="--i: 10">。</span>
            <span style="--i: 11">。</span>
            <span style="--i: 12">。</span>
          </div>
        </div>
        <div class="videoCont" v-else-if="isclickStyle == 2">
          <div class="colasBock">
            <span style="--i: 1">视</span>
            <span style="--i: 2">频</span>
            <span style="--i: 3">上</span>
            <span style="--i: 4">传</span>
            <span style="--i: 5">中</span>
            <span style="--i: 6">，</span>
            <span style="--i: 7">请</span>
            <span style="--i: 8">稍</span>
            <span style="--i: 9">后</span>
            <span style="--i: 10">。</span>
            <span style="--i: 11">。</span>
            <span style="--i: 12">。</span>
          </div>
        </div>
        <div class="videoCont" v-for="(item, index) in viderList" :key="index">
          <div class="upTimes">上传时间：{{ item.creattime }}</div>
          <dplayer :videoUrl="item.header" :pic="item.pic" />
          <van-button
            class="db"
            type="info"
            block
            @click="dbFun(item, index)"
            color="linear-gradient(to right, #d558c8, #24d292)"
          >
            <i
              style="font-size: 0.4rem; margin-right: 0.2rem"
              class="iconfont icon-xiazai1"
            >
            </i>
            点击下载
            <span v-if="item.loaded != 0 && isAddVideo">
              （{{ item.total }}/{{ item.loaded }}）
            </span>
          </van-button>
        </div>
        <van-empty v-if="viderList.length == 0" description="暂无录制视频" />
      </div>
    </van-pull-refresh>

    <van-popup v-model="showPopup">
      <div class="divpage" @click="showPopup = false">
        <svg
          t="1717643174026"
          class="icon SvgImg"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="5900"
          width="100"
          height="100"
        >
          <path
            d="M224 832a32 32 0 0 1-22.63-54.63L722.75 256H608a32 32 0 0 1 0-64h192a31.83 31.83 0 0 1 22.35 9.1l0.43 0.43A31.83 31.83 0 0 1 832 224v192a32 32 0 0 1-64 0V301.25L246.63 822.63A31.9 31.9 0 0 1 224 832z"
            fill="#ffffff"
            p-id="5901"
          ></path>
        </svg>
        <div class="textdl">
          <div>请点击右上角</div>
          <div>通过浏览器下载</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getVideoList, getSwapToken, getwxTask, getwxTaskState } from "./api";

import { getwxOpenid } from "../signUp/api/homeApi/index";
import axios from "axios";
import dplayer from "./components/DPlayerComponent";
import lottieAdmin from "./components/lottieAdmin";
var times = null,
  timesNum = 0,
  times1 = null,
  timesNum1 = 0;
export default {
  components: { dplayer, lottieAdmin },
  data() {
    return {
      isPageIndex: 0,
      isLoading: false,
      isclickStyle: 0,
      showTimesNum: "00:00",
      showPopup: false,
      isAddVideo: false,
      self: {
        total: 0,
        loaded: 0,
      },
      viderList: [
        // {
        //   name: "butterflyVideo1",
        //   header: "http://oss.3vppt.com/ppt_media/noIIg3yGcgXEWaQALsauS6TVsXZ5&2024-06-14-100557-video.mp4",
        //   total: 0,
        //   loaded: 0,
        // },
      ],
    };
  },
  mounted() {
    //获取到视频列表
    // this.getVideoUpListFun();
    //通过code获取到openid
    this.getwxOpenidFun();
    // getwxTaskState().then((res) => {
    //   console.log("获取到任务状态", res);
    // });
  },
  methods: {
    getwxTaskFun(act) {
      return new Promise((resolve, reject) => {
        getwxTask({ act }).then((res) => {
          console.log("执行了任务", res);
          if (res.rescode == 200) {
            resolve(res);
          } else {
            this.$toast.fail("执行失败");
            reject(res);
          }
        });
      });
      // getwxTask({act}).then((res)=>{
      //   console.log('执行了任务',res)
      // })
    },
    clonseVideo() {
      // 关闭录制
      if (this.isclickStyle == 1) {
        this.$toast("请先停止录屏！");
        return;
      }
      //退出设备
      this.getwxTaskFun("exit").then(() => {
        this.isclickStyle = 0;
        this.stopTime(); //先关闭
        this.$toast.success("退出设备成功");
      });
    },
    starStopVideoFun() {
      //开始录制
      let that = this;
      switch (this.isclickStyle) {
        case 0:
          //开始录制
          that.getwxTaskFun("start").then(() => {
            that.isclickStyle = 1;
            that.stopTime();
            that.startTime(); //开启定时器
            //   // that.$toast('开始录制！')
            that.$dialog
              .alert({
                title: "提示",
                message: "视频已开始录制,请前往眼镜观看！",
              })
              .then(() => {
                // on close
              });
          });
          break;
        case 1:
          //停止录制
          that.getwxTaskFun("stop").then(() => {
            that.isclickStyle = 2;
            that.stopTime(); //先关闭
            that.startTime1(); //开启三秒轮询查询接口
            // this.$toast('停止录制！')
          });

          // that.isclickStyle = 2;
          break;
        case 2:
          // that.isclickStyle = 0;
          if (this.isLoading) {
            return;
          }
          that.isLoading = true;
          that.getVideoUpListFun();
          break;
      }
    },
    startTime1() {
      let that = this;
      times1 = setInterval(() => {
        that.getVideoUpListFun();
      }, 3000);
    },
    startTime() {
      let that = this;
      times = setInterval(() => {
        timesNum++;
        that.showTimesNum = (function () {
          if (timesNum <= 0) {
            return "00:00";
          }
          let nt = timesNum % 60,
            ft = Math.floor(timesNum / 60);
          if (nt < 10) nt = `0${nt}`;
          if (ft < 10) ft = `0${ft}`;
          return `${ft}:${nt}`;
        })();
        // 大于2分钟
        if (timesNum >= 120 && that.isclickStyle == 1) {
          that.starStopVideoFun();
        }
      }, 1000);
    },
    stopTime() {
      timesNum = -3;
      clearInterval(times);
    },
    getwxOpenidFun() {
      let that = this;
      // const queryString = window.location.search;
      // // 将查询字符串转换为参数对象
      // const urlParams = new URLSearchParams(queryString);

      // // 从参数对象中获取code值
      // const code = urlParams.get("code");
      const openid = this.$route.query.openid;
      console.log("Code:", openid);
      try {
        getSwapToken({ openid: openid }).then((ress) => {
          console.log("获取到swapToken----------", ress);
          localStorage.setItem("videoToken", ress.resmsg);
          // 调取接口获取到视频列表
          that.getVideoUpListFun();
        });
      } catch (error) {}
    },
    getVideoUpListFun() {
      let that = this,
        viderListLength = that.viderList.length;
      getVideoList({
        page: 1,
        page_size: 50,
      })
        .then((result) => {
          console.log(result);
          that.isLoading = false;
          if (result.rescode) {
            let dataList = [];
            result.resmsg.forEach(async (element) => {
              element.total = 0;
              element.loaded = 0;
              try {
                element.pic = `data:image/png;base64,${element.cover.replace(
                  /^b'|'$/g,
                  ""
                )}`;
              } catch (error) {
                element.pic = require("../../assets/signUp/video/videoBg1.jpg");
              }

              dataList.push(element);
            });
            if (viderListLength != dataList.length) {
              that.isclickStyle = 0;
              that.stopTime(); //先关闭
              clearInterval(times1);
              if (that.isPageIndex != 0) {
                that.$dialog
                  .alert({
                    title: "提示",
                    message: "视频！",
                  })
                  .then(() => {
                    // on close
                  });
              }

              that.isPageIndex++;
            }
            that.viderList = dataList;
          }
        })
        .catch((err) => {});
    },
    dbFun(item, index) {
      let that = this;
      if (this.isAddVideo) return;
      if (this.is_weixn()) {
        this.showPopup = true;
      } else {
        this.isAddVideo = true;
        this.downloadVideo(JSON.parse(JSON.stringify(item)), index);
      }
    },
    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },

    setfinishSize(size) {
      let finishSize = "";
      if (size > 1048576) {
        finishSize = parseFloat(size / 1048576).toFixed(1) + "M";
      } else if (size > 1024) {
        finishSize = parseInt(size / 1024) + "K";
      } else {
        finishSize = size + "B";
      }
      return finishSize;
    },
    async downloadVideo(urls, index) {
      let that = this;
      try {
        const response = await axios.get(urls.header, {
          responseType: "blob",
          onDownloadProgress: function (progress) {
            urls.loaded = that.setfinishSize(progress.loaded);
            urls.total = that.setfinishSize(progress.total);
            that.$set(that.viderList, index, urls);
          },
        });
        setTimeout(() => {
          urls.total = 0;
          urls.loaded = 0;
          that.$set(that.viderList, index, urls);
          that.isAddVideo = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "video.mp4");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, 500);
      } catch (error) {
        this.isAddVideo = false;
        console.error("视频下载失败", error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.van-popup) {
  background: transparent !important;
}
:deep(.van-button__text) {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.van-button--primary) {
  .backLoad {
    background: #07c160;
    height: 100%;
    width: 20%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    animation: backLoadAdmi 20s linear forwards;
    overflow: hidden;
    .liuguang {
      position: absolute;
      left: -0.2rem;
      top: -0.2rem;
      height: 100%;
      animation: gradientShift 2s ease infinite;
      .back {
        position: absolute;
        left: 0;
        height: 120%;
        width: 0.4rem;
        background: rgb(255 255 255 / 45%);
        background-size: 200% 100%;
        left: calc(0.3 * var(--j));
      }
    }
  }
}

@keyframes gradientShift {
  0% {
    // background-position: 0% 50%;
    transform: skew(-40deg) translateX(-0.2rem);
  }
  100% {
    // background-position: 100% 50%;
    transform: skew(-40deg) translateX(7.5rem);
  }
}
@keyframes backLoadAdmi {
  0% {
    width: 0%;
  } /* 动画开始时的角度 */
  100% {
    width: 90%;
  } /* 动画结束时的角度，360度表示完整一圈 */
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  } /* 动画开始时的角度 */
  100% {
    transform: rotate(360deg);
  } /* 动画结束时的角度，360度表示完整一圈 */
}
.kslz {
  padding: 0 0.2rem 0.1rem;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 46px;
  z-index: 10;
  background: #fff;
  .towClass {
    background: #07c1608a !important;
    border: #07c1608a !important;
  }
  .btnClass {
    font-size: 0.32rem;
    transition: all 0.2s;
  }
  .antran {
    transform: scaleX(-1);
    animation: spin 1.5s linear infinite;
  }
  .iconfont {
    margin-right: 0.1rem;
  }
  .btnClass1 {
    width: 0;
    position: absolute;
    opacity: 0;
    right: -1.2rem;
    overflow: hidden;
  }

  .clickStyle {
    width: 46%;
  }
  .rightS {
    width: 46%;
    opacity: 1;
    right: 0.2rem;
    // position: relative !important;
    transition-delay: 0.1s;
  }
}

.divpage {
  width: 100vw;
  height: 100vh;
  // background: #fff;
  position: relative;
  .textdl {
    position: absolute;
    right: 0.5rem;
    top: 2.2rem;
    font-size: 0.32rem;
    color: #fff;
  }

  .SvgImg {
    position: absolute;
    right: 0;
    top: 0.2rem;
  }
}

/* 设置动画 */
@keyframes donghua {
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-20px);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}
.dlVPage {
  padding-top: 0rem;
  .videoCont {
    padding: 0.2rem;
    box-sizing: border-box;
    border-bottom: 1px solid #e5e5e5;
    .colasBock {
      width: 100%;
      height: 3.6rem;
      // background: #000;
      background-image: url(../../assets/signUp/video/videoBg1.jpg);
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 0.32rem;
      span {
        /* 添加动画 */
        animation: donghua 1.5s ease-in-out infinite;
        /* 利用变量动态计算动画延迟时间 */
        animation-delay: calc(0.1s * var(--i));
      }
    }
    &:last-child {
      border-bottom: none;
    }
    .upTimes {
      font-size: 0.24rem;
      color: #9c9999;
      margin: 0.2rem 0;
    }
    .Text1 {
      font-size: 0.24rem;
      color: #9c9999;
      margin-top: 0.2rem;
    }
    .db {
      margin: 0.2rem 0;
    }
  }
}
.downLoadInfo {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.8rem;
}
</style>
