import Vue from 'vue'
import Vuex from 'vuex'
import signUpModule from './modules/signUp';  
import XRleaseModule from './modules/XRlease';
import getters from './getters'; // 导入 getters 为对象
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({  
  state: {},
  mutations: {},
  actions: {},
  modules: {  
    user: signUpModule,
    xrlease: XRleaseModule,
  },
  getters,
  plugins: [  
    createPersistedState(),  
  ], 
});


// export default new Vuex.Store({
//   state: {
//     // 定义全局变量  
//     globalVariable: 'Hello, Vuex!'
//   },
//   getters: {
//     // 用来从state中派生出一些状态  
//     getGlobalVariable(state) {
//       return state.globalVariable;
//     }
//   },
//   mutations: {
//     // 用来更改state中的全局变量  
//     SET_GLOBAL_VARIABLE(state, newValue) {
//       state.globalVariable = newValue;
//     }
//   },
//   actions: {
//     // 可以包含任意异步操作，并通过commit调用mutations  
//     updateGlobalVariable({ commit }, newValue) {
//       commit('SET_GLOBAL_VARIABLE', newValue);
//     }
//   },
//   modules: {
//   }
// })