<template>
  <div class="container">
    <van-grid
      style="--van-grid-item-content-padding: 20px"
      :border="false"
      :column-num="2"
    >
      <van-grid-item
        v-for="(item, index) in GradeList"
        :key="index"
        @click="gotoPage(item)"
      >
        <div class="divBoxBorder">
          <van-image width="100%" height="42vw" lazy-load :src="item.url" />
          <div class="titles">
            <span>模板编号</span>
            <span>{{ item.name }}</span>
          </div>
        </div>
      </van-grid-item>
    </van-grid>
    <van-popup round v-model="showMusic" :style="{ width: '80%' }">
      <div class="popupTopTitle">声音模板</div>
      <div class="musicList">
        <div class="eachMusic" @click="pages(item)" v-for="(item, index) in musicList" :key="index">
          {{index+1}}. <span class="musicName">{{item.name}}</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import urlencode from "urlencode";
import { postGradeList } from "../api/index";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      GradeList: [],
      musicList: [],
      selectModel:'',
      showMusic:false
    };
  },
  mounted() {
    this.getpostGradeListFun();
  },
  computed: {
    ...mapState({
      // 将 store 中的 count 映射到 localComputedCount
      getUrl: (state) => state.user.getUrl,
    }),
  },
  methods: {
    getpostGradeListFun() {
      // let postUrl = `${this.getUrl.host}:${this.getUrl.web}`
      postGradeList().then((res) => {
        try {
          let newArr = [],
            musicInfo = [];
          res.resmsg.models.forEach((element) => {
            newArr.push({
              url: `${process.env.VUE_APP_MODEL_URL}models/${element}`,
              name: element.slice(0, 5),
              element,
            });
          });
          res.resmsg.music.forEach((element) => {
            musicInfo.push({
              url: `${process.env.VUE_APP_MODEL_URL}music/${element}`,
              name: element,
            });
          });
          console.log(newArr, "66666666666666666666666");
          this.GradeList = newArr;
          this.musicList = musicInfo;
        } catch (error) {}
      });
    },
    pages(item){

      this.$router.push({
        path: "/aiVideo/Recording",
        query: { modUrl: this.selectModel,musicInfo: item.name },
      });
    },
    gotoPage(item) {
      this.selectModel = item.element
      this.showMusic = true;
      // musicInfo
      // this.$router.push({
      //   path: "/aiVideo/Recording",
      //   query: { modUrl: item.element },
      // });




      // let urlurl = urlencode(
      //   location.href.split("#")[0] +
      //   "#/aiVideo/getCode?"+
      //   "modUrl="+item.element
      // );
      // window.location.href =
      //   "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx959c3e8ff427eaeb&redirect_uri=" +
      //   urlurl +
      //   "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
  },
};
</script>

<style lang="scss" scoped>
.musicList{
.eachMusic{
  font-size: .28rem;
  color: #ccc;
  padding: .2rem;
}
}
.popupTopTitle{
  width: 100%;
  text-align: center;
  font-size: .32rem;
  font-weight: bold;
  padding: .2rem;
  box-sizing: border-box;
}
.container {
  min-height: 100vh;
  // background: #e5e5e5;
}
.divBoxBorder {
  // border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .titles {
    width: 100%;
    font-size: 0.28rem;
    margin-top: 0.4rem;
    color: #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>