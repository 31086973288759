<template>
  <div>
    <div class="topLuyin">
      <div class="topDisTitle">
        <div>名画也疯狂</div>
        <mp3Text ref="mp3Text" />
      </div>
      <div class="divBtomFun">
        <van-image
          width="3rem"
          height="3rem"
          class="ht"
          :src="require('@/assets/aiVideo/ht.webp')"
        ></van-image>
        <div style="display: flex; flex-direction: column">
          <!-- <inputFile inputTitle="拍摄视频" @stimeUraniuml="stimeUraniuml" /> -->
          <van-button
            style="background: transparent; margin-top: 0.2rem"
            color="#fff"
            plain
            hairline
            type="primary"
            @click="touchstart"
          >
            <span v-if="pageType == 0">开启录音权限</span>
            <span v-if="pageType == 1">开始录音</span>
            <span v-if="pageType == 2">停止录音</span>
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "qiniu-js/esm/utils";
import { wechatSharefund } from "../../api/wechatSharefund";
//必须引入的核心
import Recorder from "recorder-core";

//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine";
//录制wav格式的用这一句就行
import "recorder-core/src/engine/wav";

//可选的插件支持项，这个是波形可视化插件
import "recorder-core/src/extensions/waveview";

//ts import 提示：npm包内已自带了.d.ts声明文件（不过是any类型）
import inputFile from "./inputFile.vue";
import mp3Text from "./mp3controls.vue";
let mediaRecorder = "";
export default {
  components: { mp3Text, inputFile },
  data() {
    return {
      localId: null, //停止录音后微信返回的音频id
      chunks: [],
      rec: null,
      pageType: 0,
      timer: null,
    };
  },
  props: ["divRef"],
  mounted() {
    // wechatSharefund();
    // let chunks = []; // 用于存储录制的音频片段
    let that = this;
    this.$nextTick(() => {
      this.recOpen();
    });
  },
  methods: {
    stimeUraniuml(e) {
      this.$emit("stimeUraniuml", e);
    },
    recOpen() {
      //创建录音对象
      this.rec = Recorder({
        type: "wav", //录音格式，可以换成wav等其他格式 mp3
        sampleRate: 16000, //录音的采样率，越大细节越丰富越细腻
        bitRate: 16, //录音的比特率，越大音质越好
        onProcess: (
          buffers,
          powerLevel,
          bufferDuration,
          bufferSampleRate,
          newBufferIdx,
          asyncEnd
        ) => {
          //录音实时回调，大约1秒调用12次本回调
          //可实时绘制波形，实时上传（发送）数据
          if (this.wave)
            this.wave.input(
              buffers[buffers.length - 1],
              powerLevel,
              bufferSampleRate
            );
        },
      });

      //打开录音，获得权限
      this.rec.open(
        () => {
          this.pageType = 1;
          console.log("录音已打开");
          if (this.divRef) {
            // if (this.$refs.recwave) {
            //创建音频可视化图形绘制对象
            // this.wave = Recorder.WaveView({ elem: this.$refs.recwave });
            this.wave = Recorder.WaveView({ elem: this.divRef });
          }
        },
        (msg, isUserNotAllow) => {
          //用户拒绝了录音权限，或者浏览器不支持录音
          console.log(
            (isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg
          );
        }
      );
    },
    recStart() {
      if (!this.rec) {
        this.pageType = 0;
        console.error("未打开录音");
        return;
      }
      this.rec.start();
      console.log("已开始录音");
    },

    recStop() {
      if (!this.rec) {
        console.error("未打开录音");
        return;
      }
      this.rec.stop(
        (blob, duration) => {
          //blob就是我们要的录音文件对象，可以上传，或者本地播放
          this.recBlob = blob;
          //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
          var localUrl = (window.URL || webkitURL).createObjectURL(blob);
          console.log("录音成功", blob, localUrl, "时长:" + duration + "ms");
          // this.upload(blob);//把blob文件上传到服务器
          this.$emit("uploadAdd", [
            {
              file: new File([blob], "Audio.wav", { type: "audio/wav" }),
              localUrl: localUrl,
              duration: duration,
              name: localUrl.substring(localUrl.length, localUrl.length - 11),
              animationState: "paused",
            },
          ]);
          clearInterval(this.timer);
          // this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
          // this.rec = null;
        },
        (err) => {
          console.error("结束录音出错：" + err);
          this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
          this.rec = null;
        }
      );
    },
    recPlay(recBlob) {
      //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
      // var localUrl = URL.createObjectURL(this.recBlob);
      var localUrl = URL.createObjectURL(recBlob);
      var audio = document.createElement("audio");
      audio.controls = true;
      document.body.appendChild(audio);
      audio.style.display = "none";
      audio.src = localUrl;
      audio.play(); //这样就能播放了
      // 添加播放结束的回调
      audio.onended = () => {
        console.log("音频播放完毕");
        // 这里可以执行播放结束后你需要做的操作
      };

      // 可选：处理播放错误
      audio.onerror = (error) => {
        console.error("音频播放出错:", error);
      };

      //注意不用了时需要revokeObjectURL，否则霸占内存
      setTimeout(function () {
        URL.revokeObjectURL(audio.src);
      }, 5000);
    },
    statrviod() {
      mediaRecorder.start();
    },
    async stopRecording() {
      await mediaRecorder.stop();
      //   function getAudioFile() {
      const blob = new Blob(this.chunks, { type: "audio/webm" }); // 或其他合适的音频类型
      const url = URL.createObjectURL(blob);
      // 可以使用这个url预览或下载音频
      // 例如，自动下载音频文件
      const link = document.createElement("a");
      link.href = url;
      link.download = "recorded_audio.webm";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      //   }
      // 在这里可以执行获取音频文件的操作
    },
    startTimeTwoZoie() {
      //开始录音19秒
      let that = this;
      let startTime = new Date().getTime();
      let startTimeTwo = new Date().getTime();
      let duration = 0;
      this.timer = setInterval(() => {
        duration = new Date().getTime() - startTime;
        if (duration >= 10000) {
          console.log("触发系统录音关闭");
          clearInterval(that.timer);
          // that.pageType=1
          that.touchstart();
        }
      }, 1000);
      return;
      //开始录音19秒
    },
    touchstart() {
      // return
      switch (this.pageType) {
        case 0:
          this.recOpen();
          break;
        case 1:
          this.pageType = 2;
          this.recStart();
          this.startTimeTwoZoie();
          this.$emit("uploadAdd", []);
          this.$refs.mp3Text.playAudio();
          break;
        case 2:
          this.pageType = 1;
          this.$refs.mp3Text.stopTime();
          this.recStop();
          break;
      }
      this.$emit("setType", this.pageType);

      return;
      console.log("开始录音");
      if (this.localId) {
        return;
      }
      let that = this;

      wx.startRecord({
        success: function (e) {
          // 开始录音的代码处理
        },

        cancel: function (e) {
          console.log(e);
        },
      });
    },

    touchend() {
      console.log("结束录音");

      if (this.localId) {
        return;
      }

      let that = this;

      clearInterval(this.timer);

      wx.stopRecord({
        success: function (res) {
          // 结束后的代码处理
          this.localId = res.localId;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.topLuyin {
  width: 96%;
  margin-left: 2%;
  border-radius: 0.2rem;
  padding: 0.2rem;
  box-sizing: border-box;
  background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
  overflow: hidden;
  .divBtomFun {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: -0.8rem;
    // border: 1px solid red;
    .ht {
      transform: rotate(35deg) translateY(1rem) translateX(-0.2rem);
    }
  }
  .topDisTitle {
    display: flex;
    align-items: start;
    justify-content: space-between;

    font-size: 0.4rem;
    color: #fff;
  }
}
</style>