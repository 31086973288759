<template>
  <div class="home">
    <!-- <div
      style="
        float: left;
        justify-content: space-between;
        text-align: center;
        line-height: 49px;"
        >
      <el-button size="mini" @click="exit">返回</el-button>
    </div> -->
    <div class="home-header">
      <span>巡展主题管理系统</span>
    </div>
    <!-- <div class="input">
      <el-input v-model="input" placeholder="搜索巡展"></el-input>
    </div> -->
    <!--添加巡展-->
    <div class="dialog">
      <!-- <van-button @click="addclass">添加巡展</van-button> -->
      <van-button @click="addclass" style="width: 100%">添加巡展</van-button>
      <!-- <van-button @click="addclass" style="width: 100%;">添加巡展</van-button> -->
      <el-dialog
        title="添加巡展"
        :visible.sync="AdddialogFormVisible"
        class="updialog"
        :fullscreen="true"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        @close="addtimenum = []"
      >
        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="巡展名">
            <!-- <el-select v-model="form.name" placeholder="请选择">
              <el-option
                v-for="item in getCourse"
                :key="item.value"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select> -->

            <el-input v-model="form.name"></el-input>
          </el-form-item>

          <el-form-item label="单场时间">
            <el-input type="number" v-model="form.borderTime"></el-input>
          </el-form-item>
          <!-- <el-form-item label="组合价格">
            <el-input v-model="form.price"></el-input>
          </el-form-item> -->

          <el-form-item label="选择时间">
            <div v-for="(item, index) in addtimenum" style="background: red">
              <div class="block" style="float: left">
                <el-date-picker
                  size="mini"
                  v-model="item.datatimevalue1"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="yyyy/MM/dd HH:mm"
                >
                </el-date-picker>
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button
              style="
                border: 1px solid red;
                justify-content: space-between;
                text-align: center;
                margin-left: 5px;
                margin-top: 10px;
              "
              @click="addtime"
            >
              添加
            </el-button>
            <el-button
              style="
                border: 1px solid red;
                justify-content: space-between;
                text-align: center;
                margin-left: 5px;
                margin-top: 10px;
              "
              @click="deletetime"
            >
              删除
            </el-button>
          </el-form-item>
          <el-form-item label="巡展介绍">
            <el-input v-model="form.content" type="textarea"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addclassSave">立即创建</el-button>
            <el-button @click="addCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!--新增年级-->
    <div class="Addclass">
      <van-button @click="Addclass" style="width: 100%">添加年级</van-button>
      <el-dialog
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        title="添加年级"
        :visible.sync="addClassform"
        class="updialog"
      >
        <el-form ref="form" :model="addFormclss" label-width="80px">
          <el-form-item label="年级">
            <el-input v-model="addFormclss.name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="AddclassSave">立即添加</el-button>
            <el-button @click="addCancelclass">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!--新增班级-->
    <div class="Addclass">
      <van-button @click="AddNewclass" style="width: 100%">添加班级</van-button>
      <el-dialog
        title="添加班级"
        :visible.sync="addNewclassform"
        class="updialog"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="addnewFormclss" label-width="80px">
          <el-form-item label="年级">
            <el-select
              v-model="addnewFormclss.optionsvalue"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级">
            <el-input v-model="addnewFormclss.name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="AddnewclassSave"
              >立即添加</el-button
            >
            <el-button @click="AddCancelclass">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!--删除班级-->
    <div class="Addclass">
      <van-button @click="deleteClass" style="width: 100%">删除班级</van-button>
      <el-dialog
        title="删除班级"
        :visible.sync="deleteClassform"
        class="updialog"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="deleteFormclss" label-width="80px">
          <el-form-item label="年级">
            <el-select
              v-model="deleteFormclss.grade"
              placeholder="请选择"
              @change="onChangeSelect"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="班级">
            <el-select v-model="deleteFormclss.class" placeholder="请选择班级">
              <el-option
                v-for="item in classList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="delectclassSave">删除</el-button>
            <el-button @click="deleteCancelclass">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!--修改班级-->
    <div class="Addclass">
      <van-button @click="updateClasss" style="width: 100%"
        >修改班级</van-button
      >
      <el-dialog
        title="修改班级"
        :visible.sync="updateClassform"
        class="updialog"
        :show-close="false"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
      >
        <el-form ref="form" :model="updateFormclss" label-width="80px">
          <el-form-item label="年级">
            <el-select
              v-model="updateFormclss.grade"
              placeholder="请选择"
              @change="onChangeSelect"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="修改年级">
            <el-input v-model="updateFormclss.updateGrade"></el-input>
          </el-form-item>
          <el-form-item label="班级">
            <el-select v-model="updateFormclss.class" placeholder="请选择班级">
              <el-option
                v-for="item in classList"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="修改班级">
            <el-input v-model="updateFormclss.updateClass"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="updateclassSave"
              >立即修改</el-button
            >
            <el-button @click="updateCancelclass">取消</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
    <!-- 巡展-->
    <div class="home-main-right">
      <div
        class="main"
        style="border-top: 1px solid #ccc"
        v-for="(item, index) in getres"
        :key="index"
      >
        <div class="main-title">
          <div class="main-title-box">
            <!-- <router-link
              :to="{
                path: '/signUp/XRbutterfly',
                query: {
                  id: item.id,
                  schoole_id: classid,
                  loginid: getRouter,
                  school_id: school_id,
                },
              }"
            > -->
            <!-- <router-link :to="{path:'application', query:{id:item.id,schoole_id:classid,Group:getRoutergroup,Class:getRouterclass,loginid:getRouter,school_id:school_id}}"></router-link> -->
            <div
              class="main-title-size"
              @click="getQrCode(item)"
              style="margin: 5px 0"
            >
              <div>
                <div style="">
                  <span style="font-size: 16px">{{ item.name }}</span>
                </div>
              </div>
              <div
                style="
                  min-height: 20px;
                  width: 100%;
                  display: flex;
                  justify-content: start;
                  margin: 5px 0;
                "
              >
                <span>{{ item.content }}</span>
              </div>
              <div style="margin: 5px 0"></div>
              <div v-for="(list, index) in JSON.parse(item.time)">
                <span style="font-size: 10px"
                  >时间: {{ list.datatimevalue1 }}
                  至
                  {{ list.datatimevalue2 }}
                </span>
                <!-- <span style="font-size: 10px"
                    >开始时间:{{
                      JSON.stringify(list.datatimevalue1).substr(1, 11)
                    }}</span
                  > -->
              </div>
              <div class="main-title-h5-s" style="margin: 5px 0">
                <!-- <h5>￥{{ item.price }}</h5>
                  <h5>￥{{ item.price }}</h5> -->
                <!-- <div v-if="item.oneprice" style="margin-right: 10px">
                    单人价格：<span>￥{{ item.oneprice }}</span>
                  </div> -->
                <div v-if="item.price">
                  价格：<span>￥{{ item.price }}</span>
                </div>
              </div>
            </div>
            <!-- </router-link> -->
          </div>
        </div>
        <div>
          <!-- <van-button @click="delectclass(item)">删除</van-button> -->
          <van-button @click="addMoney(item)">价格列表</van-button>
        </div>
        <div class="dialogs">
          <van-button @click="UpdateialogFormVisibleclick(item)"
            >修改</van-button
          >
        </div>
      </div>
    </div>

    <el-dialog
      title="修改巡展"
      :visible.sync="UpdateialogFormVisible"
      :fullscreen="true"
      class="updialog"
      @close="addtimenum = []"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="巡展名">
          <!-- <el-select v-model="form.name" placeholder="请选择">
            <el-option
              v-for="item in getCourse"
              :key="item.value"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select> -->

          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="单场时间">
          <el-input type="number" v-model="form.borderTime"></el-input>
        </el-form-item>
        <!-- <el-form-item label="价格">
          <el-input v-model="form.price"></el-input>
        </el-form-item> -->
        <el-form-item label="选择时间">
          <div v-for="(item, index) in addtimenum" :key="index">
            <div class="block" style="float: left">
              <el-date-picker
                size="mini"
                v-model="item.datatimevalue1"
                type="datetime"
                placeholder="选择日期时间"
                value-format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
            </div>
            <!-- <div class="block" style="float: left; border-bottom: 1px solid #c4c4c4;">
                <el-date-picker
                  size="mini"
                  v-model="item.datatimevalue2"
                  type="datetime"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
              </div> -->
          </div>
        </el-form-item>

        <el-form-item>
          <el-button
            style="
              border: 1px solid red;
              justify-content: space-between;
              text-align: center;
              margin-left: 5px;
            "
            @click="addtime"
          >
            添加
          </el-button>
          <el-button
            style="
              border: 1px solid red;
              justify-content: space-between;
              text-align: center;
              margin-left: 5px;
              margin-top: 10px;
            "
            @click="deletetime"
          >
            删除
          </el-button>
        </el-form-item>
        <el-form-item label="巡展介绍">
          <el-input v-model="form.content" type="textarea"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updataSave()">保存</el-button>
          <el-button @click="updataCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <van-overlay :show="addMoneyIf">
      <div class="wrapper" @click="addMoneyIf = false">
        <div class="block" @click.stop>
          <div class="topTiele">金额列表</div>
          <div class="moneylistDat">
            <div
              class="EachM"
              v-for="(item, index) in addMoneyList"
              :key="index"
            >
              <div class="lefym">
                <div>名称: {{ item.name }}</div>
                <div style="margin-top: 0.2rem">
                  金额:
                  <span style="color: red; font-weight: bold"
                    >￥{{ item.price }}</span
                  >
                </div>
              </div>
              <!-- <div class="rightedit"></div> -->
              <van-button
                plain
                type="primary"
                size="small"
                @click="EaitMoney(2, item)"
                >编辑</van-button
              >
            </div>
            <van-empty
              description="请添加套餐金额"
              v-if="addMoneyList.length == 0"
            />
          </div>

          <van-button block @click="EaitMoney(1)">添加金额</van-button>
          <van-button
            block
            @click="addMoneyIf = false"
            style="margin-top: 0.2rem"
            >退出</van-button
          >
          <van-overlay @click.stop :show="addMoneyIf1">
            <div class="wrapper1" @click="addMoneyIf1 = false">
              <div class="block1" @click.stop>
                <div class="topTiele">
                  {{ EaitMoneyType == 1 ? "添加" : "编辑" }}金额
                </div>
                <van-form @submit="onSubmit">
                  <van-field
                    v-model="priceData.name"
                    name="名称"
                    label="名称"
                    placeholder="名称"
                    :rules="[{ required: true, message: '请填写名称' }]"
                  />
                  <van-field
                    v-model="priceData.price"
                    name="金额"
                    label="金额"
                    placeholder="金额"
                    type="number"
                    :rules="[{ required: true, message: '请填写金额' }]"
                  />
                  <div style="margin: 16px">
                    <van-button round block type="info" native-type="submit"
                      >提交</van-button
                    >
                  </div>
                </van-form>
              </div>
            </div>
          </van-overlay>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="qrShow" @click="qrShow = false">
      <div class="wrapper1">
        <div class="block1" @click.stop>
          <qrCode :qrUrl="qrUrl" v-if="qrShow" />
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { gettime } from "./datetime.js";
// import { getTimeAfter90Minutes } from '@/views/home/gettime.js'

import {
  getschoolGroupList,
  getcourseList,
  getcourseAdd,
  getcourseDel,
  getcourseExit,
  getgradesAdd,
  getgradesList,
  getschoolGroupAdd,
  getgradesDel,
  getgradesExit,
  getcourse,
  YlWxCourse,
  PUTYlWxCourse,
  getYlWxCourse,
  SETYlWxPlatform,
  getYlWxPlatform,
  upYlWxPlatform,
} from "../../api/homeApi/index";

import qrCode from "./components/qrcode";
import { set } from "vue";

export default {
  data() {
    return {
      qrUrl: "",
      qrShow: false,
      EaitMoneyType: 1,
      addMoneyItem: null,
      priceData: { price: null, id: 0, name: "" },
      addMoneyIf: false,
      addMoneyIf1: false,
      addMoneyList: [],
      AdddialogFormVisible: false,
      UpdateialogFormVisible: false,
      addClassform: false,
      activeKey: 0,
      itemcount: 1,
      getindex: 0,
      addtimenum: [
        // {
        // datatimevalue1: '2020-01-01 00:00', // 时间 1
        // datatimevalue2: '', // 时间2
        // }
      ],
      getres: [],
      gettimestampbigen: "",
      gettimestampend: "",

      form: {
        name: "",
        // value: '',
        borderTime: 60,
        showPicker: false,
        optionsvalue: "",
        getitemData: {},
        content: "",
        price: null, //价格
      },
      input: "",
      options: [],
      upform: {
        name: "",
        content: "",
        price: 0,
        upgetitemData: {},
      },
      addFormclss: {
        name: "",
        pid: 0,
      },
      addNewclassform: false,
      addnewFormclss: {
        optionsvalue: "",
        name: "",
      },
      deleteClassform: false,
      deleteFormclss: {
        grade: "",
        class: "",
      },
      classList: [],
      gradesListData: [],
      updateClassform: false,
      updateFormclss: {
        grade: "",
        class: "",
        updateGrade: "",
        updateClass: "",
      },
      classid: sessionStorage.getItem("id"),
      getRoutergroup: "",
      getRouterclass: "",
      getRouter: 0,
      school_id: 0,
      active_id: 0,
      getCourse: [],
      getClass: [],
      itemData: [],
      Numtime: "",
      key: 0,
      queryData: {},
    };
  },
  components: { qrCode },
  mounted() {
    // if (this.$route.query.id) {
    //   this.itemcount = this.$route.query.id
    //   console.log(this.$route.query.id)
    // };
    this.getRoutergroup = this.$route.query.Group;
    this.getRouterclass = this.$route.query.Class;
    this.getRouter = this.$route.query.loginid;
    this.school_id = this.$route.query.school_id;
    this.active_id = this.$route.query.active_id;
    console.log(this.school_id);
    this.queryData.loginid = this.$route.query.loginid;
    this.queryData.school_id = this.$route.query.school_id;
    this.getcourseLists();
    // console.log(this.getRouterclass);
    // console.log(this.getRoutergroup);
  },
  methods: {
    getTimeAfter60Minutes(startTime) {
      // 将原始时间字符串解析为Date对象
      let startDate = new Date(startTime);

      // 增加90分钟
      startDate.setMinutes(startDate.getMinutes() + (this.form.borderTime||60));
      // 将修改后的时间重新格式化为字符串
      let formattedTime = `${startDate.getFullYear()}/${(
        "0" +
        (startDate.getMonth() + 1)
      ).slice(-2)}/${("0" + startDate.getDate()).slice(-2)} ${(
        "0" + startDate.getHours()
      ).slice(-2)}:${("0" + startDate.getMinutes()).slice(-2)}`;

      return formattedTime;
    },
    getQrCode(item) {
      // 生成二维码
      this.qrUrl = "";
      let that = this;

      // console.log(location.href.split('#')[0])
      let newUrl = `${location.href.split("#")[0]}#/signUp/XRbutterfly?id=${
        item.id
      }&schoole_id=${this.school_id}&loginid=${this.getRouter}&school_id=${
        this.school_id
      }`;
      that.qrUrl = newUrl;
      // 生成的二维码地址
      setTimeout(() => {
        console.log("地址", that.qrUrl);
        that.qrShow = true;
      }, 500);
      //  path: '/signUp/XRbutterfly',
      //           query: {
      //             id: item.id,
      //             schoole_id: classid,
      //             loginid: getRouter,
      //             school_id: school_id,
    },
    EaitMoney(i, item) {
      this.addMoneyIf1 = true;
      this.EaitMoneyType = i;
      try {
        this.priceData.price = item?.price || null;
        this.priceData.id = item?.id || null;
        this.priceData.name = item?.name || null;
      } catch (error) {}
    },
    onSubmit() {
      const params = {
        price: this.priceData.price,
        name: this.priceData.name,
        user_id: sessionStorage.getItem("id"),
        parent: this.addMoneyItem.id,
        id: this.priceData.id,
        time: this.addMoneyItem.time,
      };

      // console.log(this.addMoneyItem);
      // return
      //添加票据
      if (this.EaitMoneyType == 1) {
        YlWxCourse(params).then((res) => {
          this.addMoneyIf1 = false;
          this.priceData.price = null;
          this.addMoney(this.addMoneyItem);
        });
      } else {
        // 编辑票据
        PUTYlWxCourse(params).then((res) => {
          this.addMoneyIf1 = false;
          this.priceData.price = null;
          this.addMoney(this.addMoneyItem);
        });
      }
    },
    addMoney(item) {
      this.addMoneyIf = true;
      this.addMoneyItem = item;
      getYlWxCourse({ parent: item.id }).then((res) => {
        this.addMoneyList = res.resmsg;
      });
    },
    onConfirm(time) {
      this.form.value = time;
      this.form.showPicker = false;
    },
    //添加巡展
    addclass() {
      const listData = {
        datatimevalue1: "", // 时间 1
        datatimevalue2: "", // 时间2
      };
      this.addtimenum.push(listData); //addtimenum 是巡展的多个时间段
      this.form.getitemData = this.addtimenum;
      console.log(this.form.getitemData);
      if (this.addtimenum) {
        getcourse().then((res) => {
          this.getCourse = res.data;
          console.log(this.getCourse);
        });
        console.log(this.form.name);
        this.AdddialogFormVisible = true;
      } else {
        this.$dialog.alert({
          title: "提示",
          message: "请选择时间",
        });
      }
    },
    //添加取消
    addCancel() {
      this.AdddialogFormVisible = false;
      this.addtimenum = [];
    },

    //保存按钮
    async addclassSave(date) {
      // this.form.getitemData[item].datatimevalue2 = await getTimeAfter60Minutes(
      //   this.form.getitemData[item].datatimevalue1
      // );

      if (
        this.form.name == "" ||
        this.form.price == "" ||
        this.form.getitemData[0].datatimevalue1 == ""
      ) {
        this.$message.error("请填写完整数据！");
        return;
      }
      let newTime = [],
        that = this;
      let np = await Promise.all(
        this.form.getitemData.map(async (item, index) => {
          newTime.push({
            datatimevalue1: item.datatimevalue1,
            datatimevalue2: await that.getTimeAfter60Minutes(
              item.datatimevalue1
            ),
          });
          // 假设这里有一个异步操作，比如API调用
          let result = await that.getTimeAfter60Minutes(item.datatimevalue1);
          // 可以根据需要处理result，例如修改item或收集结果
          return result; // 或者其他你需要处理的结果
        })
      );
      const params = {
        name: this.form.name,
        content: this.form.content,
        price: this.form.price,
        time: JSON.stringify(newTime),
        user_id: sessionStorage.getItem("id"),
        parent: this.queryData.school_id,
      };
      console.log("newTime---", params);
      // getcourseAdd(params).then((res) => {
      //   this.AdddialogFormVisible = false;
      //   this.getcourseLists();
      //   console.log(res);
      // });
      //添加活动
      SETYlWxPlatform(params).then((res) => {
        this.AdddialogFormVisible = false;
        this.getcourseLists();
        console.log(res);
      });
    },
    //删除按钮
    delectclass(getid) {
      this.$dialog
        .confirm({
          title: "提示",
          message: `确认删除${getid.name}吗？`,
        })
        .then(() => {
          const params = {
            id: getid.id,
          };
          getcourseDel(params).then((res) => {
            this.getcourseLists();
            this.$toast.success("删除成功");
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    //修改按钮
    // updateclass() {
    //   this.$router.push("/signUp/upform");
    // },
    //修改保存
    async updataSave(getid) {
      // for (const item in this.form.getitemData) {
      //   this.form.getitemData[item].datatimevalue2 =
      //     await getTimeAfter60Minutes(
      //       this.form.getitemData[item].datatimevalue1
      //     );
      // }

      let newTime = [],
        that = this;
      let np = await Promise.all(
        this.form.getitemData.map(async (item, index) => {
          newTime.push({
            datatimevalue1: item.datatimevalue1,
            datatimevalue2: await that.getTimeAfter60Minutes(
              item.datatimevalue1
            ),
          });
          // 假设这里有一个异步操作，比如API调用
          let result = await that.getTimeAfter60Minutes(item.datatimevalue1);
          // 可以根据需要处理result，例如修改item或收集结果
          return result; // 或者其他你需要处理的结果
        })
      );
      // time: JSON.stringify(this.form.getitemData),
      const params = {
        name: this.form.name,
        content: this.form.content,
        price: this.form.price,
        time: JSON.stringify(newTime),
        id: this.form.id,
        user_id: this.getRouter,
        parent: this.queryData.school_id,
      };
      upYlWxPlatform(params).then((res) => {
        this.UpdateialogFormVisible = false;
        this.getcourseLists(); //获取添加列表
        console.log(res);
      });
      // getcourseExit(params).then((res) => {
      //   this.getcourseLists();
      //   this.UpdateialogFormVisible = false;
      // });
      // console.log(getid);
    },
    //修改取消
    updataCancel() {
      this.addtimenum = [];
      this.UpdateialogFormVisible = false;
    },
    //添加时间控制器
    addtime() {
      const date = "";
      // 整理添加的变量
      // for(const item in this.addtimenum){
      this.itemData = {
        // id: '', // 这种最好有一个唯一id，一般就是随机数
        datatimevalue1: "", // 时间1
        datatimevalue2: "", // 时间2
      };
      // 添加到数组里面 数组的push方法， 在后面插入一个元素
      this.addtimenum.push(this.itemData);
      this.form.getitemData = this.addtimenum;

      // date = new Date(this.addtimenum[item].datatimevalue1)
      // console.log(data.gettime())
      // }
    },
    //删除时间控制器
    deletetime() {
      this.addtimenum.pop();
    },
    //返回上一级目录
    exit() {
      // this.$router.go(-1);
    },

    //新增年级
    Addclass() {
      this.addClassform = true;
    },
    //增加年级的接口
    AddclassSave() {
      const params = {
        name: this.addFormclss.name,
        pid: 0,
        school_id: this.school_id, // sessionStorage.getItem("id"),
      };
      getgradesAdd(params).then((res) => {
        this.addCancelclass();
      });
    },
    //新增年级的取消按钮
    addCancelclass() {
      this.addFormclss.name = "";
      this.addClassform = false;
    },

    //新增班级
    AddNewclass() {
      const params = {
        // pid:sessionStorage.getItem('pid'),
        school_id: this.school_id, //sessionStorage.getItem("id"),
      };
      getgradesList(params).then((res) => {
        let datas = res.data;
        if (JSON.stringify(datas) != "{}") {
          this.options = datas.filter((item) => item.pid == 0);
        }
        console.log(this.options, 222);
      });

      this.addNewclassform = true;
    },
    //新增班级保存按钮
    AddnewclassSave() {
      const params = {
        name: this.addnewFormclss.name,
        pid: this.addnewFormclss.optionsvalue,
        school_id: this.school_id, //sessionStorage.getItem("id"),
      };
      if (params.pid == "" || params.name == "") {
        this.$message.info("请填写完整信息");
        return;
      }
      getgradesAdd(params).then((res) => {
        this.AddCancelclass();
      });
    },
    //新增班级取消按钮
    AddCancelclass() {
      this.addnewFormclss.name = "";
      this.addnewFormclss.optionsvalue = "";
      this.addNewclassform = false;
    },

    //change事件
    onChangeSelect(e) {
      // this.deleteFormclss.class = ''
      this.getClassList(e);
    },
    //删除班级show按钮
    deleteClass() {
      const params = {
        // pid:sessionStorage.getItem('pid'),
        school_id: this.school_id, // sessionStorage.getItem("id"),
      };
      getgradesList(params).then((res) => {
        this.gradesListData = res.data || [];
        if (JSON.stringify(this.gradesListData) != "{}") {
          this.options = this.gradesListData.filter((item) => item.pid == 0);
        }
        console.log(this.options);
      });
      this.deleteClassform = true;
    },
    getClassList(id) {
      this.classList = this.gradesListData.filter((item) => item.pid == id);
      // console.log(this.classList[1].id);
    },

    //删除班级保存按钮
    delectclassSave() {
      if (this.deleteFormclss.grade) {
        if (this.deleteFormclss.class) {
          const params = {
            id: this.deleteFormclss.class,
          };
          getgradesDel(params).then((res) => {
            this.deleteClassform = false;
            this.deleteFormclss = {
              grade: "",
              class: "",
            };
          });
          this.classList = [];
          console.log(this.deleteFormclss.class);
        } else {
          const params = {
            id: this.deleteFormclss.grade,
          };
          getgradesDel(params).then((res) => {
            this.deleteClassform = false;
            // 删除成功请求接口
            this.deleteFormclss = {
              grade: "",
              class: "",
            };
          });
          this.options = [];
        }
      }
      // if(this.deleteClassform.class){
      //   const params ={
      //     id:this.deleteFormclss.grade || this.deleteClassform.class,
      //   }
      // getgradesDel(params).then((res) => {

      //   })
      // }
    },
    //删除班级取消按钮
    deleteCancelclass() {
      this.deleteClassform = false;
      // 取消清空
      this.deleteFormclss = {
        grade: "",
        class: "",
      };
    },

    //修改班级showform
    updateClasss() {
      const params = {
        // pid:sessionStorage.getItem('pid'),
        school_id: this.school_id, // sessionStorage.getItem("id"),
      };
      getgradesList(params).then((res) => {
        this.gradesListData = res.data || [];
        if (JSON.stringify(this.gradesListData) != "{}") {
          this.options = this.gradesListData.filter((item) => item.pid == 0);
        }
        console.log(this.options);
      });
      this.updateClassform = true;
    },

    //修改保存
    updateclassSave() {
      if (this.updateFormclss.grade && this.updateFormclss.updateGrade) {
        const params = {
          id: this.updateFormclss.grade,
          name: this.updateFormclss.updateGrade,
        };
        getgradesExit(params).then((res) => {
          this.updateClassform = false;
          //清空
          this.updateFormclss = {
            grade: "",
            class: "",
            updateGrade: "",
            updateClass: "",
          };
          this.options = [];
        });
      }
      if (this.updateFormclss.class && this.updateFormclss.updateClass) {
        const params = {
          id: this.updateFormclss.class,
          name: this.updateFormclss.updateClass,
        };
        getgradesExit(params).then((res) => {
          this.updateClassform = false;

          this.classList = [];
          //清空
          this.updateFormclss = {
            grade: "",
            class: "",
            updateGrade: "",
            updateClass: "",
          };
        });
      }
    },
    //修改班级取消按钮
    updateCancelclass() {
      this.updateClassform = false;
      //清空
      this.updateFormclss = {
        grade: "",
        class: "",
        updateGrade: "",
        updateClass: "",
      };
    },

    UpdateialogFormVisibleclick(item) {
      // const listData = {
      //   datatimevalue1: "", // 时间 1
      //   datatimevalue2: "", // 时间2
      // };
      // this.addtimenum.push(listData);
      // this.form.getitemData = this.addtimenum;
      console.log(item);
      getcourse().then((res) => {
        this.getCourse = res.data;
        let times = JSON.parse(item.time);
        this.addtimenum = times;
        this.form = {
          ...item,
          borderTime:60,
          getitemData:this.addtimenum
        };
        // this.form.borderTime = 60;
        // this.form.getitemData = this.addtimenum;
      }); //获取巡展名列表
      this.UpdateialogFormVisible = true;
      // form
    },

    //获取巡展列表接口
    getcourseLists() {
      // const params = {
      //   user_id: sessionStorage.getItem("id"),
      // };
      // getcourseList(params).then((res) => {
      //   this.getres = res.data;
      //   console.log(this.getres);
      // });

      // let params = {
      //   page_size: 100,
      //   page: 1,
      //   parent: this.active_id,
      // };
      // getYlWxCourse(params).then((res) => {
      //   this.getres = res.resmsg;
      //   console.log(this.getres);
      // });
      // 更换获取活动列表

      getYlWxPlatform({
        page: 1,
        page_size: 100,
        parent: this.queryData.school_id,
      }).then((res) => {
        console.log(res);
        this.getres = res.resmsg;
      });
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.van-cell__value) {
  display: block !important;
}
.home {
  font-size: 18px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block {
    // min-width: 4.2rem;
    width: 100%;
    height: 100%;
    background-color: #fff;
    // border-radius: 0.2rem;
    box-sizing: border-box;
    .moneylistDat {
      padding: 0.2rem;
      .EachM {
        padding: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #c4c4c4;
        .lefym {
          font-size: 0.26rem;
        }
        &:last-child {
          border: none;
        }
      }
    }
    .topTiele {
      text-align: center;
      font-size: 0.44rem;
    }
  }
}
.wrapper1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .block1 {
    min-width: 4.2rem;
    // width: 100%;
    // height: 100%;
    background-color: #fff;
    border-radius: 0.2rem;
    padding: 0.2rem;
    box-sizing: border-box;
    .topTiele {
      text-align: center;
      font-size: 0.44rem;
    }
  }
}
.home-header {
  /* background: #ccc; */
  justify-content: space-between;
  text-align: center;
  margin: 0px 0px;
  font-size: 36px;
  font-weight: bold;
  float: right;
  margin-right: 20px;
  color: #737478;
}
.home-main {
  /* background: red; */
  height: 300vh;
  width: 80px;
  border-right: 1px solid #c4c4c4;
  float: left;
  border-top: 1px solid #c4c4c4;
}
.main {
  display: flex;
  height: 100%;
  align-items: center; /* 垂直居中 */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center -50px;
  background-size: cover;
  padding: 0.2rem;
  box-sizing: border-box;
}
.main-logo h3 {
  display: none;
}
.main-title {
  // height: 180px;
  width: 100%;
  margin-left: 5px;
}
.rounded-image img {
  /* 设置图片的圆角大小 */
  border-radius: 10px; /* 或者使用百分比、50%等实现半圆形效果 */
  margin-left: 2px;
}
.main-title-box {
  margin-top: 8px;
  // height: 160px;
}
.main-title-size {
  span {
    justify-content: space-between;
    // text-align: center;
    font-size: 12px;
    color: black;
  }
}
.main-title-h5-s {
  color: black;
  display: flex;
  font-size: 12px;
  span {
    color: red;
    font-weight: bold;
    font-size: 12px;
  }
}
.fl {
  float: left;
}
/* ::v-deep .el-dialog__body{
  height: 100px;
  width: 100px;
}
::v-deep .el-dialog__header{
  height: 100px;
  width: 100px;
} */
/* ::v-depp .el-dialog{
  width: 100%;
} */
.dialog :deep .el-dialog {
  width: 100%;
}
.dialogs :deep .el-dialog {
  width: 100%;
}
.block :deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: auto;
}
.block1 :deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: auto;
  border: 1px solid #f00606;
}
.Addclass :deep .el-dialog {
  width: 100%;
}
// .home-main-right{
//   width: 100%;
//   height: 100%;
// }
</style>
