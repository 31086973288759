<template>
  <div class="dPage">
  <div class="content">
    <div class="header">
      <!-- <img src="@/assets/signUp/login/logo.png"></img> -->
      LOGO
    </div>

    <div class="list">
      <div class="list-call">
        <img class="img" src="@/assets/signUp/login/4.png" />
        <input
          class="sl-input"
          v-model="account"
          type="text"
          placeholder="输入用户名"
        />
      </div>
      <div class="list-call">
        <img class="img" src="@/assets/signUp/login/2.png" />
        <input
          class="sl-input"
          v-model="password"
          :type="!showPassword?'password':'text'"
          placeholder="输入密码"
        />
        <img class="img" v-if="showPassword" src="@/assets/signUp/login/op.png" @click="changecl" />
        <img class="img" v-else src="@/assets/signUp/login/cl.png" @click="changecl" />
      </div>
    </div>

    <div class="button-login" hover-class="button-hover" @click="onLogin()">
      <span>登录</span>
    </div>

    <!-- <div class="agreenment">
      <navigator url="forget" open-type="navigate">忘记密码</navigator>
      <text>|</text>
      <navigator url="reg" open-type="navigate">注册账户</navigator>
    </div> -->
  </div></div>
</template>

<script>
import { getlogin } from "../../api/homeApi/index";
import { Toast } from "vant";
export default {
  data() {
    return {
      account: "",
      password: "",
      getInformation: [],
      getLoginid: 0,
      getGroup_id: 0,
      showPassword: false,
    };
  },
  methods: {
    changecl() {
      this.showPassword=!this.showPassword
    },
    onLogin() {
      if (!(this.account || this.password)) {
        Toast("请输入正确的用户名及密码！");
        return;
      }
      // 登录接口
      const params = {
        account: this.account,
        password: this.password,
      };
      getlogin(params).then((res) => {
        // if(this.account === res.data.username && this.password === res.data.password){
        //   console.log("kaix");
        // }
        if (
          this.account === res.data?.username &&
          this.password === res.data?.password
        ) {
          console.log(res.data);
          this.getInformation = res.data;
          console.log(this.getInformation);
          window.sessionStorage.setItem("id", this.getInformation.id);
          window.sessionStorage.setItem(
            "group_id",
            this.getInformation.group_id
          );

          this.getGroup_id = sessionStorage.getItem("group_id");
          console.log(this.getGproup_id);
          this.getLoginid = sessionStorage.getItem("id");
          console.log(this.getLoginid);
          this.$router.push({
            name: "signUpaddshool",
            query: { loginid: this.getLoginid, group_id: this.getGroup_id },
          });
        } else {
          this.$dialog
            .alert({
              message: "用户名或者密码错误", //改变弹出框的内容
            })
            .then(() => {});
        }
      });

      // this.$router.push({name: 'signUpaddshool',query:{loginid:this.getLoginid,group_id:this.getGroup_id}})
    },
    // bindLogin() {
    //   if (this.phone.length != 11) {
    //     uni.showToast({
    //       icon: 'none',
    //       title: '手机号不正确'
    //     });
    //     return;
    //   }
    //   if (this.password.length < 6) {
    //     uni.showToast({
    //       icon: 'none',
    //       title: '密码不正确'
    //     });
    //     return;
    //   }
    //   uni.request({
    //     url: 'http://***/login.html',
    //     data: {
    //       phone: this.phone,
    //       password: this.password
    //     },
    //     method: 'POST',
    //     dataType: 'json',
    //     success: (res) => {
    //       if (res.data.code != 200) {
    //         uni.showToast({
    //           title: res.data.msg,
    //           icon: 'none'
    //         });
    //       } else {
    //         //成功后的逻辑
    //         uni.navigateBack();
    //       }
    //     }
    //   });
    // }
  },
};
</script>

<style>
.dPage{
  width:100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  /* width: 1.61rem;
    height: 1.61rem;
    background: rgba(63, 205, 235, 1);
    box-shadow: 0rem .12rem .13rem 0rem rgba(63, 205, 235, 0.47);
    border-radius: 50%;*/
  margin-top: 0.3rem;
  margin-left: auto;
  margin-right: auto;
}

.header img {
  width: 1.61rem;
  height: 1.61rem;
  border-radius: 50%;
}

.list {
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

.list-call {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 1rem;
  color: #333333;
  border-bottom: 0.5px solid #e2e2e2;
}

.list-call .img {
  width: 0.4rem;
  height: 0.4rem;
}

.list-call .sl-input {
  flex: 1;
  text-align: left;
  font-size: 0.32rem;
  margin-left: 0.16rem;
  border: 0;
}

.button-login {
  color: #ffffff;
  font-size: 0.34rem;
  width: 4.7rem;
  height: 1rem;
  background: linear-gradient(
    -90deg,
    rgba(63, 205, 235, 1),
    rgba(188, 226, 158, 1)
  );
  box-shadow: 0rem 0rem 0.13rem 0rem rgba(164, 217, 228, 0.2);
  border-radius: 0.5rem;
  line-height: 1rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.button-hover {
  background: linear-gradient(
    -90deg,
    rgba(63, 205, 235, 0.8),
    rgba(188, 226, 158, 0.8)
  );
}

.agreenment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.3rem;
  margin-top: 0.8rem;
  color: #ffa800;
  text-align: center;
  height: 0.4rem;
  line-height: 0.4rem;
}

.agreenment text {
  font-size: 0.24rem;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
}
</style>
