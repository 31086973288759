import axiosBody from 'axios'
const axios = axiosBody.create();

import { showMessage } from './status' // 引入状态码文件
axios.defaults.timeout = 50000
export let getLocation = {}
// 允许携带cookie请求
axios.defaults.withCredentials = false

axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('videoVoiceToken');
    if (accessToken) {
      config.headers.Authorization = accessToken;
    }
    // config.headers['Content-Type'] = 'application/json;multipart/form-data'
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
// 拦截
axios.interceptors.response.use(
  (res) => {
    const status = res?.code || res?.data.code || res?.status || res?.errcode
    if (!(status === '200' || status === 200 || status === 204 || status === 0 || status === 401)) {
      showMessage(status) // 传入响应码，匹配响应码对应信息
      return Promise.reject(new Error('Response error: ' + status))
    }
    return res.data || res
  },
  (err) => {
    console.log('9999999999999999', err)
    if (err && err.response) {
      // 请求已发出，但是不在2xx的范围
      return Promise.reject(err.response.data)
    } else {
      // 处理其他类型的错误，例如网络连接异常
      // alert('网络连接异常,请稍后再试!')
      return Promise.reject(new Error('Network error'))
    }
  }
)
// 发送请求
export const request = (config) => {
  let { url, params={}, method = 'POST' } = config
  console.log(params,'444444444444444444')
  method = method.toUpperCase() // 统一转换为大写形式
  // 直接返回 Axios 的 Promise
  return axios({
    method: method,
    url: url,
    params: method === 'GET' ? params : undefined, // 只有 GET 请求需要传递 params
    data: method !== 'GET' ? params : undefined, // 其他请求需要传递 data
  }).catch((error) => {
    // 在发送请求时也进行了错误处理
    // console.error('Request error:', error)
    return Promise.reject(error)
  })
}
