<template>
  <div></div>
</template>

<script>
import urlencode from "urlencode";
export default {
  mounted() {
    this.$nextTick(() => {
      this.gotoPage()
    });
  },
  methods: {
    gotoPage(item) {
      // this.$router.push({ path: "/aiVideo/Recording", query: { id: 1 } });
      let urlurl = urlencode(
        location.href.split("#")[0] + 
        "#/aiVideo/getCode?"
      );
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid="+process.env.VUE_APP_INFO_APPID+"&redirect_uri=" +
        urlurl +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
