import { request } from '../axios'

// export const getuserinfo = (params) =>
//   request({
//     url: 'http://ppt.3vppt.com/wx/v1/getAuth',
//     method: 'get',
//     params
//   })
// // 获取所有设备
// export const getAllGoods = (params) =>
//   request({
//     url: 'http://ppt.3vppt.com/wx/v1/allGoods',
//     method: 'post',
//     params
//   })


//登陆接口
export const getlogin = (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/login',
    method: 'post',
    params
  })
//巡展列表
export const getcourseList = (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/courseList',
    method: 'post',
    params
  })
//添加巡展接口
export const getcourseAdd = (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/courseAdd',
    method: 'post',
    params
  })
//修改巡展接口
export const  getcourseExit= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/courseExit',
    method: 'post',
    params
  })
//删除巡展接口
export const  getcourseDel= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/courseDelete',
    method: 'post',
    params
  })
//获取学校集团接口
export const  getschoolGroupList= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/schoolGroupList',
    method: 'post',
    params
  })
//添加学校集团和学校
export const  getschoolGroupAdd= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/schoolGroupAdd',
    method: 'post',
    params
  })
//删除学校集团和学校
export const  getschoolGroupDel= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/schoolGroupDelete',
    method: 'post',
    params
  })
//修改学校和集团
export const  getschoolGroupExit= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/schoolGroupExit',
    method: 'post',
    params
  })
//获取新增班级的接口
export const getgradesAdd= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/gradesAdd',
    method: 'post',
    params
  })
//获取班级列表接口
export const getgradesList= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/gradesList',
    method: 'post',
    params
  })
//删除班级接口
export const getgradesDel= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/gradesDelete',
    method: 'post',
    params
  })
//修改班级接口
export const getgradesExit= (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/gradesExit',
    method: 'post',
    params
  })
//获取报名接口
export const getuserEnroll = (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/userEnroll',
    method: 'post',
    params
  })
export const getcourseDetails = (params) =>
  request({
    url:'http://ppt.3vppt.com/learning/v1/courseDetails',
    method: 'post',
    params
  })
// 获取支付接口
export const pay = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/pay',
    method: 'post',
    params
  })
//获取loction接口
export const getuserinfo = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/getAuth',
    method: 'post',
    params
  })
//获取openid接口
export const getopenid = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/wechatMemberLogin',
    method: 'get',
    params
  })
//获取巡展接口
export const getcourse = (params) =>
  request({
    url: 'http://ppt.3vppt.com/learning/v1/platformCourse',
    method: 'post',
    params
  })
//获取报名人数的接口
export const getenroll = (params) =>
  request({
    url: 'http://ppt.3vppt.com/learning/v1/courseEnrollNumber',
    method: 'post',
    params
  })
  // 新获取openid的接口
  export const getwxOpenid = (params) =>
    request({
      url: 'http://ppt.3vppt.com/wx/v1/getOpenId',
      method: 'get',
      params
    })


let sendNewUrl = '/lease'
if (process.env.NODE_ENV == "development") {
  sendNewUrl = 'http://192.168.31.211:8000'
  sendNewUrl = '/lease'
  //开发环境
} else if (process.env.NODE_ENV == "production") {
  //生产环境
   sendNewUrl = '/lease'
} else {
  //本地环境
  sendNewUrl = 'http://192.168.31.211:8000'
  sendNewUrl = '/lease'
}
//新添加巡展接口
export const YlWxCourse = (params) =>
  request({
    url: sendNewUrl+'/file/YlWxCourse/',
    method: 'post',
    params
  })

//新编辑巡展接口
export const PUTYlWxCourse = (params) =>
  request({
    url: sendNewUrl +`/file/YlWxCourse/${params.id}/`,
    // url: `/lease/file/YlWxCourse/${params.id}/`,
    method: 'put',
    params
  })

//添加学校集团和学校
export const SetYlWxSchool = (params) =>
  request({
    url: sendNewUrl +'/file/YlWxSchool/',
    method: 'post',
    params
  })
//添加活动名称
export const SETYlWxPlatform = (params) =>
  request({
    url: sendNewUrl + '/file/YlWxPlatform/',
    method: 'post',
    params
  })
//获取活动名称列表
export const getYlWxPlatform = (params) =>
  request({
    url: sendNewUrl + '/file/YlWxPlatform/',
    method: 'get',
    params
  })
//获取活动名称一个
export const getYlWxPlatformOne = (params) =>
  request({
    url: sendNewUrl + `/file/YlWxPlatform/${params.id}/`,
    method: 'get',
  })

//编辑活动名称
export const upYlWxPlatform = (params) =>
  request({
    url: sendNewUrl + `/file/YlWxPlatform/${params.id}/`,
    method: 'put',
    params
  })
//获取时间价格列表
export const getYlWxCourse = (params) =>
  request({
    url: sendNewUrl + '/file/YlWxCourse/',
    method: 'get',
    params
  })
  // 获取微信配置
export const getSignPackage = (params) =>
  request({
    url: 'http://ppt.3vppt.com/wx/v1/getSignPackage',
    method: 'post',
    params
  })
// export const Testupload = (params) =>
//   request({
//     url: 'http://192.168.31.212:8100/upload',
//     method: 'post',
//     param: { "upload_id":"b1o3i854dkk"},
//     params
//   })