<template>
  <div id="ap" class="van-all">
    <div class="van-tab__panel">
      <div class="bg">
        <div
          v-for="(item, index) in getres"
          :key="index"
          class="van-grid"
          style="
            padding-left: 10px;
            padding: 0px;
            border: -1px;
            margin-top: 2px;
          "
        >
          <div
            class="List"
            v-if="item.pay_status == 1 && item.status != 2"
            style="margin-top: -5px"
          >
            <van-cell>
              <!-- description -->
              <span>租赁方式</span>
              <span style="margin-left: 50%"
                >{{ item.description.name || "设备租赁" }}
              </span>
            </van-cell>
            <van-cell>
              <span>开始时间</span>
              <span>{{ convertTimestampToBeijingTime(item.start_time) }}</span>
            </van-cell>
            <van-cell>
              <span>设备押金</span>
              <span style="margin-left: 40%"
                >{{ item.description.deposit / 100 || "--" }}元</span
              >
            </van-cell>
            <van-cell>
              <span>使用时长</span>
              <getTimeComp :times="item.start_time" />
              <!-- <span style="margin-left: 40%">{{ getTimeDifference(item.start_time) }}</span> -->
            </van-cell>
            <van-cell>
              <van-button
                class="btn"
                type="danger"
                @click="newcloseOrderExit(item)"
                style="width: 100%"
                >结束使用</van-button
              >
              <!-- @click="endofuse(item)" -->
            </van-cell>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  allOrders,
  orderStates,
  refundOrder,
  returnConsumptionAmount,
  getSignPackage,
  yksReturnConsumptionAmount,
  yksRcm,
} from "../api/mysi/index";
import { parseUrl } from "@/view/XRlease/myhome/orders";
import { generateRandomID } from "@/view/XRlease/myhome/components/outtradeno.js";
import { generateRandomIDNo } from "@/view/XRlease/myis/outtradeno";
import wx from "weixin-js-sdk";
import { Howl, Howler } from "howler"; // esm
import getTimeComp from "./getTIme.vue";

// var soundBgm = new Howl({
//   src: [require("@/assets/mp3/ghg1.mp3")], // 提供多个格式以适应不同浏览器
//   autoplay: true,
//   loop: true,
//   volume: 0.5,
// });
export default {
  components: { getTimeComp },
  data() {
    return {
      getTime: "",
      getres: "",
      url: "",
      getOpenid: "",
      //定义变量接收generateRandomID
      getrandomid: "",
      paymentstatus: "",
      bit: 64,
      boolea: "",
      GetgoodsID: "",
      getstarttime: "",
      numbertime: "",
      exitamount: 0,
      type: 0,
      passWord: "",
      closeOrder: null,
    };
  },
  methods: {
    playMusic() {
      // let audio = document.createElement("audio");
      // audio.setAttribute("src", require("@/assets/mp3/ghg1.mp3"));
      // audio.play();
      // soundBgm.on("load", () => {
      //   window.WeixinJSBridge &&
      //     window.WeixinJSBridge.invoke(
      //       "getNetworkType",
      //       {},
      //       () => {
      //         soundBgm.play();
      //       },
      //       false
      //     );
      // });
    },
    // formatTimestampToBeijingTime(timestamp) {
    //     // 创建一个Date对象，将时间戳转换为Date
    //     function padZero(num) {
    //       return num < 10 ? "0" + num : num;
    //     }
    //     const date = new Date(timestamp);
    //     // 获取年月日时分秒
    //     const year = date.getFullYear();
    //     const month = padZero(date.getMonth() + 1); // 月份从0开始，所以需要加1
    //     const day = padZero(date.getDate());
    //     const hours = padZero(date.getHours());
    //     const minutes = padZero(date.getMinutes());
    //     const seconds = padZero(date.getSeconds());
    //     // 组合成字符串
    //     const formattedTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    //     // 返回格式化后的时间字符串
    //     return formattedTime;
    //   },

    convertTimestampToBeijingTime(timestamp) {
      const date = new Date(timestamp * 1000); // 将时间戳转换为毫秒

      // 设置时区为北京时间
      date.setUTCHours(date.getUTCHours() + 8);

      // 格式化日期为年月日时分秒的格式
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, "0");
      const day = String(date.getUTCDate()).padStart(2, "0");
      const hours = String(date.getUTCHours()).padStart(2, "0");
      const minutes = String(date.getUTCMinutes()).padStart(2, "0");
      const seconds = String(date.getUTCSeconds()).padStart(2, "0");

      const beijingTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

      return beijingTime;
    },
    getTimeComp(times) {
      var today = new Date(times * 1000);
      // 获取年、月、日、时、分、秒
      var year = today.getFullYear();
      var month = today.getMonth() + 1; // 月份是从 0 开始计数的，需要加1
      var day = today.getDate();
      var hours = today.getHours();
      var minutes = today.getMinutes();
      var seconds = today.getSeconds();
      // 格式化输出
      var formattedTime =
        year +
        "-" +
        (month < 10 ? "0" : "") +
        month +
        "-" +
        (day < 10 ? "0" : "") +
        day +
        " " +
        (hours < 10 ? "0" : "") +
        hours +
        ":" +
        (minutes < 10 ? "0" : "") +
        minutes +
        ":" +
        (seconds < 10 ? "0" : "") +
        seconds;
      // this.getTime = formattedTime;
      return formattedTime;
    },
    getTimeDifference(timestamp) {
      let now = Date.now();
      let difference = now - timestamp * 1000; // 获取毫秒差值
      let seconds = Math.floor(difference / 1000);
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);

      // 重新计算余下的秒和分钟
      seconds %= 60;
      minutes %= 60;

      // 格式化输出，不足两位的前面补0
      return `${hours.toString().padStart(2, "0")}小时${minutes
        .toString()
        .padStart(2, "0")}分${seconds.toString().padStart(2, "0")}秒`;
    },
    computedMoney(type, times) {
      const time = parseInt(new Date().getTime() / 1000); //获取当前时间
      let m = time - times,
        exitamount = 0;
      if (type == 1) {
        if (Math.ceil(Number(m / 60)) <= 10) {
          exitamount = 8000;
        } else {
          if (10000 - (Math.ceil(Number(m / 60)) - 10) * 200 - 2000 < 0) {
            exitamount = 0;
          } else {
            exitamount = 10000 - (Math.ceil(Number(m / 60)) - 10) * 200 - 2000;
          }
        }
      } else if (type == 2) {
        if (Math.ceil(Number(m / 60)) <= 5) {
          exitamount = 10000;
        } else {
          if (10000 - (Math.ceil(Number(m / 60)) - 5) * 400 < 0) {
            exitamount = 0;
          } else {
            exitamount = 10000 - (Math.ceil(Number(m / 60)) - 5) * 400;
          }
        }
      } else if (type == 3) {
        console.log("花费---type--", Math.floor(Number(m / 60)));
        if (Math.floor(Number(m / 60)) <= 30) {
          exitamount = 15000; //小于30分钟，返回150元
        } else {
          // 超出是1.5元一分钟 单位分150分
          if (20000 - (Math.floor(Number(m / 60)) - 30) * 150 < 0) {
            exitamount = 0;
          } else {
            exitamount = 20000 - (Math.floor(Number(m / 60)) - 30) * 150;
          }
        }
      } else if (type == 4 || type == 5) {
        if (Math.ceil(Number(m / 60)) <= 10) {
          exitamount = 8000;
        } else {
          if (10000 - (Math.ceil(Number(m / 60)) - 10) * 200 - 2000 < 0) {
            exitamount = 0;
          } else {
            exitamount = 10000 - (Math.ceil(Number(m / 60)) - 10) * 200 - 2000;
          }
        }
      }
      console.log("花费---exitamount--", exitamount);
      return exitamount;
      // return  (10000 - exitamount) / 100;
    },
    newComputedMoney(item) {
      const time = parseInt(new Date().getTime() / 1000); //获取当前时间
      let m = time - item.start_time,
        /* 
        Math.ceil 向下取整
       */
        exitamount = 0;
      // item.description.meal = 29;
      console.log(
        m + "相差时间戳",
        item.description.meal + "体验时长",
        Math.ceil(Number(m / 60)) + "分钟"
      );
      if (Math.ceil(Number(m / 60)) <= item.description.meal) {
        exitamount = item.description.deposit - item.description.thali;
      } else {
        // 当前时间减去 套餐时间description.meal。 * 超出单价description.excer
        let nexM = item.description.deposit - item.description.thali;
        if (
          nexM - //押金减去套餐金额 * 超出分钟单价
            (Math.ceil(Number(m / 60)) - item.description.meal) *
              item.description.excer <=
          0
        ) {
          exitamount = 0;
        } else {
          exitamount =
            nexM -
            (Math.ceil(Number(m / 60)) - item.description.meal) *
              item.description.excer;
        }
      }
      console.log("退款金额---exitamount--", exitamount / 100 + "元");
      return exitamount;
    },
    newcloseOrderExit(item) {
      this.closeOrder = item; //退款订单数据
      let that = this;
      let start_time = item.start_time; //订单开始时间
      let refMo = that.newComputedMoney(item);

      that.$dialog
        .alert({
          message:
            "您已使用" +
            that.getTimeDifference(start_time) +
            "，需要支付" +
            // (10000 - that.exitamount) / 100 +
            (item.description.deposit - refMo) / 100 +
            "元，欢迎下次使用", //改变弹出框的内容
          showCancelButton: true, //展示取消按钮
        })
        .then((res) => {
          console.log(res);
          that.setWxAllocation();
        })
        .catch((error) => {
          console.log("取消点击");
        });
    },
    closeOrderExit(item) {
      this.closeOrder = item;
      let that = this;
      let start_time = item.start_time; //订单开始时间
      /*  
        out_trade_no 为订单id
        type 订单类型1 | 2
       
       */
      // 查询订单状态
      // this.newGetOrderStates(item.out_trade_no).then((res)=>{
      //     console.log('订单状态',res)
      // }).catch((error)=>{
      //   this.$toast.error("查询订单状态失败");
      // })
      // yksReturnConsumptionAmount({
      //   openid:item.openid
      //   // openid:item.out_trade_no
      // }).then((res)=>{
      // })

      if (item.type != 3) {
        let refMo = that.computedMoney(item.type, item.start_time);
        console.log(refMo);

        that.$dialog
          .alert({
            message:
              "您已使用" +
              that.getTimeDifference(start_time) +
              "，需要支付" +
              // (10000 - that.exitamount) / 100 +
              (10000 - refMo) / 100 +
              "元，欢迎下次使用", //改变弹出框的内容
            showCancelButton: true, //展示取消按钮
          })
          .then((res) => {
            console.log(res);
            that.setWxAllocation();
          })
          .catch((error) => {
            console.log("取消点击");
          });
      } else {
        // let refMo = that.computedMoney(3,item.start_time);
        let refMo = that.computedMoney(item.type, item.start_time);

        console.log(refMo, "------------");

        that.$dialog
          .alert({
            message:
              "您已使用" +
              that.getTimeDifference(start_time) +
              "，需要支付" +
              // (10000 - that.exitamount) / 100 +
              (20000 - refMo) / 100 +
              "元，欢迎下次使用", //改变弹出框的内容
            showCancelButton: true, //展示取消按钮
          })
          .then((res) => {
            console.log(res);
            that.setWxAllocation();
          })
          .catch((error) => {
            console.log("取消点击");
          });
      }
    },
    // 设置微信配置
    setWxAllocation() {
      const that = this;
      this.url = window.location.href.split("#")[0];
      const param = {
        url: this.url,
      };

      const getgenerateRandomIDNo = generateRandomIDNo(this.bit);
      console.log(param, "getgenerateRandomIDNo---", getgenerateRandomIDNo);
      getSignPackage(param).then((res) => {
        let wxPz = res;
        console.log("resData---", res);
        wx.config({
          debug: false, // 开发阶段可以设为true查看调试信息
          appId: res.appId,
          timestamp: res.timestamp,
          nonceStr: res.nonceStr,
          signature: res.signature,
          jsApiList: ["scanQRCode"], // 请求使用的JS接口列表，这里只列出scanQRCode接口
        });
        wx.ready(() => {
          // 这里可以开始调用微信的API
          wx.scanQRCode({
            // onlyFromCamera: true,
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              let qrData = res.resultStr;
              console.log("扫描结果:", qrData);
              if (qrData === "K4xSurgl3CQvB0XcbfO320WH2vHoGZ9r") {
                // let refMoney = that.computedMoney(
                //   that.closeOrder.type,
                //   that.closeOrder.start_time
                // ); //这里是退款金额
                // that.closeOrder.total 实际支付金额
                // console.log("调用refMoney---", refMoney);
                const params = {
                  transaction_id: that.closeOrder.out_trade_no, //订单号
                  out_refund_no: getgenerateRandomIDNo, //退款订单号，64位全随机
                  refund: 1, //退款金额，单位分int
                };

                console.log("调用退款订单的数据---", params);
                refundOrder(params).then((res) => {
                  console.log("调用退款订单接口--", res);
                  if (res[0] == 0) {
                    that.$message({
                      type: "error",
                      message: res[1],
                    });
                    return;
                  }
                  that.$message({
                    type: "info",
                    message: "退款成功！",
                    onClose: () => {
                      //切换tba
                      that.$emit("chanineChange");
                    },
                  });
                });
              }
            },
            fail: function (err) {
              console.error("扫描失败", err);
            },
          });
        });
        // 配置失败的回调
        wx.error((res) => {
          console.error("微信JS-SDK配置错误", res);
        });
      });
    },
    //调起订单支付状态接口
    newGetOrderStates(getiddd) {
      const params = {
        out_trade_no: getiddd, //订单
        pay_status: 1,
      };
      return new Promise((resolve, reject) => {
        orderStates(params)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            console.error("查询订单状态 -- Error:", error);
            reject();
          });
      });
    },
    endofuse(goodsId) {
      this.$dialog
        .alert({
          // title: document.getElementById("getid").innerText, //加上标题
          message: "确定结束使用了吗？", //改变弹出框的内容
          showCancelButton: true, //展示取水按钮
        })
        .then(() => {
          //结束使用
          const getgoodsId = goodsId;
          // console.log("我我我我" + JSON.stringify(getgoodsId.start_time));
          this.getstarttime = getgoodsId.start_time;
          this.GetgoodsID = getgoodsId.out_trade_no;
          this.type = getgoodsId.type;
          // this.getOrderStates(this.GetgoodsID);
          console.log("啦啦啦" + this.GetgoodsID);
          // 获取当前时间
          // 创建一个 Date 对象
          var today = new Date();
          // 获取年、月、日、时、分、秒
          var year = today.getFullYear();
          var month = today.getMonth() + 1; // 月份是从 0 开始计数的，需要加1
          var day = today.getDate();
          var hours = today.getHours();
          var minutes = today.getMinutes();
          var seconds = today.getSeconds();
          // 格式化输出
          var formattedTime =
            year +
            "-" +
            (month < 10 ? "0" : "") +
            month +
            "-" +
            (day < 10 ? "0" : "") +
            day +
            " " +
            (hours < 10 ? "0" : "") +
            hours +
            ":" +
            (minutes < 10 ? "0" : "") +
            minutes +
            ":" +
            (seconds < 10 ? "0" : "") +
            seconds;
          this.getTime = formattedTime;

          const time = parseInt(new Date().getTime() / 1000) + "";
          console.log(time);
          // debugger
          if (this.getstarttime == null) {
            this.numbertime = Number(time) - Number(time);
            console.log("咯咯咯");
          } else {
            this.numbertime = Number(time) - Number(this.getstarttime);
          }

          console.log("的时间戳为：" + time);
          console.log("时间戳之间差是" + this.numbertime);
          // console.log(
          //   10000 - Math.ceil(Number(this.numbertime / 60)) * 400 + "元"
          // );
          //退款
          const getgenerateRandomIDNo = generateRandomIDNo(this.bit);
          if (this.type == 1) {
            if (Math.ceil(Number(this.numbertime / 60)) <= 10) {
              this.exitamount = 8000;
            } else {
              if (
                10000 -
                  (Math.ceil(Number(this.numbertime / 60)) - 10) * 200 -
                  2000 <
                0
              ) {
                this.exitamount = 100;
              } else {
                this.exitamount =
                  10000 -
                  (Math.ceil(Number(this.numbertime / 60)) - 10) * 200 -
                  2000;
              }
            }
          } else {
            if (Math.ceil(Number(this.numbertime / 60)) <= 5) {
              this.exitamount = 10000;
            } else {
              if (
                10000 - (Math.ceil(Number(this.numbertime / 60)) - 5) * 400 <
                0
              ) {
                this.exitamount = 100;
              } else {
                this.exitamount =
                  10000 - (Math.ceil(Number(this.numbertime / 60)) - 5) * 400;
              }
            }
          }
          console.log("退款金额----" + this.exitamount / 100);

          this.url = window.location.href.split("#")[0];
          const param = {
            url: this.url,
          };
          console.log(param);
          getSignPackage(param)
            .then((res) => {
              this.signPackage = res;
              console.log(this.signPackage);

              wx.config({
                debug: true, // 开发阶段可以设为true查看调试信息
                appId: res.appId,
                timestamp: res.timestamp,
                nonceStr: res.nonceStr,
                signature: res.signature,
                jsApiList: ["scanQRCode"], // 请求使用的JS接口列表，这里只列出scanQRCode接口
              });

              // 微信配置成功后的回调
              wx.ready(() => {
                console.log("微信JS-SDK配置成功");
                const that = this;
                // 这里可以开始调用微信的API
                wx.scanQRCode({
                  // onlyFromCamera: true,
                  needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                  scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                  success: function (res) {
                    that.passWord = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                    // console.log('扫描结果:', this.resultStr);
                    // this.resultStr = result
                    // alert(this.resultStr);
                    // 处理扫描结果
                    // that.wxPay(getitemid,this.resultStr)
                    // this.getAllGoodsData()

                    console.log("扫描结果:", that.passWord);

                    if (that.passWord === "K4xSurgl3CQvB0XcbfO320WH2vHoGZ9r") {
                      const params = {
                        // transaction_id:this.getres[i].out_trade_no,//支付订单的订单号
                        transaction_id: that.GetgoodsID, //订单号
                        out_refund_no: getgenerateRandomIDNo, //退款订单号，64位全随机
                        // refund: 10000, //退款金额，单位分int
                        refund: this.exitamount, //退款金额，单位分int
                        // refund:1//this.exitamount,//退款金额，单位分int
                      };

                      console.log(params);
                      refundOrder(params)
                        .then((res) => {
                          console.log(res);
                          if (res[0] == 400) {
                            that.$message({
                              type: "error",
                              message: res[1],
                            });
                            return;
                          }
                          that.paymentstatus = res;
                          that.boolea = that.paymentstatus;
                          console.log("退款金额" + typeof that.exitamount);
                          console.log(
                            "开始打印状态咯" +
                              JSON.stringify(that.paymentstatus)
                          );

                          //重新调用订单列表接口？
                          that.getAllOrders(that.getOpenid);

                          // if(this.getres.status==0)
                          that.$message({
                            type: "success",
                            message: "成功退款",
                          });
                          that.$dialog.alert({
                            // title: document.getElementById("getid").innerText, //加上标题
                            message:
                              "已结束使用，您已使用" +
                              Math.ceil(Number(that.numbertime / 60)) +
                              "分钟，押金已退回，需要支付" +
                              (10000 - that.exitamount) / 100 +
                              "元，欢迎下次使用", //改变弹出框的内容
                            showCancelButton: true, //展示取消按钮
                          });
                        })
                        .catch((error) => {
                          // 处理请求失败的情况
                          console.error("Error:", error);
                          // 在这里可以显示错误信息给用户，或者进行其他处理
                        });
                    }
                  },

                  fail: function (err) {
                    console.error("扫描失败", err);
                  },
                });
              });

              // 配置失败的回调
              wx.error((res) => {
                console.error("微信JS-SDK配置错误", res);
              });
            })
            .catch((error) => {
              // 处理请求失败的情况
              console.error("Error:", error);
              // 在这里可以显示错误信息给用户，或者进行其他处理
            });

          if (
            typeof this.passWord !== "undefined" &&
            this.passWord != null &&
            this.passWord !== ""
          ) {
          }
        })
        .catch(() => {
          //点击取消按钮后的调用
          //     var a=0;
          // console.log("点击了取消按钮噢" + a)
        });
    },
    //调起订单列表接口
    getAllOrders() {
      console.log("接口没得到" + this.getOpenid);
      const params = {
        openid: this.getOpenid,
      };
      let 
         wxL = this.$store.getters.wxPayOrderList
        //  console.log(wxL,'----------')
      allOrders(params)
        .then((res) => {
          let getres = res.data;
          console.log("得到了res 列表的值-1", res.data);
          //冒泡排序
          for (let i = 0; i < getres.length - 1; i++) {
            for (let j = 0; j < getres.length - 1 - i; j++) {
              if (
                Number(getres[j].start_time) < Number(getres[j + 1].start_time)
              ) {
                let temp = getres[j];
                getres[j] = getres[j + 1];
                getres[j + 1] = temp;
              }
            }
          }
          let getresNew = getres.filter(
            (item) => item.pay_status == 1 && item.status != 2
          );

          // this.getres = getresNew.map((jsonStr) => {
          //   // 将JSON字符串转换为对象
          //   try {
          //     jsonStr.description = JSON.parse(jsonStr.description);
          //   } catch (error) {}
          //   return jsonStr;
          // });
          getresNew.forEach((item1) => {
            const match = wxL.find((item2) => item2.id == item1.type);
            if (match) {
              item1.description = match; // 修改描述
            }
          });
          this.getres = getresNew;
          console.log("得到了res 列表的值-0", this.getres);
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    //调起订单支付状态接口
    getOrderStates(getiddd) {
      const GetIddd = getiddd;
      // console.log("去去去去去"+ this.GetgoodsID);
      const params = {
        out_trade_no: this.GetgoodsID, //订单
        pay_status: 1,
      };
      orderStates(params)
        .then((res) => {
          this.paymentstatus = res;
          this.boolea = this.paymentstatus; //得到状态
          // console.log("开始打印状态咯"+this.paymentstatus);
          // console.log("拉阿鲁"+GetIddd);
        })
        .catch((error) => {
          // 处理请求失败的情况
          console.error("Error:", error);
          // 在这里可以显示错误信息给用户，或者进行其他处理
        });
    },
    pageInit() {
      this.getAllOrders();
    },
  },
  mounted() {
    let that = this;
    //得到openid
    this.url = window.location.href;
    this.getUrl = parseUrl(this.url);
    // console.log(this.$store.getters.urlSendData,'--------------' )
    // this.getOpenid = this.getUrl.parameter.openid?.substring(0, 28);
    this.getOpenid = this.$store.getters.urlSendData.openid;
    console.log("订单也得" + this.getOpenid);
    this.getAllOrders(this.getOpenid);
    this.getrandomid = generateRandomID(this.bit);
    // this.getOrderStates();
    // setTimeout(() => {
    //   that.playMusic();
    // }, 1000);
  },
};
</script>
<style>
.van-all {
  /* background-color: aqua; */
  height: 100%;
}
.van-tab__panel {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
}
.bg {
  padding-top: 2vh;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  background-color: #ededed;
}
.van-grid {
  display: flex;
  flex-wrap: wrap;
}
.van-grid-item {
  position: relative;
  box-sizing: border-box;
}
.van-grid-item__content {
  display: flex;
  /* flex-direction: column-reverse; */
  box-sizing: border-box;
  height: 100%;
  padding: var(--van-grid-item-content-padding);
  background: var(--van-grid-item-content-background);
}
.van-image__img {
  display: block;
  width: 100%;
  height: 100%;
}
.click_add_sale {
  font-size: 22px;
  text-align: center;
  color: #fff;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background-color: #19c764;
}
.flag_zu {
  text-align: center;
  color: #ffcb3b;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  background-color: #0009;
}
.List {
  /* background-color: #19c764; */
  width: 96%;
  /* 设置盒子的背景颜色为白色 */
  background-color: white;
  /* 设置盒子的边框样式，这里设置为无边框 */
  border: none;

  /* 设置盒子的圆角半径 */
  border-radius: 10px;

  /* 设置盒子的内边距，即内容与边框之间的距离 */
  padding: 10px;

  /* 设置盒子的外边距，即盒子与其他元素之间的距离 */
  margin: 20px;

  /* 设置盒子的文字对齐方式 */
  /* text-align: center;   */
  text-align: justify;

  /* （可选）设置盒子的盒子模型为border-box，这样宽度和高度会包括内边距和边框 */
  box-sizing: border-box;
}

/* .van-cell__value{
    display: flex;
    justify-content: space-between;
} */
</style>