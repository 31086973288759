<template>
  <div>
    <el-form ref="form" :model="form" label-width="80px" style="float: left;">
      <el-form-item label="地址">
        <el-input v-model="form.addrss"></el-input>
      </el-form-item>
      <el-form-item label="学校集团">
        <el-input v-model="form.shools"></el-input>
      </el-form-item>
      <el-form-item label="学校">
        <el-input v-model="form.shool"></el-input>
      </el-form-item>
      <el-form-item label="班级">
        <el-select v-model="form.class" placeholder="请">
          <el-option label="一班" value="shanghai"></el-option>
          <el-option label="二班" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="上课日期">
        <el-select v-model="form.dateday" placeholder="请">
          <el-option label="一班" value="shanghai"></el-option>
          <el-option label="二班" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上课时段">
        <el-select v-model="form.datetime" placeholder="请">
          <el-option label="一班" value="shanghai"></el-option>
          <el-option label="二班" value="beijing"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="学校集团">
        <el-select v-model="form.region" placeholder="请">
          <el-option label="一班" value="shanghai"></el-option>
          <el-option label="二班" value="beijing"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        addrss:'重庆市渝中区',
        shools:'天生集团',
        shool:'天生小学',
        class:'班级',
        name:'张三',
        dateday:'2024-05-06 2024-08-06',
        datetime:'16:00:00-19:00:00',
      }
    }

  },
  methods: {
    onSubmit() {
      console.log('submit!'+JSON.stringify(this.form));
      this.$router.push('/XRlease/home')
    },
    cancel(){
      this.$router.go(-1)
    }
  },
};
</script>
<style>
.van-button {
  margin-top: 10px;
}
</style>