<template>
  <div>
    <div class="FunBox">
      <!-- <inputFile inputTitle="拍摄或上传视频" @stimeUraniuml="stimeUraniuml" /> -->
      <!--  <van-button plain type="primary" @click="StartSetTime">
        getGradeOpenAdmin
      </van-button> -->
      <onVoice
        ref="onVoice"
        @stimeUraniuml="stimeUraniuml"
        :divRef="recwaveRef"
        @uploadAdd="uploadAdd"
        @setType="setTypeFun"
      />
    </div>
    <!-- <img :src="previewUrl" alt="Preview" v-if="previewUrl && fileIsImage" /> -->
    <div
      class="groupBox"
      :style="!previewUrl ? 'display:none' : 'display:block'"
    >
      <div class="EachGroupBox" style="padding: 0.2rem">
        <div class="titName">
          <span>选择的视频</span>
          <van-button
            plain
            type="primary"
            style="
              background-color: transparent;
              color: #fff;
              border-color: #fff;
              border-radius: 0.22rem;
            "
            size="small"
            @click="uploadAddFunVideo"
          >
            <!-- @click="uploadAddFun" -->
            <i class="iconfont icon-shangchuan"></i>
            上传1
          </van-button>
        </div>
        <div class="previewUrl" ref="previewUrl" style=""></div>
        <!-- <van-image width="100" height="100" :src="firstFrameSrc" /> -->
      </div>
    </div>

    <div
      style="
        padding-top: 0.05rem;
        overflow: hidden;
        transition: all 0.2s ease-in-out;
      "
      :style="oviceType != 2 ? 'height: 0rem;' : 'height: 2rem;'"
    >
      <!-- border: 1px solid #ccc; -->
      <div
        style="display: inline-block; vertical-align: bottom; margin-left: 3vw"
      >
        <div style="height: 2rem; width: 94vw" ref="recwave"></div>
      </div>
    </div>

    <!-- <p v-if="!previewUrl">请选择一个图片或视频文件</p> -->
    <div class="groupBox">
      <div
        class="EachGroupBox"
        style="padding: 0.2rem; min-height: 0.4rem"
        v-for="(item, index) in voickList"
        :key="index"
      >
        <div class="topTile">
          <div class="leftName">录音ID:{{ item.name }}</div>
          <div class="leftinfo">时长：{{ item.duration }}ms</div>
        </div>
        <div class="cont">
          <div style="display: flex; align-items: center">
            <i class="iconfont icon-bofang" @click="palyVoice(item, index)"></i>
            <xiaozhuanlan :item="item" />
          </div>
          <van-button
            plain
            type="primary"
            style="
              background-color: transparent;
              color: #fff;
              border-color: #fff;
              border-radius: 0.22rem;
            "
            size="small"
            @click="uploadAddFunVideo(item)"
          >
            <!-- @click="uploadAddFun(item)" -->
            <i class="iconfont icon-shangchuan"></i>
            生成名画
          </van-button>
        </div>
      </div>
    </div>

    <van-empty
      v-if="voickList.length == 0 && !previewUrl"
      description="请录制语音或上传视频"
    />
    <getPic />

    <canvas ref="previewCanvas" style="display: none"></canvas>
    <!-- <div ref="dddd" class="dddd"></div> -->
    <van-dialog v-model="showdialog" :show-confirm-button="false">
      <van-loading text-color="#fff" vertical>
        <template slot="default">
          <!-- <div>请稍后</div> -->
          <div>AI正在合成名画。。。</div>
        </template>
      </van-loading>
    </van-dialog>
  </div>
</template>

<script>
import DPlayer from "dplayer";
import inputFile from "./inputFile.vue";
import onVoice from "./onVoicecopy.vue";
import xiaozhuanlan from "./xiaozhuanlan.vue";
import getPic from "./getPic.vue";
import {
  uploadFile,
  QiniuUpToken,
  getSwapToken,
  QiniuUpload,
  postGradeOpenAdmin,
  getGradeOpenAdmin,
  gettasksId,
  postGradeComp,
} from "../../api/index";
import * as qiniu from "qiniu-js";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
function generateRandomID(idLength) {
  const characters =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // const idLength = 32; // 字符串长度 这里生成30位的
  let randomID = "";

  for (let i = 0; i < idLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomID += characters.charAt(randomIndex);
  }
  //
  return randomID;
}
let times = null;
export default {
  components: { inputFile, onVoice, xiaozhuanlan, getPic },
  data() {
    return {
      voickList: [],
      previewUrl: null,
      fileIsImage: false,
      firstFrameSrc: null,
      oviceType: 1,
      qiniuuptoken:
        "EjQy0QGvHw7DWKwL5ZD9DrhcZWWkXv7P9eYZ8VKz:YA-VubYVGFFXIP_nxC5GW37RZlg=:eyJzY29wZSI6IjN2cHB0IiwiZGVhZGxpbmUiOjE3MTkyMjAzODcsImZvcmNlU2F2ZUtleSI6dHJ1ZSwic2F2ZUtleSI6InBwdF9tZWRpYS8kKGV0YWcpJiQoZm5hbWUpIiwicmV0dXJuVXJsIjpudWxsLCJyZXR1cm5Cb2R5Ijoie1wia2V5XCI6IFwiJChrZXkpXCIsIFwiaGFzaFwiOiBcIiQoZXRhZylcIiwgXCJmbmFtZVwiOiBcIiQoZm5hbWUpXCJ9In0=",
      GradeId: "",
      showdialog: false,
      recwaveRef: "",
    };
  },
  computed: {
    ...mapState({
      // 将 store 中的 count 映射到 localComputedCount
      getUrl: (state) => state.user.getUrl,
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.recwaveRef = this.$refs.recwave;
      this.QiniuUpTokenFun();
    });
  },
  methods: {
    // ...mapActions("user", ["setGeturl"]),
    QiniuUpTokenFun() {
      // 获取七牛云token
      QiniuUpToken().then((res) => {
        this.qiniuuptoken = res.uptoken;
        console.log("获取到qiniutoken----------", res);
        // uptoken: "EjQy0QGvHw7DWKwL5ZD9DrhcZWWkXv7P9eYZ8VKz:YA-VubYVGFFXIP_nxC5GW37RZlg=:eyJzY29wZSI6IjN2cHB0IiwiZGVhZGxpbmUiOjE3MTkyMjAzODcsImZvcmNlU2F2ZUtleSI6dHJ1ZSwic2F2ZUtleSI6InBwdF9tZWRpYS8kKGV0YWcpJiQoZm5hbWUpIiwicmV0dXJuVXJsIjpudWxsLCJyZXR1cm5Cb2R5Ijoie1wia2V5XCI6IFwiJChrZXkpXCIsIFwiaGFzaFwiOiBcIiQoZXRhZylcIiwgXCJmbmFtZVwiOiBcIiQoZm5hbWUpXCJ9In0="
      });
    },
    StartSetTime() {
      let that = this;
      console.log("开始轮询");
      if (!that.GradeId) {
        return;
      }
      times = setInterval((res) => {
        // gettasksId
        // getGradeOpenAdmin

        gettasksId({ event: that.GradeId }).then((res) => {
          console.log(res, "轮询获取到的数据");
          if (res.resmsg != "未找到数据") {
            // 停止计时器
            this.showdialog = false;
            clearInterval(times);
            this.$emit("changeTabs");
          }
        });
      }, 3000);
    },
    setTypeFun(i) {
      this.oviceType = i;
    },
    //上传至服务器
    uploadAddFunVideo(item) {
      console.log(item.file,'99999999999')
      // return
      // let videoStr = this.previewUrl; //'your_base64_encoded_video'; // 替换为你的 base64 编码的视频字符串
      // let videoUrl = new Blob([videoStr], { type: "video/mp4" });
      let send = {
        file: item.file,
        name:item.file.name// "video.mp4",
      };
      console.log(send, "99999999999");
      this.sendQiniu(send);
      // this.uploadAddFun(send);
    },
    async uploadAddFun(item) {
      console.log(item, "******************");
      let formData = new FormData();
      // await formData.append("upload_id", item.name);
      await formData.append("upl", item.file, item.file.name);
      let ref_img = this.$route.query.modUrl;
      // console.log("-------", this.getUrl);
      let postUrl = `${this.getUrl.host}:${this.getUrl.web}`;
      // let postUrl = "http://192.168.31.212:8100"; //`${this.getUrl.host}:${this.getUrl.port}`
      uploadFile(
        // { upload_id: item.name, files: item.file }
        formData,
        postUrl
      )
        .then((res) => {
          let input_audio = res.errmsg || "";
          let proData = {
            input_audio,
            ref_img,
          };
          return postGradeOpenAdmin(proData);
        })
        .then((res) => {
          if (res.rescode == 200) {
            // resmsg
            this.GradeId = res.resmsg;
            this.showdialog = true;
            this.StartSetTime();
          }
          console.log(res, "获取到的合成名画id");
          // 轮询查询id是否合成完毕
        });
    },
    postGradeOpenAdminFun() {},
    sendQiniu(fileUpList) {
      let that = this;
            this.showdialog = true;
      let putExtra = {
        fname: "",
        // 文件原始文件名，若未指定，则魔法变量中无法使用 fname、ext、suffix
        params: {},
        mimeType: "",
        // string指定所传的文件类型
      };
      putExtra.fname = fileUpList.name;
      putExtra.mimeType = fileUpList.file.type;
      let observable = qiniu.upload(
        fileUpList.file,
        null,
        this.qiniuuptoken,
        putExtra
      );
      let ref_img = this.$route.query.modUrl;
      observable.subscribe({
        next(res) {
          // total:{size: 20486483, loaded: 20486483, percent: 100}
          console.log(res, "七牛进度----");
          // 可以在这里添加上传进度条更新的代码
        },
        error(err) {
          console.error(err);
        },
        complete(res) {
          // 上传完成后的处理逻辑
          console.log(res, "七牛上传----");
          // QiniuUpload({ header: res.key }).then((res) => {
          //   console.log(res);
          //   // ElMessage.success("视频上传成功！");
          // });
          // fname: "video.mp4"
          // hash: "liDeI6sWqgqkrCTTcQ3N9TPqbQwj"
          // key: "ppt_media/liDeI6sWqgqkrCTTcQ3N9TPqbQwj&video.mp4"
          let source_video = res.key || "";
          let proData = {
            source_video,
            ref_img,
          };
          postGradeOpenAdmin(proData).then((res) => {
            console.log(res, "提交合成接口成功！！！");
            that.showdialog = false;
            if (res.rescode == 200) {
              that.$dialog
                .alert({
                  message: `录音上传成功，前方有${res.resmsg}人排队，预计需要${res.resmsg*10}分钟，稍后请在我的名画查看！`,
                })
                .then(() => {
                  // on close
                  that.$emit("changeTabs");
                });
            } else {
              that.$dialog
                .alert({
                  message: "上传错误！",
                })
                .then(() => {
                  // on close
                });
            }
          });
        },
      });
    },
    palyVoice(item, index) {
      console.log(item);
      if (item.animationState == "running") {
        return;
      }
      item.animationState = "running";
      this.recPlay(item);
    },
    recPlay(recBlob) {
      //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
      // var localUrl = URL.createObjectURL(this.recBlob);
      var localUrl = URL.createObjectURL(recBlob.file);
      var audio = document.createElement("audio");
      audio.controls = true;
      document.body.appendChild(audio);
      audio.style.display = "none";
      audio.src = localUrl;
      audio.play(); //这样就能播放了
      // 添加播放结束的回调
      audio.onended = () => {
        console.log("音频播放完毕");
        recBlob.animationState = "paused";
        // 这里可以执行播放结束后你需要做的操作
      };

      // 可选：处理播放错误
      audio.onerror = (error) => {
        recBlob.animationState = "paused";
        console.error("音频播放出错:", error);
      };

      //注意不用了时需要revokeObjectURL，否则霸占内存
      setTimeout(function () {
        URL.revokeObjectURL(audio.src);
      }, 5000);
    },
    uploadAdd(item) {
      this.voickList = item;
    },
    async stimeUraniuml(url) {
      this.previewUrl = url;
      const dp1 = new DPlayer({
        container: this.$refs.previewUrl, //document.getElementById("previewUrl"),
        video: {
          url: url, //"http://h5.3vppt.com/videoFile/butterflyVideo1.mp4",
          pic: require("../../../../assets/aiVideo/videoBack.png"),
        },
        autoplay: true,
        screenshot: true, //开启截图
      });
    },
    async captureFirstFrame(event) {
      return new Promise((resolve) => {
        const videoElement = document.createElement("video");
        const canvas = this.$refs.previewCanvas;
        const ctx = canvas.getContext("2d");
        videoElement.width = 100;
        videoElement.height = 100;
        videoElement.src = event;
        console.log("开始了videoElement");
        // videoElement.onloadedmetadata = () => {
        // 设置视频当前播放时间为0，准备截取第一帧
        videoElement.currentTime = 1;
        // 确保视频已经加载足够的数据来显示第一帧
        // console.log("00000000000", videoElement);
        // this.$refs.dddd.appendChild(videoElement);
        console.log("进入了");
        videoElement.addEventListener(
          "loadedmetadata",
          () => {
            console.log("完成了");
            setTimeout(() => {
              canvas.width = videoElement.videoWidth;
              canvas.height = videoElement.videoHeight;
              ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
              this.firstFrameSrc = canvas.toDataURL("image/jpeg");
              videoElement.removeEventListener(
                "canplay",
                this.captureFirstFrame
              );
              resolve();
            }, 100);
          },
          { once: true }
        );
        // };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.van-dialog) {
  background-color: transparent !important;
}
.FunBox {
  //display: flex;
  /// align-items: center;
  //justify-content: space-around;
  
  box-sizing: border-box;
  padding-top: 0.2rem;
}
.groupBox {
  padding: 0.2rem 0;
  .EachGroupBox {
    margin-bottom: 0.2rem;
    .titName {
      margin: 0 0 0.2rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .previewUrl {
      // border-radius: .2rem;
      width: 100%;
      max-height: 4.2rem;
      // margin-left: 3%;
    }
    margin-left: 2%;
    width: 96%;

    background: linear-gradient(135deg, #a1c4fd, #c2e9fb);
    border-radius: 0.2rem;
    font-size: 0.32rem;
    color: #fff;

    box-sizing: border-box;
    .cont {
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.2rem;
      .icon-bofang {
        font-size: 0.8rem;
        margin-right: 0.2rem;
        background-image: linear-gradient(135deg, #c850c0, #4158d0);
        -webkit-background-clip: text;
        color: transparent; /* 如果背景色不是透明，这句可省略或调整为相应颜色以确保兼容性 */
        background-clip: text;
      }
    }
    .topTile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftName {
        font-weight: bold;
      }
      .leftinfo {
        max-width: 50%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>