<template>
  <div></div>
</template>

<script>
// import { request } from "./api/axios";
export default {
  mounted() {
    this.$nextTick(() => {
      // request({
      //   url: "http://ppt.3vppt.com/wx/v1/getAuth",
      //   method: "get",
      // }).then((res) => {
      //   //   console.log(res)
      //   window.location.href = res;
      // });
      const axios = require('axios');
      axios.get('http://ppt.3vppt.com/wx/v1/getAuth')  
        .then(function (response) {  
          // 请求成功时处理响应数据  
          console.log(response.data);  
          window.location.href = response.data;
        })  
        .catch(function (error) {  
          // 请求失败时处理错误  
          console.error(error);  
        });
    });
  },
  methods: {
    gotowx() {
      setTimeout(() => {
        window.location.href = "http://ppt.3vppt.com/wx/v1/getAuth";
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
