<template>
  <div class="pages">
    <DPlayerComponent
      ref="DPlayerComponent"
      @delectList="delectList"
      :videoList="videoList"
    ></DPlayerComponent>
    <div class="rightVideoLisy">
      <div class="titlename">视频列表</div>
      <div class="eachList" @click="changeVideo(item)" v-for="(item, index) in videoList" :key="index">
        {{ index + 1 }}.{{ index == 0 ? "正在播放" : ""
        }}{{ index == 1 ? "下一个" : "" }}{{ index > 1 ? "等待播放" : "" }}

        <van-image
          round
          style="margin-left: 20px"
          width="40"
          :src="item[2]"
        ></van-image>
      </div>
    </div>
  </div>
</template>

<script>
import { listGrade } from "../api/index";
import DPlayerComponent from "./DPlayerComponent.vue";
let times;
export default {
  data() {
    return {
      videoList: [
        [
          "png",
          "http://h5.3vppt.com/videoFile/mateXr.mp4",
          "https://thirdwx.qlogo.cn/mmopen/vi_32/00cOhHQDLWX87y5ed2MzlXsG4f45GwscB7aCJ2ibqvISMRp5d1icG12qqCcafzHEBjEypgokOoEeWCkTxD0Xdvb1PjgxGmfdx6U5PHBicdDibgE/132",
        ],
      ],
    };
  },
  components: { DPlayerComponent },
  mounted() {
    clearInterval(times);
    times = setInterval(() => {
      this.getlistGradeFun();
    }, 5000);
    // this.$refs.DPlayerComponent.videoended();
  },
  methods: {
    changeVideo(item){
      this.$refs.DPlayerComponent.switchVideo1(item[1]);
    },
    delectList() {
      let arr = this.videoList;
      if (arr.length > 1) {
        arr.shift(); // 删除数组的第一个元素
      }
    },
    getlistGradeFun() {
      listGrade({ screen: 1 }).then((res) => {
        if (res.resmsg) {
          console.log(res.resmsg);
          let resData = res.resmsg[1];
          let trimmedStr = resData.replace(/^\[|]$/g, "");
          console.log(trimmedStr.split(","));
          let da = trimmedStr.split(",");
          this.videoList.push(da);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rightVideoLisy {
  position: fixed;
  top: 30%;
  // right: 10px;
  right: -320px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 10px;
  width: 300px;
  // overflow: hidden;
  transition: all 0.5s;
  &::before {
    content: "";
    position: absolute;
    left: -100px;
    width: 150px;
    height: 150px;
  }
  &:hover {
    right: 10px;
  }
  .titlename {
    text-align: center;
    padding: 10px 0;
  }
  .eachList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    // border-radius: 10px;
    border-bottom: 1px solid #fff;
    &:last-child {
      border: none;
    }
    &:hover {
      cursor: pointer;
      background-color: rgba(128, 128, 128, 0.5);
    }
  }
}
.pages {
  width: 100vw;
  height: 100vh;
  background: #000;
  font-size: 16px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>