<template>
  <div class="pagesB">
    <van-sticky :offset-top="0">
      <van-search
        :label="'登录Id：' + uerId"
        :clearable="false"
        show-action
        v-model="search"
        @search="onSearch"
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </van-sticky>

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <div class="opactList">
        <div
          class="eachCarBox"
          v-for="(item, index) in listData"
          :key="index"
          @click="gotoPage(item)"
        >
          <div class="eachCarBox_title">
            <div class="infor">
              <div class="titles">项目名称：</div>
              <div class="Text">{{ item.name }}</div>
              <div></div>
            </div>
            <div class="infor">
              <div class="titles">序列号：</div>
              <div class="Text">{{ item.serialid }}</div>
              <div></div>
            </div>
            <div class="infor">
              <div class="titles">密钥：</div>
              <div class="Text">
                {{ !item.showIf ? "*********" : item.cipher }}
              </div>
              <van-image
                @click="showXX(item,index)" v-if="item.showIf" width="30px" :src="require('@/assets/signUp/login/op.png')"></van-image>
              <van-image
                @click="showXX(item,index)" v-else width="30px" :src="require('@/assets/signUp/login/cl.png')"></van-image>
            </div>
            <div
              class="infor"
              style=" align-items: center"
            >
              <div class="titles">密文：</div>
              <div class="Text">{{ item.ciphertext }}</div>
              <van-button
                @click="copyText(item.ciphertext)"
                plain
                type="primary"
                size="small"
                >复制</van-button
              >
              <!-- <input
                ref="input"
                style="display: block"
                :value="item.ciphertext"
                readonly
              /> -->
            </div>
            <div class="infor">
              <div class="titles">有效期：</div>
              <div class="Text">{{ item.over }}</div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <van-empty v-if="listData.length == 0" description="暂无数据" />
    </van-pull-refresh>
    <div class="addList" @click="showAdd">+</div>
    <van-popup v-model="show" round style="width: 80%">
      <div class="titless">项目信息</div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="form.name"
          name="项目名称"
          label="项目名称"
          placeholder="项目名称"
          :rules="[{ required: true, message: '请填写项目名称' }]"
        />
        <van-field
          v-model="form.serialid"
          name="序列号"
          label="序列号"
          placeholder="序列号"
          :rules="[{ required: true, message: '请填写序列号' }]"
        />
        <van-field
          v-model="form.cipher"
          name="密钥"
          label="密钥"
          placeholder="密钥"
          :rules="[{ required: true, message: '请填写密钥' }]"
        />
        <van-cell
          is-link
          @click="showTime = true"
          :value="formatDate(currentDate)"
          title="选择时间"
        ></van-cell>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <van-popup v-model="showTime" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datehour"
        title="选择年月日时"
        :min-date="minDate"
        @confirm="confirmFUn"
      />
      <!-- :min-hour="10"
        :max-date="maxDate"
        :max-hour="20" -->
    </van-popup>
  </div>
</template>

<script>
import { LicensePost, LicenseGetList } from "../api/index";
export default {
  data() {
    return {
      showTime: false,
      show: false,
      showPopup: false,
      isLoading: false,
      form: {
        serialid: "",
        name: "",
        cipher: "",
        over: "",
      },
      listData: [],
      minDate: new Date(),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      search: "",
      uerId: localStorage.getItem("ProjectAuthorizationUserId") || "空",
    };
  },
  mounted() {
    this.LicenseGetListFun();
  },
  methods: {
    showXX(iten,index){
      // this.listData[]
      iten.showIf = !iten.showIf
      this.$set(this.listData,index,iten)
    },
    async copyText(text) {
      this.$copyText(text).then(
        () => {
          this.$toast.success("文本已复制到剪贴板");
        },
        () => {
          this.$toast.fail("无法复制文本: ", err);
        }
      );
      return;
      try {
        // 待复制的文本
        const textToCopy = text; //"这是要复制的文本内容";
        // 创建一个可隐藏的textarea元素来帮助复制过程
        const textArea = document.createElement("textarea");
        textArea.style.display = "none";
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        // 执行复制操作
        await navigator.clipboard.writeText(textToCopy);
        // 复制成功后移除临时的textarea
        document.body.removeChild(textArea);
        this.$toast.success("文本已复制到剪贴板");
      } catch (err) {
        this.$toast.fail("无法复制文本: ", err);
      }
    },
    onRefresh() {
      // this.search = "";
      this.LicenseGetListFun();
    },
    onSearch() {
      console.log(this.search);
      this.LicenseGetListFun();
    },
    LicenseGetListFun() {
      this.isLoading = true;
      LicenseGetList({ search: this.search }).then((res) => {
        let newData = res.resmsg;
        newData.forEach((element) => {
          element.showIf = false;
        });
        console.log(newData);
        this.listData = newData;
        this.isLoading = false;
      });
    },
    showAdd() {
      this.form = {
        serialid: "",
        name: "",
        cipher: "",
        over: "",
      };
      this.show = true;
    },
    confirmFUn(e) {
      console.log("times", this.formatDate(e));
      this.showTime = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    onSubmit() {
      this.form.over = this.formatDate(this.currentDate);
      console.log(this.form);
      LicensePost(this.form)
        .then((res) => {
          this.onRefresh();
          let text = res.rescode == 200 ? "添加成功" : "系统错误";
          this.$toast.success(text);
          this.show = false;
        })
        .catch((err) => {
          let text = err.rescode == 403 ? "您没有执行该操作的权限" : "系统错误";
          this.$toast.fail(text);
        });
    },
    gotoPage() {},
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.van-cell__value.van-field__value) {
  display: flex;
  flex-direction: column;
}
.pagesB {
  font-size: 16px;
  // min-height: 100vh;
  .titless {
    // text-aline\
    padding: 0.2rem;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-weight: bold;
    font-size: 0.34rem;
  }
}
.addList {
  position: fixed;
  right: 0.1rem;
  top: 75%;
  //   position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  //   border: 2px solid #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  line-height: 1rem;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  background: #fff;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100px;
    height: 100px;
    // background: linear-gradient(45deg, #ff9a9e, transparent),
    //   linear-gradient(135deg, #ff9a9e, transparent),
    //   linear-gradient(225deg, #ff9a9e, transparent),
    //   linear-gradient(315deg, #ff9a9e, transparent);
    background-size: 50% 50%;
    background-repeat: no-repeat;
    background-position: 0 100%;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
    z-index: -1;
  }
}
.opactList {
  .eachCarBox {
    width: 96%;
    margin-left: 2%;
    border: 1px solid #ccc;
    padding: 0 0.2rem;
    box-sizing: border-box;
    border-radius: 0.2rem;
    margin-bottom: 0.2rem;
    .eachCarBox_title {
      display: flex;
      font-size: 0.24rem;
      justify-content: space-between;
      flex-direction: column;
      .infor {
        box-sizing: border-box;
        width: 100%;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
        padding: 0.2rem 0;
        display: flex;
        align-items: center;
        // justify-content: start;
        .titles {
          min-width: 1.2rem;
        }
        &:last-child {
          border-bottom: none;
        }
        .Text {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 68%;
          white-space: wrap;
          font-weight: 500;
          color: #ccc;
        }
      }
    }
  }
}
</style>