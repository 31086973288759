<template>
  <div>
    <dplayer
    style="height: 3.6rem;"
      :options="playerOptions"
      ref="dplayer"
    ></dplayer>
  </div>
</template>

<script>
import DPlayer from 'vue-dplayer';
import 'vue-dplayer/dist/vue-dplayer.css';

export default {
  components: {
    DPlayer,
  },
  props: {
    pic: {
      type: String,
      required: false,
    },
    videoUrl: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      required: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      playerOptions: {
        video: {
          pic:this.pic||require("../../../assets/signUp/video/videoBg1.jpg"),
          url: this.videoUrl,
          // type: 'auto',
        //   poster: this.poster || '', // 如果没有提供海报，则不显示
        },controls: true,
        height:200
        // ...this.options,
      },
    };
  },
  mounted() {
    // 在组件挂载后，确保DPlayer实例可用
    this.$nextTick(() => {
      if (this.$refs.dplayer.player) {
        console.log('DPlayer已初始化');
      }
    });
  },
};
</script>
<style lang="scss" scoped>
:deep(.dplayer-play-icon){
  display: block !important;
}
:deep(dplayer-icons-left){
  display: flex;
  // align-items: center;
}
</style>