<template>
  <div class="header" style="font-weight: bold;">
    <div>
      <span class="title">开始时间：{{ getTime }}</span>
    </div>
    <div>
      <span class="title">使用时间：{{ gethour }}小时{{ getminute }}分钟{{ getsecond }}秒</span>
    </div>
    <div>
      <span class="title">使用分钟数：{{ getminute }}</span>
    </div>
    <div>
      <span class="title">使用小时：{{ gethour }}</span>
    </div>
    <div>
      <button @click="exit"></button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      getTime:'',
      getsecond:0,
      getminute:1,
      gethour:0
    }
  },
  methods: {
    exit() {
      this.$router.push({
        path: "/XRlease/home"
      })
    }
  },
mounted() {
  //获取当前时间
  const timee = new Date()
  const year =timee.getFullYear();
  const month =timee.getMonth() + 1;
  const day =timee.getDate();
  const hour =timee.getHours();
  const minute =timee.getMinutes();
  const second =timee.getSeconds();
  this.getTime = year + '-' + month + '-' + day +' ' + hour + ':' + minute + ':' + second;
  //自动更新
  setInterval(() => {
  if(true){
    this.getsecond = this.getsecond + 1;
    if(this.getsecond % 60==0){
      this.getminute++
    }
    if(this.getminute % 60==0){
      this.getminute = 2;
      this.gethour++
    }
    if(this.getsecond===60){
      this.getsecond = 1;
    }
  }
  // console.log(this.gettime);
  }, 1000)
  
}
}
</script>
<style>
.title{
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
</style>