import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import Vant from 'vant';
import BaiduMap from 'vue-baidu-map'
import 'element-ui/lib/theme-chalk/index.css'
import 'vant/lib/index.css';
// import * as vant from './utils/vant'
import VueQr from 'vue-qr'
import DPlayer from 'vue-dplayer';
import 'vue-dplayer/dist/vue-dplayer.css';
import QrCode from "qrcode.vue";

import adapter from 'webrtc-adapter';
import { Lazyload } from 'vant';
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);
Vue.component("Qrcode", QrCode);
Vue.use(Lazyload);
Vue.use(Lazyload, {
  lazyComponent: true,
});

Vue.use(BaiduMap, { ak: 'uxoT72mBQQuh8uODHcEOvDSobm4N5yuO' })
Vue.component('dplayer', DPlayer)
Vue.use(ElementUI)
// console.log(Vant)
Vue.use(Vant)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  console.log(to)
  if (to.meta.name) {
   document.title = to.meta.name
  }
  next()
 })


new Vue({
  store,
  VueQr,
  // vant,
  router,
  render: h => h(App)
}).$mount('#app')
