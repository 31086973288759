import axiosBody from 'axios'  
const axios = axiosBody.create();  
import { showMessage } from './status' // 引入状态码文件
// import { ElMessage } from 'element-plus' // 引入el 提示框，这个项目里用什么组件库这里引什么
// import { TokenKey } from '../utils/token'
// import website from '../config/website'
// import { Base64 } from 'js-base64' // 默认请求超时时间
axios.defaults.timeout = 50000
// @ts-ignore
// axios.defaults.baseURL = import.meta.env.VITE_API_DOMAIN;
export let getLocation = {}
// http request 拦截
axios.interceptors.request.use(
  (config) => {
    // config.headers['Authorization'] = `Basic ${Base64.encode(
    //   `${website.clientId}:${website.clientSecret}`
    // )}`
    // // 请求时携带token
    // config.headers[website.tokenHeader] = TokenKey
    // headers中配置text请求
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
// 拦截
axios.interceptors.response.use(
  (res) => {
    const status = res?.data.code || res?.status || res?.errcode
    if (!(status === '200' || status === 200 || status === 204 || status === 0 || res.appId)) {
      showMessage(status) // 传入响应码，匹配响应码对应信息
      return Promise.reject(new Error('Response error: ' + status))
    }
    //   //获取Location
    //   const location = res.headers['location'];
    // // locationData = location;
    // // 将 location 添加进 res
    // res.location = location || '';
    // // 可以将 location 存储起来或者进行其他处理
    // console.log('Location:', location)
    return res.data || res
  },
  (err) => {
    if (err && err.response) {
      // 请求已发出，但是不在2xx的范围
      return Promise.reject(err.response.data)
    } else {
      // 处理其他类型的错误，例如网络连接异常
      // alert('网络连接异常,请稍后再试!')
      return Promise.reject(new Error('Network error'))
    }
  }
)
// 发送请求
export const request = (config) => {
  let { url, params = {}, method = 'POST' } = config
  method = method.toUpperCase() // 统一转换为大写形式
  // 直接返回 Axios 的 Promise
  return axios({
    method: method,
    url: url,
    params: method === 'GET' ? params : undefined, // 只有 GET 请求需要传递 params
    data: method !== 'GET' ? params : undefined // 其他请求需要传递 data
  }).catch((error) => {
    // 在发送请求时也进行了错误处理
    console.error('Request error:', error)
    return Promise.reject(error)
  })
}
