import { request } from './axios'
function makeRequest(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.setRequestHeader('Accept', 'application/json'); // 设置接收JSON类型响应

    // 设置请求完成后的回调
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(JSON.parse(xhr.responseText)); // 解析并传递响应数据
      } else {
        reject(new Error(`Request failed with status ${xhr.statusText}`));
      }
    };

    // 错误处理
    xhr.onerror = function () {
      reject(new Error('Network request failed'));
    };

    // 发送请求
    xhr.send();
  });
}
// 新获取openid的接口
export const getVideoList = (params) =>
  request({
    url: '/lease/file/FilesList/',
    method: 'get',
    params
  })

// 通过openid获取token
export const getSwapToken = (params) =>
  request({
    url: '/lease/api/swapToken/',
    method: 'post',
    params
  })
export const getwxTask = (params) =>
  request({
    url: '/lease/api/wxTask/',
    method: 'post',
    params
  })
export const getwxTaskState = () =>
  request({
    url: '/lease/api/wxTask/',
    method: 'get',
  })
  