const signUpModule = {
  namespaced: true, // 默认为 false，设置为 true 表示模块内的状态、mutations、actions 和 getters 都是局部的  
  state: {
    name: 'Guest',
    token: null,
    signTime: [],
    randomNumber:[],
    getUrl:{}
  },
  mutations: {
    // SET_URLDATA(state, name) {  
    //   state.name = name;  
    // },  
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_RANDOM(state, data) {
      state.randomNumber = data;
    },
    SET_TIME(state, time) {
      // state.signTime.push(time)
      state.signTime = time;
    },
    SET_GETURL(state, data) {
      state.getUrl = data;
    },
  },
  actions: {
    setGeturl({ commit }, data) {
      // ai服务器地址
      commit('SET_GETURL', data);
    },
    setRandomNumber({ commit }, randomNumber) {
      // 随机数
      commit('SET_RANDOM', randomNumber);
    },
    setSignTime({ commit }, time) {
      // 时间
      commit('SET_TIME', time);
    },
    login({ commit }, userInfo) {
      // 模拟异步登录  
      setTimeout(() => {
        // commit('SET_NAME', userInfo.name);  
        commit('SET_TOKEN', userInfo.token);
      }, 1000);
    }
  },
  getters: {
    isLoggedIn: state => !!state.token
  }
};


export default signUpModule;